import { useCallback, useState } from 'react';
import { Space, Typography, Select, Avatar, Button, Checkbox, Popover, Row, Col } from 'antd';
import { formatCurrency } from '../../common/utils';
import { useMembersRoles } from '../../dal';
import MoneyInput from '../shared/MoneyInput';

type User = {
  id: number;
  picture: string;
  name: string;
  email: string;
  positions: string[];
  rateValue: number;
  rateType: "hourly" | "monthly";
  allowUrgentRate: boolean;
  allowVariableRate: boolean;
  urgentRateValue: number;
  averageHourlyRate: number;
}

type Props = {
  users: User[];
  onAdd: (formData: { userId: number, role: string, rate: number, rateType: string, averageHourlyRate: number }) => void
}

export const AddMember = ({ users, onAdd }: Props) => {
  const { roles } = useMembersRoles();
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [useUrgentRate, setUseUrgentRate] = useState<boolean>(false);
  const [useCustomRate, setUseCustomRate] = useState<boolean>(false);
  const [customRate, setCustomRate] = useState<number>(0);
  const [open, setOpen] = useState(false);


  const reset = useCallback(() => {
    setSelectedRole('');
    setSelectedUserId(0);
    setSelectedUser(undefined);
    setUseUrgentRate(false);
    setUseCustomRate(false);
    setCustomRate(0);
    setOpen(false);
  }, []);

  const onAddCallback = useCallback((selectedRole, selectedUser, useUrgentRate, useCustomRate, customRate) => {
    if (!selectedUser) return;
    if (useCustomRate && customRate === 0) return;

    let rate = selectedUser.rateValue;
    if (selectedUser.rateType === 'montly') {
      rate = selectedUser.rateValue;
    } else if (useCustomRate) {
      rate = customRate;
    } else if (useUrgentRate) {
      rate = selectedUser.urgentRateValue;
    }

    const data = {
      role: selectedRole,
      userId: selectedUser.id,
      rate,
      rateType: selectedUser.rateType,
      averageHourlyRate: selectedUser.averageHourlyRate
    };

    onAdd(data);
    
    reset();
  }, [onAdd, reset]);

  const addDisabled = selectedUserId === 0 || !selectedUser?.rateValue || (selectedUser.rateType === 'monthly' && !selectedUser.averageHourlyRate);
  
  const content = <Space direction="vertical">
    <Select
      style={{ width: '350px' }}
      value={selectedRole}
      onChange={value => {
        setSelectedRole(value);
        setSelectedUserId(0);
        setSelectedUser(undefined);
        setUseUrgentRate(false);
        setUseCustomRate(false);
        setCustomRate(0);
      }}
    >
      {roles.map(r => <Select.Option key={r} value={r}>{r}</Select.Option>)}
    </Select>

    <Select
      style={{ width: '350px' }}
      allowClear
      showSearch
      disabled={selectedRole === ''}
      placeholder="Select user"
      getPopupContainer={trigger => trigger.parentElement}
      filterOption={(input, option) => {
        return `${option?.children?.[1]}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      value={selectedUserId === 0 ? null : selectedUserId}
      onChange={value => {
        setSelectedUserId(value);
        setSelectedUser(users.find(u => u.id === value));
        setUseUrgentRate(false);
        setUseCustomRate(false);
        setCustomRate(0);
      }}
    >
      {users.filter(u => u.positions.indexOf(selectedRole) > -1).map(u => (
        <Select.Option key={u.id} value={u.id}>
          <Avatar src={u.picture} style={{ marginRight: '6px' }} size="small" />
          {u.name || u.email} <Typography.Text type="secondary">{formatCurrency(u.rateValue)}/{u.rateType?.slice(0, 1)}</Typography.Text>
          {u.rateType === 'monthly' && <Typography.Text type="secondary"> ({formatCurrency(u.averageHourlyRate)}/h)</Typography.Text>}
        </Select.Option>
      ))}
    </Select>

    {selectedUser?.allowUrgentRate
      && <Checkbox checked={useUrgentRate} onChange={e => {
        setUseUrgentRate(e.target.checked);
        setUseCustomRate(!e.target.checked);
      }}>
        Use urgent rate ({formatCurrency(selectedUser.urgentRateValue)})</Checkbox>}

    {selectedUser?.allowVariableRate
      &&
      <>
        <Checkbox checked={useCustomRate} onChange={e => {
          setUseCustomRate(e.target.checked);
          setUseUrgentRate(!e.target.checked);
        }}>Use custom rate</Checkbox>
        <MoneyInput disabled={!useCustomRate} value={customRate} onChange={e => setCustomRate(parseInt(e?.toString() || '0'))} style={{ width: '60px' }} />
      </>}

    <Row justify="space-between">
      <Col><Button type="primary" onClick={() => onAddCallback(selectedRole, selectedUser, useUrgentRate, useCustomRate, customRate)} disabled={addDisabled}>Add</Button></Col>
      <Col><Button type="link" onClick={() => reset()}>Close</Button></Col>
    </Row>
  </Space>;

  return <Popover placement="bottomLeft" content={content} title="Add member" trigger="click" open={open} onOpenChange={newOpen => setOpen(newOpen)}>
    <Button>Add member</Button>
  </Popover>
}
