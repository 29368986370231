import { Col, List, PageHeader, Row, Spin, Tabs } from 'antd';
import Layout from '../../layouts/authorised/Authorised';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from '../../dal';
import { useUser } from '../../dal/useUser';
import { useUserRates } from '../../dal/useUserRates';
import Avatar from 'antd/lib/avatar/avatar';
import UsersRates from '../../components/users/UserRates';
import UserOverview from '../../components/users/UserOverview';
import UserEditableOverview from '../../components/users/UserEditableOverview';
import { useUserProjects } from '../../dal/useUserProjects';
import UserTimesheetSwitcher from '../../components/users/UserRequireTimesheet';
import UserRoles from '../../components/users/UserRoles';
import UserTasks from '../../components/users/UserTasks';
import UserTimesheet from '../../components/users/UserTimesheet';
import UserSlackId from '../../components/users/UserSlackId';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { UserPermisions } from '../../common/usePermissions/permissions';
import UserPositions from '../../components/users/UserPositions';
import UserActiveSwitcher from '../../components/users/UserActiveSwitcher';

const User = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useUser(id);
  const { rates } = useUserRates(id);
  const { projects } = useUserProjects(id!);
  const { session } = useSession();
  const { hasPermission } = usePermissions();

  if (!user)
    return (
      <Layout>
        <Spin />
      </Layout>
    );

  return (
    <Layout>
      <PageHeader
        title={
          <>
            <Avatar src={user.picture} size="large" />
            {user.name}
          </>
        }
        subTitle={user.email}
        onBack={() => navigate(-1)}
      />
      <Row gutter={16}>
        <Col span={24}>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: 'overview',
                label: 'Overview',
                children: session && session.id === Number(id) ? <UserEditableOverview user={session} /> : <UserOverview user={user} />,
              },
              {
                key: 'projects',
                label: 'Projects',
                children: (
                  <List
                    itemLayout="horizontal"
                    dataSource={projects}
                    renderItem={t => (
                      <List.Item key={t.id}>
                        <List.Item.Meta
                          title={`${t.project?.client?.name || 'No client'} - ${t.project?.name || 'No project name'}`}
                          description={t.role}
                        />
                      </List.Item>
                    )}
                  />
                ),
              },
              {
                key: 'timesheet',
                label: 'Timesheet',
                children: hasPermission(UserPermisions.USER_TIMESHEET_READ) && <UserTimesheet userId={user.id} />,
              },
              {
                key: 'tasks',
                label: 'Tasks',
                children: hasPermission(UserPermisions.USER_TASKS_READ) && <UserTasks userId={user.id} />,
              },
              {
                key: 'admin',
                label: 'Admin',
                children: hasPermission(UserPermisions.USER_SETTINGS_WRITE) && (
                  <>
                    <UserSlackId user={user} />
                    <UserRoles user={user} />
                    <UserPositions user={user} />
                    <UserTimesheetSwitcher user={user} />
                    <UserActiveSwitcher user={user} />
                    <UsersRates user={user} rates={rates} />
                  </>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default User;
