import { Row, Tabs } from 'antd';
import Layout from '../layouts/authorised/Authorised';
import FilesManager from './filesManager';
import { FilesPermissions } from '../common/usePermissions/permissions';
import { usePermissions } from '../common/usePermissions/usePermissions';

import { ReactComponent as IconHomepage } from '../images/icons/settings-gray.svg';
import { ReactComponent as IconFilesManager } from '../images/icons/file-gray.svg';

import './Settings.scss';
import React from 'react';
import Settings from './Settings';
import SettingsPhases from './settingsPhases';

const SettingsNew = () => {
  const { hasPermission } = usePermissions();

  const [activePage, setActivePage] = React.useState<string>('homepage');
  const tabRef = React.createRef<HTMLDivElement>();

  const onMouseMove = (e: MouseEvent) => {
    const [sidebarInner, mainContainerInner] = [document.getElementById('sidebar-inner'), document.getElementById('main-container-inner')];
    if (sidebarInner && mainContainerInner) {
      document.body.style.userSelect = 'none';

      if (e.pageX > 225 && e.pageX < 600) {
        document.body.className = e.pageX - 55 + '';
        sidebarInner.style.width = `${e.pageX - 55}px`;
        sidebarInner.style.display = 'block';
      }
    }
  };

  const handleMouseUp = () => {
    document.body.style.userSelect = '';

    localStorage.setItem('left-bar-width-settings', document.body.className);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const onMouseDown = () => {
    document.addEventListener('mousemove', onMouseMove);

    document.addEventListener('mouseup', handleMouseUp);
  };

  React.useEffect(() => {
    const elem = tabRef.current,
      tabs = elem?.getElementsByClassName('settings-tabs')[0];

    const notFormatedWidth = localStorage.getItem('left-bar-width-settings');

    const width = notFormatedWidth !== null && notFormatedWidth.length > 0 ? Number(localStorage.getItem('left-bar-width-settings')) : 250;

    const childrens = tabs?.children;

    if (tabs && !tabs.getElementsByClassName('custom-divider').length && childrens?.length) {
      const [sidebarInner, mainContainerInner] = [
        document.getElementsByClassName('ant-tabs-nav')[0],
        document.getElementsByClassName('ant-tabs-content-holder')[0],
      ];
      sidebarInner.id = 'sidebar-inner';
      mainContainerInner.id = 'main-container-inner';

      const leftId = document.getElementById('sidebar-inner');

      if (width >= 0 && leftId) {
        if (width > 135 && width < 600) {
          leftId.style.width = width + 'px';
          leftId.style.display = 'flex';
        }
      }

      const elementAfter = tabs.getElementsByClassName('ant-tabs-nav')[0],
        divider = document.createElement('div');
      divider.addEventListener('mousedown', onMouseDown);
      divider.className = 'custom-divider';
      elementAfter.after(divider);
    }

    return () => {
      document.getElementsByClassName('custom-divider')[0]?.addEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabRef]);

  return (
    <Layout isFullPage fullHeight>
      <div ref={tabRef} style={{ height: '100%' }}>
        <Tabs
          tabPosition="left"
          style={{ height: '100%' }}
          onChange={setActivePage}
          tabBarStyle={{ width: 280 }}
          tabBarGutter={0}
          id="settings-tabs"
          className="settings-tabs"
          activeKey={activePage}
          items={
            [
              {
                key: 'setting-header',
                disabled: true,
                label: (
                  <span className=" cursor-default font-[Proxima Nova] font-[700] text-[#1D1D1D] text-[21px] !pl-4 text-ellipsis overflow-hidden whitespace-nowrap">
                    Settings
                  </span>
                ),
              },
              {
                key: 'homepage',
                label: (
                  <Row className=" items-center">
                    <IconHomepage />
                    <span>Homepage</span>
                  </Row>
                ),
                children: <Settings />,
              },
              {
                key: 'phases',
                label: (
                  <Row className=" items-center">
                    <IconHomepage />
                    <span>Phases</span>
                  </Row>
                ),
                children: <SettingsPhases />,
              },
              hasPermission(FilesPermissions.FILES_READ) && {
                key: 'files-manager',
                label: (
                  <Row className="items-center">
                    <IconFilesManager />
                    <span>Files manager</span>
                  </Row>
                ),
                children: <FilesManager />,
              },
            ].filter(Boolean) as []
          }
        />
      </div>
    </Layout>
  );
};

export default SettingsNew;
