import React from 'react';
import { Select } from 'antd';

type Option = {
  label: string;
  value: string | boolean | null;
};

interface PortfolioSelectProps {
  value: string | string[];
  index: number;
  options: Option[];
  onChange: (value: string | string[], index: number) => void;
  multiple?: boolean;
  allowClear?: boolean;
}

export const PortfolioSelect: React.FC<PortfolioSelectProps> = ({ value, index, options, onChange, multiple, allowClear }) => {
  return (
    <Select
      value={value}
      onChange={value => onChange(value, index)}
      options={options}
      mode={multiple ? 'multiple' : undefined}
      allowClear={allowClear}
      style={{ width: '100%' }}
    />
  );
};
