import { Tabs, TabsProps } from 'antd';
import './SingleTab.css';

export const SingleTab = (props: TabsProps & { title: string }) => {
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          key: '1',
          label: props.title,
          children: props.children,
        },
      ]}
      className="single-tab"
    />
  );
};
