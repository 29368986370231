import React from 'react';

import { ChatMessage, Reaction } from '../../entities/chat';
import { User } from '../../entities';
import { pusher } from '../../dal';
import { Tooltip } from 'antd';
import { useChatApi } from '../../../api/useChatApi';

interface Props {
  message: ChatMessage;
  reactions: Reaction[];
  user: User;
  isAllowToCommunicate: string | null;
}

interface IFilteredEmojies {
  [key: string]: { count: number; isAuthor: boolean; id: string; whoLeft: string[] };
}
const reactionSubscriber = pusher.subscribe('reaction-event');
const Reactions: React.FC<Props> = ({ message, reactions, user, isAllowToCommunicate }) => {
  const [filteredEmojies, setFlitertedEmojies] = React.useState<IFilteredEmojies>({});

  const { reaction } = useChatApi();

  React.useEffect(() => {
    if (reactions && user) {
      reactionSubscriber.bind(`reaction-updated-${message.id}`, (data: { isDelete: true; reactionData: { author: User; emoji: string } }) => {
        setFlitertedEmojies(prevState => {
          const newState = { ...prevState };

          if (newState[data.reactionData.emoji]) {
            if (data.isDelete) {
              if (newState[data.reactionData.emoji].count === 1) {
                delete newState[data.reactionData.emoji];
              } else {
                newState[data.reactionData.emoji].count -= 1;
                const index = newState[data.reactionData.emoji].whoLeft.indexOf(data.reactionData.author.name);

                newState[data.reactionData.emoji].whoLeft.splice(index, 1);
                if (data.reactionData.author.id === user.id) {
                  newState[data.reactionData.emoji].isAuthor = false;
                }
              }
            } else {
              newState[data.reactionData.emoji].count += 1;
              newState[data.reactionData.emoji].whoLeft.push(data.reactionData.author.name);
              if (!newState[data.reactionData.emoji].isAuthor) {
                newState[data.reactionData.emoji].isAuthor = data.reactionData.author.id === user.id;
              }
            }
          } else {
            if (!data.isDelete) {
              const newEmoji = {
                count: 1,
                isAuthor: data.reactionData.author.id === user.id,
                id: data.reactionData.emoji,
                whoLeft: [data.reactionData.author.name],
              };

              newState[data.reactionData.emoji] = newEmoji;
            }
          }
          return newState;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.id]);

  React.useEffect(() => {
    if (reactions && reactions.length) {
      let objToCheckIsPushed: IFilteredEmojies = {};
      for (let x = 0; x < reactions.length; x++) {
        const reaction = reactions[x];
        const isFromYou = reaction.author.id === user.id;

        if (objToCheckIsPushed[reaction.emoji]) {
          objToCheckIsPushed[reaction.emoji].count += 1;
          objToCheckIsPushed[reaction.emoji].whoLeft.push(reaction.author.name);
        } else objToCheckIsPushed[reaction.emoji] = { isAuthor: isFromYou, count: 1, id: reaction.id, whoLeft: [reaction.author.name] };

        objToCheckIsPushed[reaction.emoji].id = reaction.id;

        if (isFromYou) {
          objToCheckIsPushed[reaction.emoji].isAuthor = true;
        }
      }
      setFlitertedEmojies(objToCheckIsPushed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactions]);

  const updateReaction = async (emoji: string) => {
    await reaction(message.id, emoji);
  };

  const getTooltipTitle = (whoLeft: string[]) => {
    return (
      <div>
        {whoLeft.map(
          (name, id) =>
            id < 10 && (
              <span key={name} className="font-bold">
                {id !== 0 && ', '} {whoLeft.length > 1 && id === whoLeft.length - 1 && ' and'} {name}
              </span>
            ),
        )}
        {whoLeft.length > 10 && <span className="font-bold">, and other</span>}
        <span> reacted</span>
      </div>
    );
  };

  const setBottomPaddingToComment = () => {
    const curMessage = document.getElementById(message.id);
    const item = curMessage?.getElementsByClassName('ant-comment-inner')[0];

    if (item) {
      if (Object.keys(filteredEmojies).length) {
        item.classList.add('!pb-9');
      } else {
        item.classList.remove('!pb-9');
      }
    }
  };

  return (
    <div>
      <>{setBottomPaddingToComment()}</>
      <div className="absolute flex flex-wrap bottom-[-2rem] left-0">
        {Object.keys(filteredEmojies).map(emoji => (
          <Tooltip key={filteredEmojies[emoji].id} title={getTooltipTitle(filteredEmojies[emoji].whoLeft)}>
            <div
              onClick={() => {
                if (isAllowToCommunicate !== undefined) updateReaction(emoji);
              }}
              className={` ${
                filteredEmojies[emoji].isAuthor && 'mb-1 sm:mb-0 border-solid border-2 border-[rgb(51,144,236)] !py-[1px] !px-[6px] '
              } select-none mr-2 px-[8px] py-[3px] cursor-pointer bg-[rgb(235,243,253)] rounded-md flex justify-center items-center text-md text-[rgb(51,144,236)]`}
            >
              {emoji.startsWith('http') ? <img className="h-4 w-4 mr-1" src={emoji} alt="" /> : emoji}
              <span>{filteredEmojies[emoji].count}</span>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default React.memo(Reactions);
