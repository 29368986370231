import { Button, Tabs } from 'antd';
import moment from 'moment';
import { displayHoursFromNumber, getTotalHours, formatCurrency } from '../../common/utils';
import { datePickerFormat } from '../../constants';
import { Timesheet, TimesheetEntry, User } from '../../entities';
import TimesheetFormRow from './TimesheetFormRow';
import { ReactComponent as IconEdit } from '../../images/icons/icon-edit.svg';
import './TimesheetTabs.css';

function getTimesheetFromSpecificDate(timesheets: TimesheetEntry[], date: string): TimesheetEntry[] {
  return timesheets.filter(t => moment(t.entryDate).format(datePickerFormat) === moment(date).format(datePickerFormat));
}

type Props = {
  timesheets: Timesheet;
  user?: User;
  days: string[];
  onEditClick?: (id?: string) => void;
  onDayChange?: (date: string) => void;
  refreshTimesheets?: () => void;
  activeDay: string;
  projectId?: string;
  addEntryEnabled: boolean;
  displayFinancialData: boolean;
};

const TimesheetTabs = ({
  timesheets,
  user,
  days,
  onEditClick,
  onDayChange,
  activeDay,
  projectId,
  refreshTimesheets,
  addEntryEnabled,
  displayFinancialData,
}: Props) => {
  return (
    <Tabs
      activeKey={activeDay}
      onChange={day => onDayChange && onDayChange(day)}
      centered
      items={days.map(day => ({
        key: day,
        label: (
          <div>
            <h4>{moment(day).format('ddd, MMM D')}</h4>
            <p className="timesheet-day-total">{displayHoursFromNumber(getTotalHours(getTimesheetFromSpecificDate(timesheets, day)))}</p>
          </div>
        ),
        children: (
          <>
            {onEditClick && addEntryEnabled && (
              <TimesheetFormRow user={user} refreshTimesheets={refreshTimesheets} date={activeDay} projectId={projectId} />
            )}
            {getTimesheetFromSpecificDate(timesheets, day).map(t => (
              <div className="timesheet-row" key={t.id}>
                <div style={{ width: 182 }} className="timesheet-col-left">
                  {t.project?.client?.name || <i>No client</i>} - {t.project?.name || <i>No project name</i>}
                </div>
                <div style={{ width: 182 }} className="timesheet-col-left">
                  {t.task ? t.task?.name : <>No task selected</>}
                </div>
                <div style={{ width: 182 }} className="timesheet-col-left">
                  {t.category}
                </div>
                <div style={{ width: 259 }} className="timesheet-col-left">
                  {displayFinancialData && (
                    <div>
                      {t.fixedCost ? (
                        <span className="timesheet-financial-data">{t.isCommission ? 'Commission' : 'Fixed cost'}</span>
                      ) : t.customRate ? (
                        t.customRate === user?.urgentRateValue ? (
                          <span className="timesheet-financial-data">Urgent hours</span>
                        ) : (
                          <span className="timesheet-financial-data">Custom rate: {formatCurrency(t.customRate)}</span>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  {t.hours ? (
                    <div>
                      <h3>{displayHoursFromNumber(t.hours)}</h3>
                    </div>
                  ) : (
                    <div>{displayFinancialData && <h3>{formatCurrency(t.fixedCost)}</h3>}</div>
                  )}
                </div>
                <div style={{ flexGrow: 1 }} className="timesheet-col-left">
                  {t.description}
                </div>
                <div className="timesheet-col-center">
                  {onEditClick && (
                    <Button onClick={() => onEditClick(t.id)}>
                      <IconEdit />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </>
        ),
      }))}
    />
  );
};

export default TimesheetTabs;
