import { Project } from '../../../entities';

type ProjectLinkType = 'FIGMA_PROJECT' | 'FIGMA_CLIENT' | 'HTML' | 'HTML_DDC' | 'DEV_SITE' | 'LIVE_SITE' | 'WAYBACK';

const LINK_TYPES: { key: string; type: ProjectLinkType }[] = [
  { key: 'figmaProjectLink', type: 'FIGMA_PROJECT' },
  { key: 'figmaClientLink', type: 'FIGMA_CLIENT' },
  { key: 'htmlLink', type: 'HTML' },
  { key: 'htmlDdcLink', type: 'HTML_DDC' },
  { key: 'devSiteLink', type: 'DEV_SITE' },
  { key: 'liveSiteLink', type: 'LIVE_SITE' },
  { key: 'waybackLink', type: 'WAYBACK' },
];

export type Portfolio = {
  projectId: string;
  client?: string;
  name: string;
  code?: string;
  phase?: string;
  figmaProjectLink?: string;
  figmaProjectLinkId?: string;
  figmaClientLink?: string;
  figmaClientLinkId?: string;
  htmlLink?: string;
  htmlLinkId?: string;
  htmlDdcLink?: string;
  htmlDdcLinkId?: string;
  devSiteLink?: string;
  devSiteLinkId?: string;
  liveSiteLink?: string;
  liveSiteLinkId?: string;
  waybackLink?: string;
  waybackLinkId?: string;
  hosting?: boolean | null;
  industry?: string[];
};

export type ChangedLink = {
  url: string;
  linkType: ProjectLinkType;
  linkId?: string;
};

export type FormattedChangedLink = ChangedLink & {
  projectId: string;
  isVisibleToClient: boolean;
};

export const getLinkInfo = (portfolioLinks: any[] | undefined, linkType: ProjectLinkType) => {
  const link = portfolioLinks?.find(link => link.linkType === linkType);
  return {
    url: link?.url,
    id: link?.id,
  };
};
export const formChangedLink = (changedProject: Portfolio, changedLink: ChangedLink): FormattedChangedLink | null => {
  for (const { key, type } of LINK_TYPES) {
    if (type === changedLink.linkType && changedLink.url !== changedProject[key]) {
      return {
        projectId: changedProject.projectId,
        linkType: type,
        url: changedLink.url || '',
        linkId: changedLink.linkId,
        isVisibleToClient: true,
      };
    }
  }

  return null;
};

const capitalizeFirstLetter = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formPortfolio = (projects: Project[]) =>
  projects.map(item => {
    const links = LINK_TYPES.reduce((acc, { key, type }) => {
      const { url, id } = getLinkInfo(item.portfolioLinks, type);
      return {
        ...acc,
        [key]: url,
        [`${key}Id`]: id,
      };
    }, {});

    return {
      key: item.id,
      projectId: item.id,
      name: item.name,
      phase: capitalizeFirstLetter(item.phase),
      hosting: item?.hosting,
      industry: item?.industry,
      client: item.client?.name,
      code: item.autoCode,
      ...links,
    };
  });

export const HOSTING_OPTIONS = [
  { value: null, label: '--' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const INDUSTRY_OPTIONS = [
  { value: null, label: '-' },
  { value: 'artificial-intelligence', label: 'Artificial Intelligence' },
  { value: 'biopharma', label: 'Biopharma' },
  { value: 'construction-landscaping', label: 'Construction / Landscaping' },
  { value: 'education', label: 'Education' },
  { value: 'entertainment-media', label: 'Entertainment and media' },
  { value: 'finance-banking', label: 'Finance / Banking' },
  { value: 'fitness', label: 'Fitness' },
  { value: 'government', label: 'Government' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'hospitality', label: 'Hospitality' },
  { value: 'industrials', label: 'Industrials' },
  { value: 'information-technology', label: 'Information Technology' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'ngo-non-profit', label: 'NGO / Non-Profit' },
  { value: 'recruiting', label: 'Recruiting' },
  { value: 'real-estate', label: 'Real Estate' },
  { value: 'renewable-energy', label: 'Renewable Energy' },
  { value: 'software-industry', label: 'Software Industry' },
  { value: 'telecommunications', label: 'Telecommunications' },
  { value: 'cosmetic', label: 'Cosmetic' },
  { value: 'security', label: 'Security' },
  { value: 'business-consultation', label: 'Business Consultation' },
  { value: 'environmental-services', label: 'Environmental Services' },
  { value: 'photography', label: 'Photography' },
  { value: 'technology-and-innovation', label: 'Technology and Innovation' },
  { value: 'beauty', label: 'Beauty' },
  { value: 'ai', label: 'AI' },
  { value: 'engineering', label: 'Engineering' },
  { value: 'construction', label: 'Construction' },
];

export const OFFSET = 0;
export const LIMIT = 20;
