import useSWR from 'swr';
import { FetchResult, User } from '../entities';
import * as Sentry from '@sentry/browser';
import { PermissionsType } from '../common/usePermissions/permissions';

export type UserWithPermissions = User & { permissions: PermissionsType[], accessRights: any, token: string };
type Result = FetchResult & { session?: UserWithPermissions };

export function useSession(): Result {
  const { data, error, mutate } = useSWR<{ user: UserWithPermissions }>(`/session`);

  if (data?.user) {
    Sentry.setUser({ email: data.user.email });
  } else {
    Sentry.configureScope(scope => scope.setUser(null));
  }

  return {
    session: data?.user,
    isLoading: !error && !data,
    isError: error || !data?.user,
    mutate,
  };
}
