import useSWR from 'swr';
import { FetchResult, ProjectsReportItem } from '../entities';

type Result = FetchResult & { allProjectsReport: ProjectsReportItem[]; pagination: any };

export function useTimesheetReportAllProjects(currentMonth: string | null): Result {
  const { data, error, mutate } = useSWR(`/project-finances/timesheet-report-all-projects?currentMonth=${currentMonth || ''}`);

  return {
    allProjectsReport: data?.data,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
