import useSWR from 'swr';
import axios from 'axios';
import { FetchResult, Project, Link } from '../entities';

type Result = FetchResult & {
  projects: Project[];
  count: number;
  isLoading: boolean;
  isError: any;
  mutate: () => void;
  updateLink: (linkId: string, link: Partial<Link>) => Promise<void>;
  createLink: (link: Partial<Link>) => Promise<void>;
  deleteLink: (linkId: string) => Promise<void>;
  updateProject: (projectId: string, field: string, value: any) => Promise<void>;
};

export function useProjectsWithLinks(offset: number, limit: number, filter: string, hosting?: boolean, industry?: string[]): Result {
  const buildURL = (offset: number, limit: number, filter: string, hosting?: boolean, industry?: string[]): string => {
    const params = new URLSearchParams();
    params.append('offset', offset.toString());
    params.append('limit', limit.toString());
    params.append('filter', filter);

    if (hosting !== undefined) {
      params.append('hosting', String(hosting));
    }

    if (industry !== undefined) {
      industry.forEach(ind => params.append('industry', ind));
    }

    return `/projects/links?${params.toString()}`;
  };

  const url = buildURL(offset, limit, filter, hosting, industry);
  const { data, error, mutate } = useSWR(url);

  const updateLink = async (linkId: string, link: Partial<Link>) => {
    await axios.patch(`/links/${linkId}`, link);
    mutate();
  };

  const createLink = async (link: Partial<Link>) => {
    await axios.post(`/links`, link);
    mutate();
  };

  const deleteLink = async (linkId: string) => {
    await axios.delete(`/links/${linkId}`);
    mutate();
  };

  const updateProject = async (projectId: string, field: string, value: any) => {
    await axios.patch(`/projects/${projectId}/${field}`, { [field]: value });
    mutate();
  };

  return {
    projects: data?.data.projects,
    count: data?.data.count,
    isLoading: !error && !data,
    isError: error,
    mutate,
    updateLink,
    createLink,
    deleteLink,
    updateProject,
  };
}
