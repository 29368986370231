import { Radio, Space, Table, Typography } from 'antd';
import { displayDurationFromMinutes, displayHoursFromNumber, formatCurrency, nullableDataSorter } from '../../common/utils';
import { formInvoiceFromTimesheetData } from '../../utils';
import { useState } from 'react';
import { GroupByType } from '../../entities';
import { TimesheetSummary } from '../../dal';
import { InvoiceLineItem } from '../../entities/Invoice';

type Props = {
  details: InvoiceLineItem[];
  isClientView?: boolean;
  groupByPhase?: boolean;
};

const InvoiceTable = ({ details, isClientView = false, groupByPhase = false }: Props) => {
  const [groupingOption, setGroupingOption] = useState<GroupByType>(isClientView ? 'role' : '');

  const dataSource = isClientView
    ? formInvoiceFromTimesheetData(details, groupingOption).filter(pd => pd.isGroup && (pd.billableTotal || pd.fixedBillableTotal))
    : formInvoiceFromTimesheetData(details, groupingOption);

  const summary = pageData => {
    let summaryBillableHourlySpent = 0;
    let summaryTotalMinutes = 0;
    let summaryBillableHours = 0;
    let summaryHourlySpent = 0;
    let summaryFixedSpent = 0;
    let summaryBillableFixedSpent = 0;

    pageData.forEach(({ totalMinutes, totalCost, fixedCost, billableTotal, billableHours, isGroup, fixedBillableTotal }) => {
      if (isClientView && isGroup) {
        summaryBillableHourlySpent += billableHours ? billableTotal || 0 : 0;
        summaryBillableFixedSpent += !billableHours ? fixedBillableTotal || 0 : 0;
        summaryBillableHours += billableHours;
      } else if (!isClientView && !isGroup) {
        summaryBillableHourlySpent += billableTotal;
        summaryTotalMinutes += totalMinutes;
        summaryBillableHours += billableHours;
        summaryHourlySpent += fixedCost ? 0 : totalCost;
        summaryFixedSpent += fixedCost || 0;
        summaryBillableFixedSpent += !billableHours ? fixedBillableTotal || 0 : 0;
      }
    });

    return isClientView ? (
      <Table.Summary.Row className="role-group-row">
        <Table.Summary.Cell index={0}>
          <Typography.Text>Totals</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="right">
          <Typography.Text>{displayDurationFromMinutes(Math.round(summaryBillableHours * 60))}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="right"></Table.Summary.Cell>
        <Table.Summary.Cell index={3} align="right">
          <Typography.Text>{formatCurrency(summaryBillableHourlySpent + summaryBillableFixedSpent)}</Typography.Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ) : (
      <>
        <Table.Summary.Row className="role-group-row">
          <Table.Summary.Cell index={0} colSpan={groupingOption ? 3 : 4}>
            <Typography.Text>Totals</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            <Typography.Text>{displayDurationFromMinutes(summaryTotalMinutes)}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align="right">
            <Typography.Text>{formatCurrency(summaryHourlySpent + summaryFixedSpent)}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="right">
            <Typography.Text>{displayDurationFromMinutes(Math.round(summaryBillableHours * 60))}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align="right"></Table.Summary.Cell>
          <Table.Summary.Cell index={5} align="right">
            <Typography.Text>{formatCurrency(summaryBillableHourlySpent + summaryBillableFixedSpent)}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row className="role-group-row">
          <Table.Summary.Cell index={0} colSpan={groupingOption ? 7 : 8} align="right">
            <Typography.Text>Markup</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            <Typography.Text>
              {summaryBillableHourlySpent > 0
                ? ((1 - (summaryHourlySpent + summaryFixedSpent) / (summaryBillableHourlySpent + summaryBillableFixedSpent)) * 100).toFixed(2) + '%'
                : ''}
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const rowClassName = record => (!isClientView && record.isGroup ? 'role-group-row' : '');

  const baseOptions = [
    { label: 'Group by role', value: 'role' },
    { label: 'Group by phase', value: 'phase' },
  ];

  const options: { label: string; value: string }[] = [];

  if (isClientView) {
    if (groupByPhase) {
      options.push(...baseOptions);
    }
  } else {
    options.push({ label: 'Ungrouped', value: '' }, ...baseOptions);
  }

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: '1rem' }}>
      <Radio.Group options={options} onChange={e => setGroupingOption(e.target.value)} value={groupingOption} optionType="button" />

      <Table dataSource={dataSource} pagination={false} size="small" summary={summary} rowKey="key" rowClassName={rowClassName}>
        <Table.Column
          title="Resource"
          dataIndex="userName"
          render={(value, record: TimesheetSummary) => (record.isGroup ? record.key : value)}
          sorter={
            (!groupingOption || isClientView) && ((a, b) => nullableDataSorter(isClientView ? a.key : a.userName, isClientView ? b.key : b.userName))
          }
        />
        {!isClientView && (
          <Table.Column
            title={groupingOption === 'role' ? 'Phase' : 'Role'}
            dataIndex={groupingOption === 'role' ? 'phaseName' : 'category'}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.category, b.category))}
          />
        )}

        {!isClientView && !groupingOption && (
          <Table.Column
            title="Phase"
            dataIndex="phaseName"
            sorter={
              !groupingOption &&
              ((a: TimesheetSummary, b: TimesheetSummary) => {
                return nullableDataSorter(a.phaseName, b.phaseName);
              })
            }
          />
        )}

        {!isClientView && (
          <Table.Column
            title="Hourly rate"
            dataIndex="ratePerHour"
            align="right"
            render={(value, { isGroup, fixedCost }: TimesheetSummary) => !isGroup && !fixedCost && formatCurrency(value)}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.ratePerHour, b.ratePerHour))}
          />
        )}
        {!isClientView && (
          <Table.Column
            title="Real hours"
            dataIndex="totalMinutes"
            render={(value, { isGroup }: TimesheetSummary) => !isGroup && displayDurationFromMinutes(value)}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalMinutes, b.totalMinutes))}
            align="right"
          />
        )}
        {!isClientView && (
          <Table.Column
            title="Real spend"
            dataIndex="totalCost"
            align="right"
            render={(value, { isGroup, fixedCost }: TimesheetSummary) => !isGroup && formatCurrency(value || fixedCost)}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalCost, b.totalCost))}
          />
        )}
        <Table.Column
          title="Billable hours"
          dataIndex="billableHours"
          align="right"
          render={(value, { billableTotal }: TimesheetSummary) => (value && billableTotal ? displayHoursFromNumber(value || 0) : '')}
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.billableHours, b.billableHours))
          }
        />
        <Table.Column
          title="Billable rate"
          dataIndex="billableRate"
          align="right"
          render={(value, { billableTotal, fixedBillableTotal }: TimesheetSummary) =>
            billableTotal ? formatCurrency(value || 0) : fixedBillableTotal && <i>Fixed cost</i>
          }
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.billableRate, b.billableRate))
          }
        />
        <Table.Column
          title="Billable spend"
          dataIndex="billableTotal"
          align="right"
          render={(value, { fixedBillableTotal }: TimesheetSummary) => formatCurrency(value || fixedBillableTotal || 0)}
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.billableTotal, b.billableTotal))
          }
        />
      </Table>
    </Space>
  );
};

export default InvoiceTable;
