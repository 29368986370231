import { useEffect, useState, createRef } from 'react';
import { useParams } from 'react-router-dom';
import { parse } from 'query-string';
import type { Project as IProject } from '../../../entities';
import ChatArea from '../../components/chat/chatArea';
import { useChat, useChatSession } from '../../dal';
import Layout from '../../layouts/chat/Chat';
import { DashboardPages, ISelectedPage } from './types/types';
import Project from '../../../pages/projects/[id]';
import DashboardSidebar from './components/DashboardSidebar';
import './index.scss';
import { NewChannelData, useChatApi } from '../../../api/useChatApi';
import { useSidebarWidth } from './hooks/useSidebarWidth';
import { useProjects, useSession } from '../../../dal';

const Dashboard = () => {
  const { id: idFromParams } = useParams<{ id: string }>();

  const { projects } = useProjects();

  const [selectedPage, setSelectedPage] = useState<ISelectedPage<any>>({ type: DashboardPages.INITIAL, data: null });
  const [isCreatingNewChannel, setIsCreatingNewChannel] = useState(false);
  const [newChannelMembers, setNewChannelMembers] = useState<string[]>([]);
  const [channelListOpen, setChannelListOpen] = useState<boolean>(true);

  const { session: userSession } = useSession();

  const path = `chat-id=${process.env.REACT_APP_CHAT_ID}&token=${userSession?.token}&name=${userSession?.name}&picture=${userSession?.picture}&chatView=direct-chats&allow-comment`;

  const searchParams = parse(path);

  const { session: chatSession, isError, isLoading } = useChatSession(path);

  const chatData = useChat(chatSession, path);

  const chatId = searchParams['chat-id'] || 'non-chat';

  const { chatChannels, activeChannelId, handleChannel, messages, replyTo, retrieveMessages, setReplyTo, users, loadUsers, chatWhereUserMember } =
    chatData;

  const { createChannel, renameChannel } = useChatApi();

  const DashboardSidebarRef = createRef<HTMLDivElement>();
  const DashboardMainContainerRef = createRef<HTMLDivElement>();
  const CustomDividerRef = createRef<HTMLDivElement>();

  const closeChannelList = () => {
    setNewChannelMembers([]);
    setIsCreatingNewChannel(false);
    setChannelListOpen(false);
  };

  useEffect(() => {
    if (idFromParams) {
      setSelectedPage({
        type: DashboardPages.PROJECT,
        data: {
          id: idFromParams,
        } as IProject,
      });
    }
  }, [idFromParams]);

  const handleSideBarWidth = useSidebarWidth(DashboardSidebarRef, DashboardMainContainerRef, CustomDividerRef);

  useEffect(() => {
    handleSideBarWidth();
  }, [handleSideBarWidth]);

  const handleRenameChannel = async (name: string) => {
    const channelData = chatChannels.find(chnl => chnl.id === activeChannelId);

    if (
      name.length > 0 &&
      (channelData?.isNameEdited ||
        name !== channelData?.users.reduce((pV, cV) => (cV.user.id !== chatSession?.id ? `${pV ? pV + ', ' : ''}${cV.user.name}` : pV), ''))
    ) {
      const result = await renameChannel(activeChannelId, name);

      if (result.data?.data?.id) {
        chatChannels[chatChannels.findIndex(c => c.id === activeChannelId)].name = name;
      }
    }
  };

  const createNewChannel = async (values: NewChannelData) => {
    const channelName = values.name || newChannelMembers[0].split('/')[0] + ' and other';
    const result = await createChannel(chatId, channelName, values);

    if (result.data?.data?.id) {
      chatChannels.push({ id: result.data.data.id, name: result.data.data.name, unread: 0, manualUnread: 0, users: result.data.users });
      setTimeout(() => {
        handleChannel(result.data.data.id, false, true);
      }, 0);
    }
  };

  return (
    <Layout>
      <section className="dashboard-container flex w-full">
        <DashboardSidebar
          session={chatSession}
          chatData={chatData}
          projects={projects}
          selectedPage={selectedPage}
          isCreatingNewChannel={isCreatingNewChannel}
          isError={isError}
          isLoading={isLoading}
          createNewChannel={createNewChannel}
          onSelectedPageChange={page => setSelectedPage(page)}
          onCreatingNewChannelChange={setIsCreatingNewChannel}
          onCloseChannelList={closeChannelList}
          ref={DashboardSidebarRef}
        />

        <div id="custom-divider-new" className="custom-divider" ref={CustomDividerRef}></div>
        <main id="dashboard-main-container" className="page-content flex-auto" ref={DashboardMainContainerRef}>
          {selectedPage.type === DashboardPages.CREATE && (
            <ChatArea
              idToCheck="create"
              type="nothing"
              isCreatingNewChannel={true}
              handleChannelListOpen={() => setChannelListOpen(!channelListOpen)}
              renameChannel={handleRenameChannel}
              channel={null}
              messages={messages}
              session={chatSession}
              chatChannels={chatChannels}
              activeChannelId={activeChannelId}
              users={users}
              replyTo={replyTo}
              setReplyTo={setReplyTo}
              retrieveMessages={retrieveMessages}
              loadUsers={loadUsers}
              searchParams={searchParams}
              newChannelMembers={newChannelMembers}
              onAddUserToChannel={setNewChannelMembers}
              handleChannel={handleChannel}
              setIsCreatingNewChannel={setIsCreatingNewChannel}
              chatWhereUserMember={chatWhereUserMember}
            />
          )}

          {selectedPage.type === DashboardPages.PROJECT && <Project id={selectedPage.data.id} chatData={chatData} />}

          {selectedPage.type === DashboardPages.CHAT && selectedPage.data.type && (
            <ChatArea
              idToCheck={selectedPage.data.id}
              type={selectedPage.data.type}
              isCreatingNewChannel={isCreatingNewChannel}
              handleChannelListOpen={() => setChannelListOpen(!channelListOpen)}
              renameChannel={handleRenameChannel}
              channel={selectedPage.data}
              messages={messages}
              session={chatSession}
              chatChannels={chatChannels}
              activeChannelId={activeChannelId}
              users={users}
              replyTo={replyTo}
              isAbleToRemoveUser={selectedPage.data.users.length > 2}
              setReplyTo={setReplyTo}
              retrieveMessages={retrieveMessages}
              loadUsers={loadUsers}
              searchParams={searchParams}
              newChannelMembers={newChannelMembers}
              onAddUserToChannel={setNewChannelMembers}
              setIsCreatingNewChannel={setIsCreatingNewChannel}
              handleChannel={handleChannel}
              createNewChannel={createNewChannel}
              chatWhereUserMember={chatWhereUserMember}
            />
          )}
        </main>
      </section>
    </Layout>
  );
};

export default Dashboard;
