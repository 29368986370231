import { Select, Row, Col, Typography } from 'antd';
import { ApprovalsPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { useSession } from '../../dal';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { Task } from '../../entities';

const TaskSelector = (props: any) => {
  const { session } = useSession();
  const { hasPermission } = usePermissions();
  const { phases = [], unassigned = [], isLoading } = useProjectTasks(props.project);
  const phasesTasks = phases.reduce((prev: any, curr) => {
    if (curr.tasks) {
      return [...prev, ...curr.tasks];
    }
    return prev;
  }, []);

  const options: Task[] = hasPermission(ApprovalsPermissions.APPROVALS_WRITE)
    ? [...phasesTasks, ...unassigned]
    : [...phasesTasks, ...unassigned].filter(t => {
        const checkUser = t.assignees.filter(a => a.id === session?.id);
        return checkUser.length > 0;
      });

  if (!props.project && isLoading) {
    return <>Project not selected</>;
  }

  if (!isLoading && options.length === 0) {
    return (
      <>
        No tasks in this <a href={`/projects/${props.project}`}>project</a>
      </>
    );
  }

  return (
    <Select
      {...props}
      showSearch
      placeholder="Select task"
      filterOption={(input, option) => {
        return `${option?.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {options.map(o => 
      <Select.Option key={o.id} value={o.id} label={o.name}>
        {getLabel(o)}
      </Select.Option>)}
    </Select>
  );
};

const getLabel = (task) => {
  const left = task.estimate != null ? task.estimate - task.timeSpent : null;
  return <Row justify="space-between">
    <Col>{task.name}</Col>
    <Col>{left != null && <Typography.Text type={left >= 0 ? "secondary" : "danger"}>{Math.abs(left).toFixed(1)}h {left >= 0 ? 'left' : 'over'}</Typography.Text>}</Col>
  </Row>;
};
export default TaskSelector;
