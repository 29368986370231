import { Col, PageHeader, Row, Spin, Form, Input, Select, Button, message, DatePicker, Checkbox, Switch, Space, Card } from 'antd';
import { SelectOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import Layout from '../../layouts/authorised/Authorised';
import { useNavigate, useParams } from 'react-router-dom';
import { usePresentation, usePresentationTemplates } from '../../dal';
import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { Presentation as PresentationType } from '../../entities';

const Presentation = () => {
  const presentationsURL = process.env.REACT_APP_VIRTUAL_PRESENTATIONS_BASE_URL || '';
  const presentationsCdnURL = process.env.REACT_APP_VIRTUAL_PRESENTATIONS_CDN_URL || '';

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { presentation, mutate } = usePresentation(id!);
  const { templates } = usePresentationTemplates();

  const [form] = useForm();

  const [itemCustom, setItemCustom] = useState<any>({});
  const [itemsSortTrigger, setItemsSortTrigger] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const dateFormat = 'MMMM Do, YYYY';

  const onFinish = async (values: any) => {
    setIsSaving(true);
    for (let k of Object.keys(values.data)) {
      if (moment.isMoment(values.data[k])) values.data[k] = values.data[k].format(dateFormat);
    }
    await axios.post(`/presentations/edit/${presentation.id}`, { ...values });
    message.success('Presentation updated');
    await mutate();
    setIsSaving(false);
  };

  const itemsSorter = (a: any, b: any, c: boolean) => {
    return a.order - b.order;
  };

  const onItemsSort = (items: any[], dataName: string, direction: string, i: number, form: FormInstance) => {
    const nearby = direction === 'up' ? i - 1 : i + 1;
    if (!items[nearby]) return;
    if (direction === 'up') {
      items[i].order -= 1;
      items[nearby].order += 1;
    } else {
      items[i].order += 1;
      items[nearby].order -= 1;
    }
    form.setFieldsValue({
      data: {
        [dataName]: {
          [items[i].path]: { order: items[i].order },
          [items[nearby].path]: { order: items[nearby].order },
        },
      },
    });
    setItemsSortTrigger(!itemsSortTrigger);
  };

  const generatePassword = (form: FormInstance) => {
    form.setFieldsValue({ password: Math.random().toString(36).slice(-8) });
  };

  if (!presentation || !templates)
    return (
      <Layout>
        <Spin />
      </Layout>
    );

  return (
    <Layout>
      <Spin spinning={isSaving} tip="Saving data...">
        <PageHeader
          title={presentation.name}
          onBack={() => navigate(-1)}
          extra={[
            <Button icon={<SelectOutlined rotate={90} />} type="primary" target="_blank" href={(presentation.login || presentation.password ? presentationsURL.replace('://', '://' + presentation.login + ':' + presentation.password + '@') : presentationsURL) + presentation.key}>
              Preview
            </Button>,
          ]}
        />
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            key: presentation.key,
            name: presentation.name,
            login: presentation.login,
            password: presentation.password,
            templateName: presentation.templateName,
          }}
          layout="vertical"
        >
          <Form.Item label="Name" name="name" required>
            <Input />
          </Form.Item>
          <Form.Item label="URL" name="key" required>
            <Input />
          </Form.Item>
          <Form.Item label="Template" name="templateName" required>
            <Select
              placeholder="Select template"
              onChange={() => {
                if (window.confirm('Changing template can reset some entered data below, are you sure?')) form.submit();
                else form.resetFields(['templateName']);
              }}
            >
              {templates.map(t => (
                <Select.Option key={t} value={t}>
                  {t}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div style={{ display: 'flex', marginBottom: 26 }}>
            <Space>
              <Form.Item label="Login" name="login" style={{ marginRight: '20px' }}>
                <Input />
              </Form.Item>
            </Space>
            <Form.Item label="Password">
              <Space>
                <Form.Item name="password" noStyle>
                  <Input />
                </Form.Item>
                <Button type="link" onClick={() => generatePassword(form)} style={{ paddingLeft: 0 }}>
                  Generate
                </Button>
              </Space>
            </Form.Item>
          </div>

          {Object.entries(presentation.templateVariables).map(([key, group]) => (
            <Card
              title={key.replace(/([a-z])([A-Z])/g, '$1 $2')}
              style={{ marginBottom: 50 }}
              extra={
                <img src={presentationsCdnURL + presentation.templateName + "/screenshots/" + key + ".png"} alt={key} style={{ height: 130, margin: "-17px -25px -17px 0", borderTopRightRadius: 8 }} />
              }
            >
              {group.map((v: any) =>
                v.groupVar.indexOf('Date') !== -1
                  ? (<Form.Item
                    label={v.groupVar}
                    name={['data', v.fullVar]}
                    initialValue={
                      moment(presentation.data?.[v.fullVar], dateFormat).isValid() ? moment(presentation.data?.[v.fullVar], dateFormat) : moment()
                    }
                  >
                    <DatePicker style={{ width: '100%' }} format={dateFormat} />
                  </Form.Item>)
                  : v.groupVar.indexOf('_Input') !== -1
                    ? (<Form.Item
                      label={v.groupVar.substring(0, v.groupVar.indexOf('_Input'))}
                      name={['data', v.fullVar]}
                      initialValue={presentation.data?.[v.fullVar]}
                    >
                      <Input />
                    </Form.Item>)
                    : v.groupVar.indexOf('_Array') !== -1
                      ? (<Form.List name={['data', v.fullVar]} initialValue={presentation.data?.[v.fullVar]}>
                        {(fields: any, { add, remove }) => {
                          const inputs = v.groupVar.substring(0, v.groupVar.indexOf('_Array')).replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
                          return <>
                            {inputs.length > 2 && (
                              <Row wrap={false} gutter={20} style={{ marginBottom: 8 }}>
                                <Col style={{ width: 60 }}></Col>
                                {inputs.slice(1).map((t: string) => (
                                  <Col flex="auto" style={{ width: "100%" }}>{t}</Col>
                                ))}
                                <Col span="1"></Col>
                              </Row>
                            )}
                            {fields.map((field: any, i: number) => (
                              <Row key={field.key} align="middle" wrap={false} gutter={20} style={{ paddingBottom: 20 }}>
                                {inputs[0] === 'Inp'
                                  ? (<>
                                    <Col style={{ width: 35 }}>{i < 9 ? '0' + (i + 1) : i + 1}/</Col>
                                    {inputs.slice(1).map((t: string) => (
                                      <Col flex="auto">
                                        <Form.Item name={[field.name, t]} style={{ marginBottom: 0 }}>
                                          <Input />
                                        </Form.Item>
                                      </Col>
                                    ))}
                                  </>)
                                  : (<Col flex="auto">
                                    <Form.Item label={i < 9 ? '0' + (i + 1) : i + 1} name={[field.name]} style={{ marginBottom: 0 }}>
                                      <TextArea rows={4} />
                                    </Form.Item>
                                  </Col>)
                                }
                                <Col span="1">
                                  <Button type="text" icon={<MinusCircleOutlined />} onClick={() => remove(field.name)} />
                                </Col>
                              </Row>
                            ))}
                            <Row>
                              <Col span="23">
                                <Form.Item style={{ marginBottom: 0 }}>
                                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        }}
                      </Form.List>)
                      : (<Form.Item label={v.groupVar} name={['data', v.fullVar]} initialValue={presentation.data?.[v.fullVar]}>
                        <TextArea rows={4} />
                      </Form.Item>),
              )}
            </Card>
          ))}

          {['PortfolioItems', 'LeadershipTeam', 'ProjectTeam'].map(itemsType => presentation[itemsType]?.length ? (
            <Card
              title={itemsType.replace(/([a-z])([A-Z])/g, '$1 $2')}
              style={{ marginBottom: 50 }}
              extra={
                <img src={presentationsCdnURL + presentation.templateName + "/screenshots/" + itemsType + ".png"} alt={itemsType} style={{ height: 130, margin: "-17px -25px -17px 0", borderTopRightRadius: 8 }} />
              }
            >
              <table>
                <tr>
                  <td style={{ paddingBottom: '10px' }}>Active</td>
                  <td style={{ textAlign: 'center', paddingBottom: '10px', whiteSpace: 'nowrap' }}>Custom text</td>
                  <td style={{ textAlign: 'center', paddingBottom: '10px' }}>Order</td>
                  <td style={{ width: '100%', paddingBottom: '10px' }}>
                    {itemsType === 'ProjectTeam' && (
                      <div style={{ display: 'flex', alignItems: 'top', height: 22 }}>
                        Open all at once
                        <Form.Item
                          name={['data', 'Excl_ProjectTeamOpenAtOnce', 'enabled']}
                          initialValue={presentation.data?.['Excl_ProjectTeamOpenAtOnce']?.enabled}
                          style={{ marginBottom: 0, marginTop: -5 }}
                          valuePropName="checked"
                        >
                          <Switch size="small" style={{ marginLeft: 10 }} />
                        </Form.Item>
                      </div>
                    )}
                  </td>
                </tr>
                {presentation[itemsType as keyof PresentationType]
                  .sort((a: any, b: any) => itemsSorter(a, b, itemsSortTrigger))
                  .map((p: any, i: number) => (
                    <>
                      <tr>
                        <td style={{ paddingBottom: '5px' }}>
                          <Form.Item
                            name={['data', itemsType, p.path, 'enabled']}
                            initialValue={presentation.data?.[itemsType]?.[p.path]?.enabled}
                            style={{ marginBottom: '0' }}
                            valuePropName="checked"
                          >
                            <Checkbox>
                              <b style={{ whiteSpace: 'nowrap' }}>{p.name.replace(/([a-z])([A-Z])/g, '$1 $2')}</b>
                            </Checkbox>
                          </Form.Item>
                        </td>
                        <td style={{ textAlign: 'center', paddingBottom: '5px' }}>
                          <Form.Item
                            name={['data', itemsType, p.path, 'custom']}
                            initialValue={presentation.data?.[itemsType]?.[p.path]?.custom}
                            style={{ marginBottom: '0' }}
                            valuePropName="checked"
                          >
                            <Switch size="small" onChange={c => setItemCustom({ ...itemCustom, [p.path]: c })} />
                          </Form.Item>
                        </td>
                        <td style={{ padding: '0 10px 5px', whiteSpace: 'nowrap' }}>
                          {presentation[itemsType as keyof PresentationType][i - 1] && (
                            <Button
                              type="link"
                              icon={<ArrowUpOutlined />}
                              onClick={() => onItemsSort(presentation[itemsType as keyof PresentationType], itemsType, 'up', i, form)}
                            />
                          )}
                          {presentation[itemsType as keyof PresentationType][i + 1] && (
                            <Button
                              type="link"
                              icon={<ArrowDownOutlined />}
                              onClick={() => onItemsSort(presentation[itemsType as keyof PresentationType], itemsType, 'down', i, form)}
                              style={{ marginLeft: i === 0 ? '32px' : 0 }}
                            />
                          )}
                          <Form.Item name={['data', itemsType, p.path, 'order']} initialValue={p.order} noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                        </td>
                      </tr>
                      {(itemCustom[p.path] ?? presentation.data?.[itemsType]?.[p.path]?.custom) &&
                        Object.entries(p.data).map(([key, group]: any) => (
                          <tr>
                            <td colSpan={4} style={{ paddingLeft: '25px' }}>
                              {group.map((v: any) =>
                                v.groupVar.indexOf('Input') !== -1 ? (
                                  <Form.Item
                                    label={v.fullVar.substring(0, v.fullVar.indexOf('_Input'))}
                                    name={['data', itemsType, p.path, v.fullVar]}
                                    initialValue={presentation.data?.[itemsType]?.[v.fullVar] || v.value}
                                  >
                                    <Input />
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    label={v.fullVar}
                                    name={['data', itemsType, p.path, v.fullVar]}
                                    initialValue={presentation.data?.[itemsType]?.[p.path]?.[v.fullVar] || v.value}
                                  >
                                    <TextArea rows={4} />
                                  </Form.Item>
                                ),
                              )}
                            </td>
                          </tr>
                        ))}
                    </>
                  ))}
              </table>
            </Card>
          ) : (<></>))}

          <Form.Item style={{ marginTop: '40px' }}>
            <Row gutter={20}>
              <Col>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Col>
              <Col>
                <Button icon={<SelectOutlined rotate={90} />} type="primary" target="_blank" href={(presentation.login || presentation.password ? presentationsURL.replace('://', '://' + presentation.login + ':' + presentation.password + '@') : presentationsURL) + presentation.key}>
                  Preview
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </Layout>
  );
};

export default Presentation;
