import { Form, Comment, Spin } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { User } from '../../entities';

import { useHotkeys } from 'react-hotkeys-hook';

import { ChatChannel, ChatMessage, ChatMessageReplyProps } from '../../entities/chat';

import Tribute from 'tributejs';
import { S3_URL_MASK } from '../../constants';

import './Editor.css';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import { isJPEG, isPNG } from '../../common/utils';
import { CloseOutlined, FileAddOutlined, FileOutlined } from '@ant-design/icons';
import { pusher } from '../../dal';
import ImageModal from './imageModal';
import { useParams } from 'react-router-dom';
import { useChatApi } from '../../../api/useChatApi';

interface EditorProps {
  onChange: (e: string) => void;
  onSubmit: () => void;
  submitting: boolean;
  value: string;
  users: User[];
  rows?: number;
  session?: User;
  attachedFiles: { filterKey?: string; file: File }[];
  setAttachedFiles: React.Dispatch<React.SetStateAction<{ filterKey?: string; file: File }[]>>;
  fileUploading: File[];
  setFileUploading: React.Dispatch<React.SetStateAction<File[]>>;
  channel?: string;
}

interface ReplyFormProps {
  session?: User;
  channel: string;
  replyTo?: ChatMessageReplyProps;
  setReplyTo: (replyTo?: ChatMessageReplyProps) => void;
  chatId: string;
  users?: User[];
  style?: any;
  channelName?: string;
  rows?: number;
  loadUsers?: () => void;
  messages: ChatMessage[];
  disabled?: boolean;
  handleSendMessage?: (data: any) => void;
  isCreatingNewChannel?: boolean;
  newChannelMembers?: string[];
  isGlobal?: boolean;
  isSameChannelExist?: ChatChannel;
  idToCheck: string;
}

const progressPusher = pusher.subscribe('progress-event');

const LocalEditor = React.forwardRef<HTMLDivElement, EditorProps>(
  ({ onChange, onSubmit, submitting, users, attachedFiles, setAttachedFiles, session, fileUploading, setFileUploading, channel }, ref) => {
    const { saveFile } = useChatApi();

    React.useEffect(() => {
      if ((ref as any).current && users.length) {
        const TributeRef = new Tribute({
          values: users.map(({ id, name, picture }) => ({
            key: name + ':' + id,
            value: name,
            picture,
            id,
          })),
          autocompleteMode: false,
          noMatchTemplate: undefined,
          menuItemTemplate: function (item) {
            return `<div class="flex min-w-[130px]" id="${item.original.id}">
            <img src="${item.original.picture}" class="w-5 rounded-full mr-2" alt=""/>
            <span>${item.original.value}</span>
          </div>`;
          },
          selectTemplate: function (item) {
            return `<span data-mention-id="${item.original.id}" contenteditable="false" @mention${item.original.id} class="mention cursor-pointer mention-${item.original.id}">@${item.original.value}</span>`;
          },
        });
        TributeRef.attach((ref as any).current);
      }
    }, [users, ref]);

    const [progress, setProgress] = React.useState<{ [key: string]: string }>({});

    React.useEffect(() => {
      let counter = 0;
      let el = document.getElementsByClassName('ant-tabs-content-holder')[0];
      if (!el) el = document.getElementsByClassName('ant-layout-content')[0];
      if (el) {
        el.addEventListener('dragenter', e => {
          e.preventDefault();
          counter++;
          handleDragItem(e);
        });

        el.addEventListener('dragleave', e => {
          counter--;
          if (counter === 0) {
            setIsDragginItem(false);
          }
        });

        document.addEventListener('dragover', e => {
          e.preventDefault();
        });

        document.addEventListener('drop', e => {
          e.preventDefault();
          counter = 0;
          handleAddFile(e);
        });
      }

      progressPusher.bind('chat:update-progress', data => {
        const { fileName, progress } = data;
        setProgress(pV => {
          if (pV[fileName] && pV[fileName] > progress) {
            return { ...pV };
          } else return { ...pV, [fileName]: progress };
        });
      });

      return () => {
        pusher.unbind('chat:update-progress');

        document.removeEventListener('dragenter', e => {
          e.preventDefault();
          counter++;
          handleDragItem(e);
        });
        document.removeEventListener('dragleave', e => {
          counter--;
          if (counter === 0) {
            setIsDragginItem(false);
          }
        });

        document.removeEventListener('dragover', e => {
          e.preventDefault();
        });

        document.removeEventListener('drop', e => {
          e.preventDefault();
          counter = 0;
          handleAddFile(e);
        });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isDraggingItem, setIsDragginItem] = React.useState<boolean>(false);

    function readBuffer(file, start = 0, end = 2) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file.slice(start, end));
      });
    }

    async function handleCheckFileExtention(file: File) {
      if (file && file.type === '') {
        const buffers: any = await readBuffer(file, 0, 8);
        const uint8Array = new Uint8Array(buffers);
        let extention;
        if (isPNG(uint8Array) && !extention) {
          extention = 'png';
        }

        if (isJPEG(uint8Array) && !extention) {
          extention = 'jpeg';
        }

        if (extention) {
          file = new File([file], `${file.name}.${extention}`, {
            type: 'image/' + extention,
          });
        }
      }
      return file;
    }

    const handleAddFile = async (e: any) => {
      setIsDragginItem(currentState => {
        (async function () {
          if (currentState && e.dataTransfer.items) {
            const fileArray = [...e.dataTransfer.items];
            let filesList: File[] = await Promise.all(fileArray.map(async item => await handleCheckFileExtention(item.getAsFile())));
            setFileUploading(pV => [...pV, ...filesList]);
            filesList.forEach(file => {
              const filterKey = new Date().valueOf() + '';
              saveFile(filterKey, file).then(() => {
                setFileUploading(pV => {
                  if (pV.find(item => item.name === file.name)) {
                    setAttachedFiles(currentFiles => [...currentFiles, { filterKey, file }]);
                  }
                  return pV;
                });
              });
            });
          }
        })();
        return false;
      });
    };

    const handleDragItem = (e: any) => {
      const dt = e.dataTransfer;
      // eslint-disable-next-line eqeqeq
      if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files'))) {
        setIsDragginItem(currentState => (currentState === false ? true : currentState));
      } else {
        setIsDragginItem(currentState => (currentState === true ? false : currentState));
      }
    };

    const onChangeMessage = (e: ChangeEvent<HTMLDivElement>) => {
      const text = e.target.innerHTML;
      onChange(text);
    };

    const onSendMessage = () => {
      if (attachedFiles.length === fileUploading.length) {
        onSubmit();
      }
    };

    const [, setPrevRange] = React.useState<Range>();

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const selection: any = window.getSelection();
      if (
        selection &&
        selection.rangeCount > 0 &&
        ((selection.anchorNode as any).id === 'message-input-area' || (selection?.anchorNode?.parentNode as any).id === 'message-input-area')
      ) {
        const range = selection?.getRangeAt(0);
        setPrevRange(range);
      }
    };

    const handleRemoveFile = (fileName: string) => {
      setAttachedFiles(pV => pV.filter(data => data.file.name !== fileName));
      setFileUploading(pV => pV.filter(file => file.name !== fileName));
    };

    const handleAttachFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const arrayFiles = Array.from(e.target.files);
        const filesList: File[] = await Promise.all(arrayFiles.map(async file => await handleCheckFileExtention(file)));
        setFileUploading(pV => {
          return [...pV, ...filesList];
        });
        for (const file of filesList) {
          const filterKey = new Date().valueOf() + '';

          saveFile(filterKey, file).then(() => {
            setFileUploading(pV => {
              if (pV.find(item => item.name === file.name)) {
                setAttachedFiles(currentFiles => [...currentFiles, { file, filterKey }]);
              }
              return pV;
            });
          });
        }
      }
    };

    const [displayEmoji, setDisplayEmoji] = React.useState<boolean>(false);
    const { id } = useParams();

    React.useEffect(() => {
      const container = document.getElementById('dashboard-main-container');
      if (container) {
        const el: any = container.getElementsByClassName('messages-window')[0];

        if (el) {
          if (el.parentElement?.classList.contains('ant-layout-content')) {
            el.style.height = fileUploading.length ? 'calc(100vh - 405px)' : 'calc(100vh - 305px)';
          } else {
            el.style.height = fileUploading.length ? 'calc(100vh - 336px)' : 'calc(100vh - 240px)';
          }
        }
      }
    }, [fileUploading, id]);

    const [showImage, setShowImage] = React.useState<string | null>();

    const onEmojiClick = React.useCallback(
      (data: EmojiClickData) => {
        const input = (ref as any).current;
        input?.focus();
        const selection = window.getSelection();
        if (
          selection &&
          input &&
          ((selection.anchorNode as any).id === 'message-input-area' || (selection?.anchorNode?.parentNode as any).id === 'message-input-area')
        ) {
          setPrevRange(prevRange => {
            const range = prevRange || selection?.getRangeAt(selection.rangeCount - 1);
            if (!prevRange) {
              return range;
            }
            const emoji = document.createElement('img');
            emoji.className = 'text-emoji';
            emoji.style.userSelect = 'none';
            emoji.src = data.getImageUrl(EmojiStyle.APPLE);
            emoji.style.width = '20px';
            emoji.style.marginBottom = '3px';
            range.insertNode(emoji);
            range.setStartAfter(emoji);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
            onChange(input.innerHTML);
            return prevRange;
          });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [ref],
    );

    const handleMouseLeave = (e: any) => {
      if (
        e.relatedTarget.className !== 'epr-btn epr-emoji epr-visible' &&
        e.relatedTarget.className !== 'EmojiPickerReact epr-main' &&
        e.relatedTarget.className !== 'backdrop absolute h-20 w-20 right-[45px] bottom-0' &&
        e.relatedTarget.className !== '__EmojiPicker__ epr-emoji-img' &&
        e.relatedTarget.className !== 'emoji-icon absolute top-[1.5rem] right-[3.5rem] hover:bg-[#4F67FF] cursor-pointer'
      ) {
        setDisplayEmoji(false);
      }
    };

    const handleOpenEmoji = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setDisplayEmoji(true);
    };

    return (
      <>
        <div id="drop-zone" className={`itemdrop-zone ${isDraggingItem ? 'show-dropzone' : 'hide-dropzone'}`}>
          <div className="flex flex-col">
            <FileAddOutlined className="text-[40px] p-0 m-0" />
            <span className="text-xl font-semibold">Drop file here to attach it</span>
          </div>
        </div>
        <Form.Item>
          <div className={'absolute bottom-[80px] right-0'} onMouseLeave={handleMouseLeave}>
            {displayEmoji && <EmojiPicker onEmojiClick={onEmojiClick} />}
          </div>
          <div className={`message-container flex ${fileUploading.length ? 'h-[10rem]' : 'h-[4rem]'}`}>
            <div
              onKeyDown={e => {
                if (e.ctrlKey && e.key === 'Enter') {
                  const selection = window.getSelection(); // get the current selection.
                  if (selection) {
                    const range = selection?.getRangeAt(0); // get the current range of the selection.
                    const br = document.createElement('br');
                    range.insertNode(br); // insert the <br> element at the current cursor position.
                    range.setStartAfter(br); // set the start of the range to the position after the <br> element.
                    range.collapse(true); // collapse the range to the position after the <br> element.
                    selection.removeAllRanges(); // used to remove any existing ranges from the selection.
                    selection.addRange(range); // add the modified range to the selection.
                  }
                }
                if (!e.currentTarget.lastChild || e.currentTarget.lastChild.nodeName.toLowerCase() !== 'br')
                  e.currentTarget.appendChild(document.createElement('br'));
              }}
              data-text="Enter your message..."
              ref={ref}
              id="message-input-area"
              className={`w-full h-[4rem] overflow-auto`}
              contentEditable={true}
              suppressContentEditableWarning
              onInput={onChangeMessage}
            ></div>
            {showImage && session && <ImageModal image={showImage} user={session} setShowImage={setShowImage} />}
            <div className={`attached-files flex bottom-0 absolute w-full h-fit ${fileUploading.length ? 'z-10' : '-z-10'} px-4 py-4`}>
              {fileUploading.map((file, index) => {
                const isLoaded = attachedFiles.find(item => item.file.name === file.name);
                const fileRoute = `${S3_URL_MASK}${session?.id}/${isLoaded?.filterKey}/${isLoaded?.file.name}`;
                return file.type.split('/')[0] === 'image' ? (
                  <div key={index + fileRoute} className="message-file-image !h-[62px] !w-[62px]">
                    {isLoaded ? (
                      <img
                        className="file-image !h-[62px] !w-[62px]"
                        src={fileRoute}
                        alt=""
                        onClick={() => {
                          setShowImage(fileRoute);
                        }}
                      />
                    ) : (
                      <Spin />
                    )}
                    <div className="remove-file" onClick={() => handleRemoveFile(file.name)}>
                      <CloseOutlined />
                    </div>
                  </div>
                ) : (
                  <div key={index + fileRoute} className="message-file !h-[62px]">
                    {isLoaded ? <FileOutlined className=" text-[32px]" /> : <Spin />}
                    <div className="message-file-data ml-2">
                      <div className="file-name">{file.name}</div>
                      <div className="file-size uppercase">
                        {isLoaded ? file.name.split('.')[file.name.split('.').length - 1] : (progress[file.name] || 0) + '%'}
                      </div>
                    </div>
                    <div className="remove-file" onClick={() => handleRemoveFile(file.name)}>
                      <CloseOutlined />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="relative">
              <label className=" attach-icon absolute top-[1.5rem] right-[5.5rem] hover:bg-[#4F67FF] cursor-pointer" htmlFor="attach-file"></label>
              <input
                multiple
                type="file"
                id="attach-file"
                className="hidden"
                onChange={handleAttachFile}
                onClick={(event: any) => {
                  event.target.value = null;
                }}
              />
              <div onMouseLeave={handleMouseLeave} className="backdrop absolute h-20 w-20 right-[45px] bottom-0"></div>
              <div
                onMouseEnter={e => {
                  setDisplayEmoji(true);
                  handleMouseDown(e);
                }}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleMouseDown}
                className="emoji-icon absolute top-[1.5rem] right-[3.5rem] hover:bg-[#4F67FF] cursor-pointer"
                onClick={handleOpenEmoji}
              ></div>
              <div className=" send-icon absolute top-[1.5rem] right-[1.5rem] hover:bg-[#4F67FF] cursor-pointer" onClick={onSendMessage}></div>
            </div>
          </div>
        </Form.Item>
      </>
    );
  },
);

const ReplyForm = ({
  isSameChannelExist,
  isGlobal,
  newChannelMembers,
  isCreatingNewChannel,
  handleSendMessage,
  disabled,
  messages,
  session,
  channel,
  replyTo,
  setReplyTo,
  style,
  channelName,
  rows,
  chatId,
  loadUsers,
  users,
  idToCheck,
}: ReplyFormProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const MessageRef = React.useRef<HTMLDivElement>(null!);
  const [attachedFiles, setAttachedFiles] = React.useState<{ filterKey?: string; file: File }[]>([]);
  const [fileUploading, setFileUploading] = React.useState<File[]>([]);
  const { id } = useParams();

  const { createMessage } = useChatApi();

  const getIsChannelAlreadyExist = () => {
    if (!isSameChannelExist) {
      return 'create-channel';
    } else return isSameChannelExist.id;
  };

  const handleSubmit = (replyTo: ChatMessageReplyProps | undefined) => {
    setValue(pValue => {
      if (!pValue && !attachedFiles.length) return '';
      const members = newChannelMembers && newChannelMembers.map(member => users && users.find(user => user.id === member.split('/')[1]));
      setSubmitting(true);
      const idIfCreatinNewChannel = getIsChannelAlreadyExist();

      createMessage(isCreatingNewChannel, idIfCreatinNewChannel, channel, pValue, replyTo, channelName, chatId, isGlobal, members, attachedFiles)
        .then(data => {
          if (isCreatingNewChannel && handleSendMessage && data) handleSendMessage(data.data);
        })
        .catch(err => {
          if (isCreatingNewChannel && handleSendMessage) {
            // console.log(err);
            // handle error - if you tried to create already existing private chat, then it will open existing chat
            const id = err.response.data.message.split('id: ')[1];
            handleSendMessage({ error: true, channelId: id });
          }
        });
      setAttachedFiles([]);
      setFileUploading([]);
      setSubmitting(false);
      const el = (MessageRef as any).current;
      if (el) el.innerHTML = '';
      return '';
    });
    setReplyTo(undefined);
  };

  const handleChange = (e: string) => {
    setValue(e);
  };

  useHotkeys(
    'enter',
    e => {
      e.preventDefault();
      if (fileUploading.length === attachedFiles.length) {
        handleSubmit(replyTo);
      }
    },
    { enableOnContentEditable: true },
    [
      fileUploading,
      attachedFiles,
      channelName,
      messages,
      replyTo,
      handleSendMessage,
      isSameChannelExist,
      isCreatingNewChannel,
      isGlobal,
      newChannelMembers,
    ],
  );

  function handleHotkeyOnNewLine(e: KeyboardEvent) {
    e.preventDefault();
    if (MessageRef.current) {
      const selection = window.getSelection(); // get the current selection.
      if (
        selection &&
        ((selection.anchorNode as any).id === 'message-input-area' || (selection?.anchorNode?.parentNode as any).id === 'message-input-area')
      ) {
        const range = selection?.getRangeAt(0); // get the current range of the selection.
        const br = document.createElement('br');
        range.insertNode(br); // insert the <br> element at the current cursor position.
        range.setStartAfter(br); // set the start of the range to the position after the <br> element.
        range.collapse(true); // collapse the range to the position after the <br> element.
        selection.removeAllRanges(); // used to remove any existing ranges from the selection.
        selection.addRange(range); // add the modified range to the selection.
        if (!(selection.anchorNode as any).lastChild || (selection.anchorNode as any).lastChild.nodeName.toLowerCase() !== 'br')
          (selection.anchorNode as any).appendChild(document.createElement('br'));
      }
    }
  }

  useHotkeys(
    'alt+enter',
    e => {
      handleHotkeyOnNewLine(e);
      // handleSubmit(replyTo);
    },
    { enableOnContentEditable: true },
    [handleHotkeyOnNewLine],
  );

  React.useEffect(() => {
    if (loadUsers) {
      loadUsers();
    }
    setAttachedFiles([]);
    setFileUploading([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (users && (idToCheck === id || !id)) {
    return (
      <Comment
        className="reply-field !ml-[0.1rem] !mr-[0.8rem] absolute bottom-0 w-[98 %] bg-white"
        style={style}
        content={
          !disabled && (
            <LocalEditor
              channel={channel}
              fileUploading={fileUploading}
              setFileUploading={setFileUploading}
              setAttachedFiles={setAttachedFiles}
              attachedFiles={attachedFiles}
              ref={MessageRef}
              session={session}
              users={users}
              onChange={handleChange}
              onSubmit={() => handleSubmit(replyTo)}
              submitting={submitting}
              value={value}
              rows={rows}
            />
          )
        }
      />
    );
  }

  return <div></div>;
};

export default ReplyForm;
