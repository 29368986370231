import React, { useState, useRef, useEffect, ChangeEvent, FocusEvent, useCallback } from 'react';
import { Input, Tooltip, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { formatLink, getPathFromLink } from './utils';

import './LinkInput.css';

type LinkInputProps = {
  disabled?: boolean;
  value?: string;
  onChange: (value: string) => void;
};

const LinkInput: React.FC<LinkInputProps> = ({ disabled = false, value = '', onChange }) => {
  const [isEditing, setIsEditing] = useState<boolean>(!value);
  const [link, setLink] = useState<string>(value);
  const [displayLink, setDisplayLink] = useState<string>(formatLink(value));
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    setLink(value);
    setDisplayLink(formatLink(value));
    setIsEditing(!value);
  }, [value]);

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (link !== value) {
        onChange(link);
      }
      if (link) {
        setIsEditing(false);
        setDisplayLink(formatLink(link));
      }
    },
    [link, value, onChange],
  );

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        if (link.trim() === '') {
          inputRef.current?.blur();
        } else {
          handleBlur(e as any);
        }
      }
    },
    [handleBlur, link],
  );

  const path = getPathFromLink(displayLink);

  return (
    <>
      {!disabled && (
        <div className="link-container">
          {isEditing ? (
            <Input
              ref={inputRef}
              type="text"
              value={link}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              placeholder="Enter URL"
            />
          ) : (
            <div className="link-display">
              <a href={link} target="_blank" rel="noopener noreferrer" className="link">
                <span className="domain">{displayLink.split('/')[0]}</span>
                {path && <span className="path">/{path}</span>}
              </a>

              <Tooltip title="Edit">
                <Button type="text" icon={<EditOutlined />} onClick={handleEditClick} className="edit-button" />
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(LinkInput);
