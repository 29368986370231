import { Input, Switch, Table, Tag } from 'antd';
import queryString from 'query-string';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '../../dal';
import { Client, Project, Roles } from '../../entities';
import { useEffect, useState } from 'react';
import { formatDate, nullableDataSorter } from '../../common/utils';
import { FilterTypes, saveFiltersToStorage, useFiltersState } from '../../common/useFiltersState';
import { getOutstandingAmount, OutstandingAmount } from '../../components/project/FinancialInfo';
import { filterProjects, Filters, ProjectFilters } from '../../components/project/ProjectFilters';
import { FinancialInfoSwitch } from './FinancialInfoSwitch';
import UserSelector from '../users/UserSelector';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ProjectPermissions } from '../../common/usePermissions/permissions';

export type InternalPropjectTypes = 'all' | 'internal' | 'client';

// TODO: Cleanup multiple filters
const filterInternalProjects = (projects: Project[], showInternalProjects: boolean) => {
  if (showInternalProjects) return projects;

  return projects?.filter(p => p.client?.autoCode !== 'CL0000');
};

export const filterProjectsType = (projects: Project[], internalProjects: InternalPropjectTypes, activeId?: string) => {
  if (internalProjects === 'client') {
    return projects?.filter(p => p.client?.autoCode !== 'CL0000' || activeId === p.id);
  }

  if (internalProjects === 'internal') {
    return projects?.filter(p => p.client?.autoCode === 'CL0000' || activeId === p.id);
  }

  return projects;
};

export const searchByPhrase = (projects: Project[], phrase: string, activeId?: string): Project[] => {
  return projects?.filter(
    p =>
      p.name.toLowerCase().search(phrase.toLowerCase()) > -1 ||
      p.autoCode.toLowerCase().search(phrase.toLowerCase()) > -1 ||
      (p.client && p.client.name.toLowerCase().search(phrase.toLowerCase()) > -1) ||
      activeId === p.id,
  );
};

export const filterByUser = (projects: Project[], userId: number | null, activeId?: string): Project[] => {
  if (!userId) return projects;

  //TODO: temporary solution for a quick fix
  return projects?.filter(p => activeId === p.id);
};

type Props = {
  projects: Project[];
  filterKey: FilterTypes;
};

const ProjectsTable = ({ projects, filterKey }: Props) => {
  const params = useFiltersState(filterKey);
  const { session } = useSession();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const isAdmin = session && session.roles ? session.roles.includes(Roles.ADMIN) : false;
  const searchPhraseFromParams = params && params[`${filterKey}-s`] ? (params[`${filterKey}-s`]!.toString() as string) : '';
  const financialInfoFromParams = params && params[`${filterKey}-fi`] ? (params[`${filterKey}-fi`] as boolean) : false;
  const filterFromParams = params && params[`${filterKey}-f`] ? (params[`${filterKey}-f`]!.toString() as Filters) : 'active';
  const [showInternalProjects, setShowInternalProjects] = useState<boolean>(
    params && params[`${filterKey}-int`] ? (params[`${filterKey}-int`] as boolean) : false,
  );
  const [userFilter, setUserFilter] = useState<number | null>(
    params && params[`${filterKey}-u`] ? (Number(params[`${filterKey}-u`]) as number) : null,
  );
  const [showFinancialInfo, setShowFinancialInfo] = useState<boolean>(financialInfoFromParams);
  const [phrase, setPhrase] = useState<string>(searchPhraseFromParams);
  const [filter, setFilter] = useState<Filters>(filterFromParams);

  useEffect(() => {
    const filtersState = {
      [`${filterKey}-s`]: !!phrase ? phrase : undefined,
      [`${filterKey}-f`]: !!filter ? filter : undefined,
      [`${filterKey}-fi`]: !!showFinancialInfo ? showFinancialInfo : undefined,
      [`${filterKey}-int`]: !!showFinancialInfo ? showFinancialInfo : undefined,
      [`${filterKey}-u`]: !!userFilter ? userFilter : undefined,
    };
    const filtersStringify = `?${queryString.stringify(filtersState)}`;
    saveFiltersToStorage(filterKey, filtersStringify);
    navigate({
      search: filtersStringify,
    });
  }, [phrase, filter, showFinancialInfo, filterKey, navigate, showInternalProjects, userFilter]);

  return (
    <>
      <div style={{ padding: '20px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Input
          placeholder="Search by project name or client name"
          value={phrase}
          onChange={e => setPhrase(e.target.value)}
          style={{ width: '300px' }}
        />
        <UserSelector allowClear style={{ width: '200px' }} value={userFilter} onChange={e => setUserFilter(e)} />
        <div>
          <Switch
            checkedChildren="Internal projects"
            unCheckedChildren="Internal projects"
            checked={showInternalProjects}
            onChange={(value: boolean) => setShowInternalProjects(value)}
            style={{ marginRight: '20px' }}
          />
          {hasPermission(ProjectPermissions.PROJECT_FINANCES_READ) && (
            <FinancialInfoSwitch isChecked={showFinancialInfo} onChange={setShowFinancialInfo} />
          )}
          <ProjectFilters value={filter} onChange={setFilter} hasPermissionForDraft={hasPermission(ProjectPermissions.PROJECT_DRAFT_READ)} />
        </div>
      </div>

      <Table
        className="clickable-row"
        dataSource={filterInternalProjects(
          searchByPhrase(filterProjects(filterByUser(projects, userFilter), filter, hasPermission(ProjectPermissions.PROJECT_DRAFT_READ)), phrase),
          showInternalProjects,
        )}
        size="small"
        rowKey="id"
        pagination={false}
        onRow={record => {
          return {
            onClick: () => {
              navigate(`/projects/${record.id}`);
            },
          };
        }}
      >
        <Table.Column
          key="client"
          dataIndex="client"
          title="Client"
          render={(client: Client) =>
            client && (
              <Link onClick={event => event.stopPropagation()} to={`/clients/${client.id}`} rel="noopener noreferrer" target="_blank">
                {client.name}
              </Link>
            )
          }
          sorter={(a: Project, b: Project) => nullableDataSorter(a.client ? a.client.name : null, b.client ? b.client.name : null)}
        />
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value, row: any) => (
            <Link onClick={event => event.stopPropagation()} to={`/projects/${row.id}`} rel="noopener noreferrer" target="_blank">
              {value}
            </Link>
          )}
          sorter={(a: Project, b: Project) => nullableDataSorter(a.name, b.name)}
        />
        <Table.Column
          dataIndex="autoCode"
          title="Code"
          render={(value, row: any) => (
            <Link onClick={event => event.stopPropagation()} to={`/projects/${row.id}`} rel="noopener noreferrer" target="_blank">
              {value}
            </Link>
          )}
          sorter={(a: Project, b: Project) => nullableDataSorter(a.autoCode, b.autoCode)}
        />
        <Table.Column dataIndex="phase" title="Phase" sorter={(a: Project, b: Project) => nullableDataSorter(a.phase, b.phase)} />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value, row: Project) =>
            row.status === 'finished' ? (
              <Tag color="geekblue">Finished</Tag>
            ) : row.status === 'active' ? (
              <Tag color="green">Active</Tag>
            ) : row.status === 'draft' ? (
              <Tag color="gray">Draft</Tag>
            ) : null
          }
        />
        <Table.Column
          dataIndex="contractEndDate"
          title="Contract Signed Date"
          render={value => formatDate(value)}
          sorter={(a: Project, b: Project) =>
            nullableDataSorter(a.contractEndDate ? new Date(a.contractEndDate) : null, b.contractEndDate ? new Date(b.contractEndDate) : null)
          }
        />
        <Table.Column
          dataIndex="startDate"
          title="Kickoff Start Date"
          render={value => formatDate(value)}
          sorter={(a: Project, b: Project) =>
            nullableDataSorter(a.startDate ? new Date(a.startDate) : null, b.startDate ? new Date(b.startDate) : null)
          }
        />
        <Table.Column
          dataIndex="estimatedEndDate"
          title="Estimated End Date"
          render={value => formatDate(value)}
          sorter={(a: Project, b: Project) =>
            nullableDataSorter(a.estimatedEndDate ? new Date(a.estimatedEndDate) : null, b.estimatedEndDate ? new Date(b.estimatedEndDate) : null)
          }
        />
        <Table.Column
          dataIndex="endDate"
          title="Actual End Date"
          render={value => formatDate(value)}
          sorter={(a: Project, b: Project) => nullableDataSorter(a.endDate ? new Date(a.endDate) : null, b.endDate ? new Date(b.endDate) : null)}
        />
        <Table.Column
          dataIndex="warrantyEndDate"
          title="Warranty End Date"
          render={value => formatDate(value)}
          sorter={(a: Project, b: Project) =>
            nullableDataSorter(a.warrantyEndDate ? new Date(a.warrantyEndDate) : null, b.warrantyEndDate ? new Date(b.warrantyEndDate) : null)
          }
        />
        <Table.Column dataIndex="category" title="Category" sorter={(a: Project, b: Project) => nullableDataSorter(a.category, b.category)} />
        {isAdmin && showFinancialInfo && (
          <>
            <Table.Column
              title="Budget"
              render={(value, row: Project) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.finance?.budget || 0)}
              sorter={(a: Project, b: Project) => nullableDataSorter(a.finance ? a.finance.budget : null, b.finance ? b.finance.budget : null)}
            />
            <Table.Column
              title="Outstanding Amount"
              render={(value, row: Project) =>
                !row.finance || Number(row.finance?.budget) === 0 ? (
                  <></>
                ) : (
                  <OutstandingAmount amount={(row.finance && getOutstandingAmount(row.finance)) || 0} />
                )
              }
              sorter={(a: Project, b: Project) =>
                nullableDataSorter(a.finance ? getOutstandingAmount(a.finance) : null, b.finance ? getOutstandingAmount(b.finance) : null)
              }
            />
          </>
        )}
      </Table>
    </>
  );
};

export default ProjectsTable;
