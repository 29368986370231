import axios from 'axios';
import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { Input, DatePicker, Button, Table, InputNumber, Popconfirm, Switch } from 'antd';
import { useSession } from '../../dal';
import ProjectSelector from '../project/ProjectSelector';
import { datePickerFormat } from '../../constants';
import { useMutateTasks } from './useMutateTasks';
import UsersMultiSelector from '../users/UsersMultiSelector';
import { useProjectMembersNotifications } from '../../common/useProjectMembersNotifications';

type ErrorStatus = '' | 'warning' | 'error' | undefined;

type Props = { projectId?: string | null; phaseId?: string | null; ticketId?: string; personal: boolean; userId?: number };

const AddTaskForm = ({ projectId, phaseId, ticketId, personal, userId }: Props) => {
  const { session } = useSession();
  const mutateTasks = useMutateTasks({ projectId, userId, ticketId });
  const { canAddMembers, showNotificationForAssignees } = useProjectMembersNotifications(projectId!);
  const [name, setName] = useState<string>();
  const [project, setProject] = useState<string | undefined | null>(projectId || undefined);
  const [dueDate, setDueDate] = useState<Moment | null>(null);
  const [assignees, setAssignees] = useState<number[]>();
  const [estimate, setEstimate] = useState<number | null>();
  const [nameStatus, setNameStatus] = useState<ErrorStatus>();
  const [isVisibleToClient, setIsVisibleToClient] = useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      setAssignees([userId]);
    } else if (!projectId && session) {
      setAssignees([session.id]);
    }
  }, [session, projectId, userId]);

  const clearFields = () => {
    let assignee;
    if (userId) {
      assignee = userId;
    } else if (!projectId && session) {
      assignee = session.id;
    } else {
      assignee = undefined;
    }

    setName('');
    setProject(projectId);
    setDueDate(null);
    setAssignees(assignee ? [assignee] : undefined);
    setNameStatus('');
    setEstimate(null);
    setIsVisibleToClient(false);
  };

  const handleFinish = async () => {
    if (!name) setNameStatus('error');

    if (!name) return;

    if (!personal && canAddMembers && assignees) {
      showNotificationForAssignees(assignees);
    }

    const resp = await axios.post(
      `/tasks`,
      {
        name,
        status: 'todo',
        state: 'new',
        assignees:
          personal && session
            ? [{ id: session.id }]
            : assignees?.map(a => ({
                id: a,
              })),
        projectId: project,
        ticketId: ticketId,
        phaseId: phaseId,
        dueDate: dueDate ? new Date(dueDate.toDate()) : null,
        estimate,
        isVisibleToClient,
      },
      { params: { ticketId, projectId: ticketId ? undefined : projectId, userId } },
    );

    await mutateTasks(resp.data);
    clearFields();
  };

  return (
    <Table.Summary fixed={'bottom'}>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={1}>
          <Input value={name} onChange={e => setName(e.target.value)} placeholder="Task name" status={nameStatus} />
        </Table.Summary.Cell>

        <Table.Summary.Cell index={1} colSpan={1}>
          <DatePicker format={datePickerFormat} value={dueDate} onChange={value => setDueDate(value)} />
        </Table.Summary.Cell>

        {!projectId && (
          <Table.Summary.Cell index={2} colSpan={1}>
            <ProjectSelector value={project} onChange={(value: string) => setProject(value)} style={{ width: '200px' }} />
          </Table.Summary.Cell>
        )}

        {!personal && (
          <Table.Summary.Cell index={3} colSpan={1}>
            <UsersMultiSelector
              projectId={projectId}
              value={assignees}
              onChange={(value: number[]) => setAssignees(value)}
              style={{ width: '175px' }}
            />
          </Table.Summary.Cell>
        )}
        <Table.Summary.Cell index={4}>
          <InputNumber controls={false} value={estimate} onChange={e => setEstimate(e)} min={0} precision={1} style={{ width: '50px' }} />
        </Table.Summary.Cell>

        <Table.Summary.Cell index={5}></Table.Summary.Cell>

        <Table.Summary.Cell index={6}>
          <Popconfirm
            title="You want to show this task for client. Are you sure?"
            onCancel={() => setIsVisibleToClient(false)}
            disabled={isVisibleToClient === true}
          >
            <Switch checkedChildren="visible" unCheckedChildren="hidden" checked={isVisibleToClient} onChange={e => setIsVisibleToClient(e)} />
          </Popconfirm>
        </Table.Summary.Cell>

        <Table.Summary.Cell index={7}>
          <Popconfirm title="You are creating a task without estimate. Are you sure?" onConfirm={handleFinish} disabled={!!estimate}>
            <Button type="primary" style={{ width: '100%' }} onClick={!!estimate ? handleFinish : undefined}>
              Add
            </Button>
          </Popconfirm>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};

export default AddTaskForm;
