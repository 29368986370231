import { Select, notification } from 'antd';
import axios from 'axios';
import { User } from '../entities';
import { ProjectPermissions } from './usePermissions/permissions';
import { usePermissions } from './usePermissions/usePermissions';
import { useProject, useProjectMembers, useUsers } from '../dal';

export function useProjectMembersNotifications(projectId: string): {
  canAddMembers: boolean;
  showNotificationForAssignees: (assignees: number[]) => void;
} {
  const { hasPermission } = usePermissions();
  const { project } = useProject(projectId);
  const { members } = useProjectMembers(projectId);
  const { users } = useUsers({ clientId: project?.client?.id });

  const canAddMembers = hasPermission(ProjectPermissions.PROJECT_MEMBERS_WRITE);

  const addUserAsMember = async (role: string, userId: number, projectId: string) => {
    await axios.post(`/projects/${projectId}/members`, { userId, role });
    notification.close(userId.toString());
  };

  const showNotificationForAssignees = async (assigneesIds: number[]) => {
    for (const assigneeId of assigneesIds) {
      const isAssigneeMember = members?.some(m => m.user.id === assigneeId) || users.some(m => m.id === assigneeId);
      if (!isAssigneeMember) {
        const { data } = await axios.get<{ data: User }>(`/users/${assigneeId}`);

        notification.warning({
          duration: null,
          message: <strong>{data.data.name} is not a member of a project</strong>,
          description: (
            <>
              Add user as:
              <Select style={{ width: '100%' }} onChange={role => addUserAsMember(role, assigneeId, projectId)}>
                {data.data.positions.map((role: any) => (
                  <Select.Option key={role} value={role}>
                    {role}
                  </Select.Option>
                ))}
              </Select>
            </>
          ),
          key: assigneeId.toString(),
        });
      }
    }
  };

  return { canAddMembers, showNotificationForAssignees };
}
