import { Spin, List, Avatar, Form, Button, Space, Card, Tag, Row, Col } from 'antd';
import { useMembersRoles, useProjects } from '../../dal';
import { useProjectMembers } from '../../dal/useProjectMembers';
import { ProjectMember } from '../../entities';
import { PlusOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import axios from 'axios';
import UserSelector from '../users/UserSelector';
import { useForm } from 'antd/lib/form/Form';
import RoleSelector from './RoleSelector';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ProjectPermissions } from '../../common/usePermissions/permissions';

type Props = {
  projectId: string;
};

const ProjectMembers = ({ projectId }: Props) => {
  const [form] = useForm();
  const { roles } = useMembersRoles();
  const [ loading, setLoading ] = useState(false);
  const { members, mutate } = useProjectMembers(projectId);
  const { mutate: mutateProjects } = useProjects()
  const { hasPermission } = usePermissions();

  const onFinish = useCallback(
    async values => {
      await axios.post(`/projects/${projectId}/members`, values);
      await mutate();
      await mutateProjects();
      form.resetFields();
    },
    [projectId, mutate, form, mutateProjects],
  );

  const onDelete = useCallback(
    async id => {
      await axios.delete(`/projects/${projectId}/members`, { data: { id } });
      await mutate();
      await mutateProjects();
    },
    [projectId, mutate, mutateProjects],
  );

  const onSync = useCallback(async () => {
    setLoading(true);
    await axios.post(`/projects/${projectId}/sync-members`);
    await mutate();
    await mutateProjects();
    setLoading(false);
  }, [projectId, mutate, mutateProjects]);

  if (!members) return <Spin />;

  members.sort((a, b) => roles.indexOf(a.role) - roles.indexOf(b.role));

  return (
    <Spin spinning={loading}>
      <Card title="Project members" size="small">
        <Space direction="vertical" style={{ width: '100%' }}>
          {hasPermission(ProjectPermissions.PROJECT_MEMBERS_WRITE) && (
            <Row justify="space-between">
              <Col>
                <Card size="small" bordered={false}>
                  <Form layout="inline" onFinish={onFinish} form={form}>
                    <Form.Item label="User" name="userId" rules={[{ required: true }]}>
                      <UserSelector style={{ width: '240px' }} />
                    </Form.Item>
                    <Form.Item label="Role" name="role" rules={[{ required: true }]}>
                      <RoleSelector style={{ width: '160px' }} />
                    </Form.Item>
                    <Form.Item>
                      <Button icon={<PlusOutlined />} htmlType="submit" type="link">
                        Add member
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
              <Col>
                <Button icon={<DownloadOutlined />} type="link" onClick={onSync}>
                  Sync from Budget Planner
                </Button>
              </Col>
            </Row>
          )}
          <List
            dataSource={members}
            renderItem={(member: ProjectMember) => (
              <List.Item
                actions={[
                  <Button
                    hidden={!hasPermission(ProjectPermissions.PROJECT_MEMBERS_DELETE)}
                    icon={<CloseOutlined />}
                    type="text"
                    onClick={() => onDelete(member.id)}
                  ></Button>,
                ]}
              >
                <List.Item.Meta avatar={<Avatar src={member.user.picture} size="large" />} title={member.user.name} description={member.user.email} />
                <Tag>{member.role}</Tag>
              </List.Item>
            )}
          />
        </Space>
      </Card>
    </Spin>
  );
};

export default ProjectMembers;
