export enum ApprovalsPermissions {
  APPROVALS_VIEW = 'APPROVALS_VIEW',
  APPROVALS_WRITE = 'APPROVALS_WRITE',
}

export enum SettingsPermissions {
  SETTINGS_VIEW = 'SETTINGS_VIEW',
  SETTINGS_WRITE = 'SETTINGS_WRITE',
}

export enum FilesPermissions {
  FILES_READ = 'FILES_READ',
  FILES_WRITE = 'FILES_WRITE',
}

export enum PresentationsPermissions {
  PRESENTATIONS_VIEW = 'PRESENTATIONS_VIEW',
  PRESENTATIONS_WRITE = 'PRESENTATIONS_WRITE',
}

export enum ReportPermissions {
  REPORTS_VIEW = 'TIMESHEET_REPORT_VIEW',
  REPORTS_SALES_READ = 'REPORTS_SALES_READ',
  REPORTS_CASHFLOW_READ = 'REPORTS_CASHFLOW_READ',
  REPORTS_TIMESHEET_READ = 'REPORTS_TIMESHEET_READ',
  REPORTS_TIMESHEET_RECALCULATE = 'REPORTS_TIMESHEET_RECALCULATE',
  REPORTS_TIMESHEET_DOWNLOAD_OR_SEND = 'REPORTS_TIMESHEET_DOWNLOAD_OR_SEND',
}

export enum ProjectPermissions {
  PROJECT_CREATE = 'PROJECTS_CREATE',
  PROJECT_UPDATE = 'PROJECTS_UPDATE',
  PROJECT_CLIENT_UPDATE = 'PROJECTS_CLIENT_UPDATE',
  PROJECT_DELETE = 'PROJECT_DELETE',
  PROJECT_FINISH = 'PROJECT_FINISH',
  PROJECT_BUDGET_PLANNER = 'PROJECT_BUDGET_PLANNER',
  PROJECT_BUDGET_PLANNER_SET_BUDGET = 'PROJECT_BUDGET_PLANNER_SET_BUDGET',
  PROJECT_FINANCES_UPDATE = 'PROJECT_FINANCES_UPDATE',
  PROJECT_FINANCES_READ = 'PROJECTS_FINANCES_READ',
  PROJECT_FINANCES_READ_COSTS = 'PROJECT_FINANCES_READ_COSTS',
  PROJECT_BUDGET_READ = 'PROJECTS_BUDGET_READ',
  PROJECT_ALL_VIEW = 'PROJECTS_ALL_VIEW',
  PROJECT_REPORT_READ = 'PROJECT_REPORT_READ',
  PROJECT_MEMBERS_WRITE = 'PROJECT_MEMBERS_WRITE',
  PROJECT_MEMBERS_DELETE = 'PROJECT_MEMBERS_DELETE',
  PROJECT_DRAFT_READ = 'PROJECT_DRAFT_READ',
  PROJECT_DRAFT_WRITE = 'PROJECT_DRAFT_WRITE',
}

export enum ClientPermissions {
  CLIENT_VIEW = 'CLIENT_VIEW',
  CLIENT_CREATE = 'CLIENT_CREATE',
  CLIENT_DELETE = 'CLIENT_DELETE',
  CLIENT_WRITE = 'CLIENT_WRITE',
  CLIENT_REPORT_READ = 'CLIENT_REPORT_READ',
  CLIENT_ACCOUNT_WRITE = 'CLIENT_ACCOUNT_WRITE',
  CLIENT_ACCOUNT_READ = 'CLIENT_ACCOUNT_READ',
}

export enum UserPermisions {
  USER_READ = 'USER_READ',
  USER_WRITE = 'USER_WRITE',
  USER_TIMESHEET_READ = 'USER_TIMESHEET_READ',
  USER_TASKS_READ = 'USER_TASKS_READ',
  USER_SETTINGS_WRITE = 'USER_SETTINGS_WRITE',
  USER_DELETE = 'USER_DELETE',
  USER_RATES_VIEW = 'USER_RATES_VIEW',
}

export enum TasksPermissions {
  TASKS_READ = 'TASKS_READ',
  TASKS_ADD = 'TASKS_ADD',
  TASKS_WRITE = 'TASKS_WRITE',
  TASKS_ESTIMATE_UPDATE = 'TASKS_ESTIMATE_UPDATE',
  TASKS_PHASES_WRITE = 'TASKS_PHASES_WRITE',
  TASKS_PHASES_READ = 'TASKS_PHASES_READ',
  TASKS_HISTORY_READ = 'TASKS_HISTORY_READ',
  TASKS_PHASES_DELETE = 'TASKS_PHASES_DELETE',
}

export enum TicketsPermissions {
  TICKETS_READ_ALL = 'TICKETS_READ_ALL',
  TICKETS_READ = 'TICKETS_READ',
  TICKETS_WRITE = 'TICKETS_WRITE',
  TICKETS_CREATE = 'TICKETS_CREATE',
  TICKETS_PROJECT_READ = 'TICKETS_PROJECT_READ',
}

export enum PayrollPermissions {
  PAYROLL_VIEW = 'PAYROLL_VIEW',
}

export enum BudgetReportPermissions {
  BUDGET_REPORT_VIEW = 'BUDGET_REPORT_VIEW',
}

export enum PortfolioPermissions {
  PORTFOLIO_VIEW = 'PORTFOLIO_VIEW',
}

export enum CommissionPermissions {
  COMMISSION_VIEW = 'COMMISSION_VIEW',
}

export type PermissionsType =
  | ApprovalsPermissions
  | SettingsPermissions
  | PresentationsPermissions
  | ReportPermissions
  | ProjectPermissions
  | ClientPermissions
  | UserPermisions
  | FilesPermissions
  | TasksPermissions
  | TicketsPermissions
  | PayrollPermissions
  | PortfolioPermissions
  | BudgetReportPermissions
  | CommissionPermissions;
