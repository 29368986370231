import { ChatChannel } from "../../../entities/chat";
import type { Project as IProject } from '../../../../entities';

export enum DashboardPages {
  CHAT = "chat",
  PROJECT = "project",
  CREATE = "create",
  INITIAL = "initial",
}

export enum ProjectsDisplay {
  SHORT = "short",
  ADVANCED = "advanced",
}

export enum CREATION_MENU_ACTIONS {
  PROJECT = 'project',
  MESSAGES = 'messages',
  CHANNEL = 'channel',
}

export type UrlParams = {
  id: string;
  tab: string;
};

export type ISelectedPage<T> = T extends ChatChannel
  ? {
    type: DashboardPages.CHAT;
    data: ChatChannel;
  }
  : T extends IProject
  ? {
    type: DashboardPages.PROJECT;
    data: IProject;
  }
  : {
    type: DashboardPages.CREATE | DashboardPages.INITIAL;
    data: any;
  };
