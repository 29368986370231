import { Tag } from 'antd';
import { getInvoiceRowCssClass, Invoice } from '../../entities/Invoice';

type Props = {
  invoice: Invoice;
};

export const InvoiceStatusTag = ({ invoice }: Props) => {
  var statusColor = getInvoiceRowCssClass(invoice);
  return (
    <Tag className={`status-tag ${invoice.paidDate ? 'paid-invoice' : statusColor}`} style={{ textTransform: 'capitalize' }}>
      {invoice.paidDate ? 'Paid' : statusColor.replace('-invoice', '')}
    </Tag>
  );
};
