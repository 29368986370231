import Layout from '../../layouts/authorised/Authorised';
import Dashboard from '../../chat/pages/dashboard/dashboard';

import './index.css';

const Home = () => {
  return (
    <Layout isFullPage fullHeight>
      <div id="chat-container">
        <Dashboard />
      </div>
    </Layout>
  );
};

export default Home;
