import { useState } from 'react';
import { Table, Button, Drawer, Space, InputNumber, Descriptions, Switch } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { ClockCircleOutlined, MessageOutlined, WarningOutlined } from '@ant-design/icons';
import AddTaskForm from './AddTaskForm';
import { nullableDataSorter } from '../../common/utils';
import { Project, Task, User } from '../../entities';
import TaskDetails from './TaskDetails';
import TaskName from './TaskName';
import TaskAssignees from './TaskAssignees';
import TaskDueDate from './TaskDueDate';
import TaskProject from './TaskProject';
import TaskStateTag from './TaskStateTag';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { TasksPermissions } from '../../common/usePermissions/permissions';
import { useSession } from '../../dal';
import TimesheetFormRow from '../Timesheet/TimesheetFormRow';

import './TaskTable.css';

type Props = {
  data: Task[];
  initialProjectId?: string;
  initialPhaseId?: string;
  initialTicketId?: string;
  personal: boolean;
  userId?: number;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
};

const TasksTable = ({ data, initialProjectId, initialPhaseId, initialTicketId, personal, userId, onTaskChange }: Props) => {
  const { hasPermission } = usePermissions();
  const { session } = useSession();
  const [detailedTask, setDetailedTask] = useState<Task | undefined>();
  const [timeTrackingTask, setTimeTrackingTask] = useState<Task | undefined>();
  const showDetails = (taskId: string) => {
    const task = data.filter(t => t.id === taskId)[0];
    setDetailedTask(task);
  };

  const showTracking = (taskId: string) => {
    const task = data.filter(t => t.id === taskId)[0];
    setTimeTrackingTask(task);
  };

  const closeDetails = () => {
    setDetailedTask(undefined);
  };

  const closeTracking = () => {
    setTimeTrackingTask(undefined);
  };

  const isTaskAssignedToUser = (assignees: User[] | null): boolean => {
    return assignees ? assignees.filter(a => a.id === session?.id).length > 0 : false;
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Task',
      sorter: (a: Task, b: Task) => nullableDataSorter(a.name, b.name),
      render: (value: string, record: Task) => (
        <Space direction="vertical">
          <TaskName task={record} onTaskChange={onTaskChange} />

          <TaskStateTag task={record} onTaskChange={onTaskChange} />
        </Space>
      ),
    },
    {
      dataIndex: 'dueDate',
      title: 'Due date',
      defaultSortOrder: 'ascend' as SortOrder,
      render: (value: any, record: Task) => <TaskDueDate task={record} onTaskChange={onTaskChange} />,
      sorter: (a: Task, b: Task) => nullableDataSorter(a.dueDate, b.dueDate),
    },
    {
      dataIndex: 'project',
      title: 'Project',
      render: (value: any, record: Task) =>
        !personal ? (
          <a href={`/projects/${record.project?.id}?defaultTab=tasks`}>
            {record.project?.client?.name} - {record.project?.name}
          </a>
        ) : (
          <TaskProject task={record} onTaskChange={onTaskChange} />
        ),
      sorter: (a: Task, b: Task) => nullableDataSorter(a.project?.name, b.project?.name),
    },
    {
      dataIndex: 'assignees',
      title: 'Assignees',
      render: (value: any, record: Task) => <TaskAssignees task={record} onTaskChange={onTaskChange} />,
      sorter: (a: Task, b: Task) => nullableDataSorter(a.dueDate, b.dueDate),
    },
    {
      dataIndex: 'estimate',
      title: 'Estimate [h]',
      render: (value: any, record: Task) =>
        hasPermission(TasksPermissions.TASKS_ESTIMATE_UPDATE) ? (
          <InputNumber
            defaultValue={value}
            controls={false}
            status={!value ? 'warning' : undefined}
            prefix={!value ? <WarningOutlined /> : undefined}
            onChange={e => onTaskChange({ id: record.id, estimate: e })}
            min={0}
            precision={1}
            style={{ width: '50px' }}
          />
        ) : (
          <InputNumber readOnly={true} bordered={false} defaultValue={value} controls={false} />
        ),
    },
    {
      dataIndex: 'timeSpent',
      title: 'Time Spent [h]',
      render: (value: any, record: Task) => (
        <InputNumber defaultValue={value} readOnly={true} bordered={false} precision={1} style={{ width: '50px' }} />
      ),
    },
    {
      dataIndex: 'isVisibleToClient',
      title: 'Visible to client',
      render: (value: any, record: Task) => (
        <Switch
          checkedChildren="visible"
          unCheckedChildren="hidden"
          defaultChecked={value}
          onChange={() => onTaskChange({ id: record.id, isVisibleToClient: !record.isVisibleToClient })}
        />
      ),
    },
    {
      dataIndex: 'details',
      title: '',
      render: (value: any, record: Task) => (
        <Space direction="vertical">
          <Button type="link" onClick={() => showDetails(record.id)}>
            More
            {record.description && <MessageOutlined />}
          </Button>
          {isTaskAssignedToUser(record.assignees) && (
            <Button type="text" onClick={() => showTracking(record.id)}>
              <ClockCircleOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const visibleColumns = columns
    .filter((c: any) => !initialProjectId || c.dataIndex !== 'project')
    .filter((c: any) => !personal || c.dataIndex !== 'assignees');

  return (
    <>
      <Table
        dataSource={data}
        columns={visibleColumns}
        size="small"
        className="task-table"
        pagination={false}
        rowKey={record => record.id}
        summary={() =>
          hasPermission(TasksPermissions.TASKS_ADD) && (
            <AddTaskForm projectId={initialProjectId} phaseId={initialPhaseId} ticketId={initialTicketId} personal={personal} userId={userId} />
          )
        }
      />

      {detailedTask && (
        <Drawer title={detailedTask.name} placement="right" size="large" onClose={closeDetails} open={!!detailedTask}>
          <TaskDetails task={detailedTask} onTaskChange={onTaskChange} />
        </Drawer>
      )}

      {timeTrackingTask && (
        <Drawer title={timeTrackingTask.name} placement="right" size="large" onClose={closeTracking} open={!!timeTrackingTask}>
          <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {timeTrackingTask.estimate && (
              <Descriptions bordered>
                <Descriptions.Item label="Estimate">{timeTrackingTask.estimate} h</Descriptions.Item>
                <Descriptions.Item label="Time spent">{timeTrackingTask.timeSpent} h</Descriptions.Item>
                <Descriptions.Item label="Time left">{timeTrackingTask.estimate - timeTrackingTask.timeSpent} h</Descriptions.Item>
              </Descriptions>
            )}

            <TimesheetFormRow
              projectId={(timeTrackingTask.project as Project).id}
              taskId={timeTrackingTask.id}
              user={session}
              date={new Date().toString()}
              vertical
              onSubmitAction={closeTracking}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};

export default TasksTable;
