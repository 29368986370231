import useSWR from 'swr';
import { FetchResult } from '../entities';
import { Invoice } from '../entities/Invoice';
import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import moment, { isMoment, Moment } from 'moment';

type Result = FetchResult & {
  invoices: Invoice[] | undefined;
  saveInvoice: (payload: Invoice) => Promise<AxiosResponse<any>>;
  deleteInvoice: (invoiceId: string) => Promise<AxiosResponse<any>>;
};

export function useInvoices(forMonth: string | null): Result & { invoices: Invoice[] | undefined } {
  const { data, error, mutate } = useSWR<Invoice[]>(`/invoices?forMonth=${forMonth || ''}`);

  const saveInvoice = useCallback((invoice: Invoice) => axios.put(`/projects/${invoice.projectId}/invoices`, formatForSave(invoice)), []);
  const deleteInvoice = useCallback((invoiceId: string) => axios.delete(`/invoices/${invoiceId}`), []);

  return {
    invoices: data && formatInvoices(data),
    isLoading: !error && !data,
    isError: error,
    mutate,
    saveInvoice,
    deleteInvoice,
  };
}

export function useProjectInvoices(projectId: string): Result {
  const { data, error, mutate } = useSWR<Invoice[]>(`/projects/${projectId}/invoices`);

  const saveInvoice = useCallback((invoice: Invoice) => axios.put(`/projects/${projectId}/invoices`, formatForSave(invoice)), [projectId]);
  const deleteInvoice = useCallback((invoiceId: string) => axios.delete(`/projects/${projectId}/invoices/${invoiceId}`), [projectId]);

  return {
    invoices: data && formatInvoices(data),
    isLoading: !error && !data,
    isError: error,
    mutate,
    saveInvoice,
    deleteInvoice,
  };
}

const formatForSave = (invoice: Invoice) => {
  return {
    ...invoice,
    estimatedPayDate: momentToString(invoice.estimatedPayDate),
    sentDate: momentToString(invoice.sentDate),
    paidDate: momentToString(invoice.paidDate),
    forMonth: isMoment(invoice.forMonth) ? (invoice.forMonth as Moment).startOf('month').format('YYYY-MM-DD') : null,
    dateRange: invoice.dateRange ? invoice.dateRange.map(momentToString) : [],
  };
};

const formatInvoices = (invoices: Invoice[]): Invoice[] => {
  return invoices.map(invoice => {
    if (invoice.dateRange) {
      return {
        ...invoice,
        details: invoice.details.map(detail => ({
          ...detail,
          totalMinutes:
            typeof detail.totalMinutes === 'string'
              ? detail.totalMinutes.split(':').reduce((acc, val, i) => acc + Number(val) * (i === 0 ? 60 : 1), 0)
              : detail.totalMinutes,
          totalCost: +detail.totalCost,
        })),
        dateRange: [invoice.dateRange[0] ? moment(invoice.dateRange[0]) : null, invoice.dateRange[1] ? moment(invoice.dateRange[1]) : null],
      };
    }
    return invoice;
  });
};

const momentToString = (date: any) => (isMoment(date) ? (date as Moment).format('YYYY-MM-DD') : null);
