import useSWR from 'swr';
import { FetchResult, User } from '../entities';
import { PermissionsType } from '../common/usePermissions/permissions';
import { axiosChatInstance } from '../../api/useChatApi';
import { useSession } from '../../dal';
import { useFeatureFlag } from 'configcat-react';

export type UserWithPermissions = User & { permissions: PermissionsType[] };
type Result = FetchResult & { session?: UserWithPermissions };

const fetcher = (url: string) => axiosChatInstance({ url, method: "GET" }).then(res => res.data);

export function useChatSession(searchParams?): Result {
  const { session } = useSession();
  const { value: chatEnabled } = useFeatureFlag('chat', false, { identifier: session?.email! });
  const { data, error, mutate } = useSWR<{ user: UserWithPermissions }>(chatEnabled ? `/session?${searchParams}` : null, fetcher);

  return {
    session: data?.user,
    isLoading: !error && !data && chatEnabled,
    isError: error || !data?.user,
    mutate,
  };
}
