import { Row, Tabs } from 'antd';
import { ProfileFilled, ReadFilled, PercentageOutlined } from '@ant-design/icons';
import Layout from '../layouts/authorised/Authorised';
import {
  ApprovalsPermissions,
  PayrollPermissions,
  BudgetReportPermissions,
  PortfolioPermissions,
  ProjectPermissions,
  ReportPermissions,
  UserPermisions,
} from '../common/usePermissions/permissions';
import { usePermissions } from '../common/usePermissions/usePermissions';

import { ReactComponent as IconApprovals } from '../images/icons/icon-approvals.svg';
import { ReactComponent as IconSales } from '../images/icons/icon-sales.svg';
import { ReactComponent as IconTimesheetAdmin } from '../images/icons/icon-timesheet-admin.svg';

import './Settings.scss';
import React from 'react';
import SalesReport from './reports/SalesReport';
import TimesheetReport from './reports/TimesheetReport';
import Approvals from './approvals';
import Payroll from './payroll';
import EmployeeRates from './payroll/employeeRates';
import CashFlowReport from './reports/CashFlowReport';
import ProjectsTable from '../components/project/ProjectsTable';
import { useProjects } from '../dal';
import { PortfolioTableForm } from '../components/portfolio/PortfolioTableForm';
import BudgetReport from './payroll/budgetReport';
import CommissionReport from './reports/CommissionReport';

const ReportsMain = () => {
  const { hasPermission, ability } = usePermissions();

  const { projects = [] } = useProjects();

  const [activePage, setActivePage] = React.useState<string>('sales');

  const tabRef = React.createRef<HTMLDivElement>();

  const onMouseMove = (e: MouseEvent) => {
    const [sidebarInner, mainContainerInner] = [document.getElementById('sidebar-inner'), document.getElementById('main-container-inner')];
    if (sidebarInner && mainContainerInner) {
      document.body.style.userSelect = 'none';

      if (e.pageX > 225 && e.pageX < 600) {
        document.body.className = e.pageX - 55 + '';
        sidebarInner.style.width = `${e.pageX - 55}px`;
        sidebarInner.style.display = 'block';
      }
    }
  };

  // disable resizing
  const handleMouseUp = () => {
    document.body.style.userSelect = '';

    localStorage.setItem('left-bar-width-reports', document.body.className);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const onMouseDown = () => {
    document.addEventListener('mousemove', onMouseMove);

    document.addEventListener('mouseup', handleMouseUp);
  };

  React.useEffect(() => {
    const elem = tabRef.current,
      tabs = elem?.getElementsByClassName('settings-tabs')[0];

    const notFormatedWidth = localStorage.getItem('left-bar-width-reports');

    const width = notFormatedWidth !== null && notFormatedWidth.length > 0 ? Number(localStorage.getItem('left-bar-width-reports')) : 250;

    const childrens = tabs?.children;

    if (tabs && !tabs.getElementsByClassName('custom-divider').length && childrens?.length) {
      const [sidebarInner, mainContainerInner] = [
        document.getElementsByClassName('ant-tabs-nav')[0],
        document.getElementsByClassName('ant-tabs-content-holder')[0],
      ];
      sidebarInner.id = 'sidebar-inner';
      mainContainerInner.id = 'main-container-inner';

      const leftId = document.getElementById('sidebar-inner');

      if (width >= 0 && leftId) {
        if (width > 135 && width < 600) {
          leftId.style.width = width + 'px';
          leftId.style.display = 'flex';
        }
      }

      const elementAfter = tabs.getElementsByClassName('ant-tabs-nav')[0],
        divider = document.createElement('div');
      divider.addEventListener('mousedown', onMouseDown);
      divider.className = 'custom-divider';
      elementAfter.after(divider);
    }

    return () => {
      document.getElementsByClassName('custom-divider')[0]?.addEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabRef]);

  return (
    <Layout isFullPage fullHeight>
      <div ref={tabRef} style={{ height: '100%' }}>
        <Tabs
          tabPosition="left"
          style={{ height: '100%' }}
          onChange={setActivePage}
          tabBarStyle={{ width: 280 }}
          tabBarGutter={0}
          id="settings-tabs"
          className="settings-tabs"
          activeKey={activePage}
          items={
            [
              {
                key: 'reports-header',
                disabled: true,
                label: (
                  <span className=" cursor-default !m-0 font-[Proxima Nova] font-[700] text-[#1D1D1D] text-[21px] !pl-4 text-ellipsis overflow-hidden whitespace-nowrap">
                    Reports
                  </span>
                ),
              },
              hasPermission(ReportPermissions.REPORTS_SALES_READ) && {
                key: 'sales',
                label: (
                  <Row className="items-center">
                    <IconSales className="text-black" />
                    <span>Sales</span>
                  </Row>
                ),
                children: <SalesReport />,
              },
              hasPermission(ReportPermissions.REPORTS_CASHFLOW_READ) && {
                key: 'cashflow',
                label: (
                  <Row className="items-center">
                    <IconSales className="text-black" />
                    <span>Cash Flow</span>
                  </Row>
                ),
                children: <CashFlowReport />,
              },
              hasPermission(ReportPermissions.REPORTS_TIMESHEET_READ) && {
                key: 'timesheet',
                label: (
                  <Row className="items-center">
                    <IconTimesheetAdmin />
                    <span>Timesheet report</span>
                  </Row>
                ),
                children: <TimesheetReport />,
              },
              hasPermission(ApprovalsPermissions.APPROVALS_VIEW) && {
                key: 'approvals',
                label: (
                  <Row className="items-center">
                    <IconApprovals />
                    <span>Approvals</span>
                  </Row>
                ),
                children: <Approvals />,
              },
              hasPermission(PayrollPermissions.PAYROLL_VIEW) && {
                key: 'payroll',
                label: (
                  <Row className="items-center">
                    <IconSales />
                    <span>Payroll</span>
                  </Row>
                ),
                children: <Payroll />,
              },
              hasPermission(BudgetReportPermissions.BUDGET_REPORT_VIEW) && {
                key: 'phaseBrakedown',
                label: (
                  <Row className="items-center">
                    <IconSales />
                    <span>Budget Report</span>
                  </Row>
                ),
                children: <BudgetReport />,
              },
              hasPermission(UserPermisions.USER_RATES_VIEW) && {
                key: 'employee-rates',
                label: (
                  <Row className="items-center">
                    <IconSales />
                    <span>Employee Rates</span>
                  </Row>
                ),
                children: <EmployeeRates />,
              },
              hasPermission(ProjectPermissions.PROJECT_ALL_VIEW) && {
                key: 'all-projects',
                label: (
                  <Row className="items-center">
                    <ProfileFilled style={{ fontSize: '20px' }} />
                    <span>All projects</span>
                  </Row>
                ),
                children: <ProjectsTable projects={projects} filterKey="allprojects" />,
              },
              hasPermission(PortfolioPermissions.PORTFOLIO_VIEW) && {
                key: 'portfolio',
                label: (
                  <Row className="items-center">
                    <ReadFilled style={{ fontSize: '20px' }} />
                    <span>Portfolio</span>
                  </Row>
                ),
                children: <PortfolioTableForm />,
              },
              ability.can('view', 'Commission') && {
                key: 'commission',
                label: (
                  <Row className="items-center">
                    <PercentageOutlined style={{ fontSize: '20px' }} />
                    <span>Commission</span>
                  </Row>
                ),
                children: <CommissionReport />,
              },
            ].filter(Boolean) as []
          }
        />
      </div>
    </Layout>
  );
};

export default ReportsMain;
