import { useState, useEffect, useCallback } from 'react';
import { Input, Select, Spin } from 'antd';
import { useDebounce } from 'use-debounce';
import { PortfolioTable } from './PortfolioTable';
import { useProjectsWithLinks } from '../../dal/useProjectsWithLinks';
import { formPortfolio, Portfolio, OFFSET, LIMIT, formChangedLink, ChangedLink, HOSTING_OPTIONS, INDUSTRY_OPTIONS } from './utils';
import { Project } from '../../entities';

import './PortfolioTableForm.css';

export const PortfolioTableForm = () => {
  const [allPortfolio, setAllPortfolio] = useState<Portfolio[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [phrase, setPhrase] = useState('');
  const [hosting, setHosting] = useState();
  const [industry, setIndustry] = useState();
  const [offset, setOffset] = useState(OFFSET);
  const [limit, setLimit] = useState(LIMIT);

  const [filter] = useDebounce(phrase, 500);

  const { projects, count, isLoading, updateLink, createLink, deleteLink, updateProject, mutate } = useProjectsWithLinks(
    offset,
    limit,
    filter,
    hosting,
    industry,
  );

  const processProjects = useCallback((projects: Project[], count: number) => {
    const filteredProjects = projects.map(({ id, name, client, phase, portfolioLinks, hosting, industry, autoCode }) => ({
      id,
      name,
      client,
      phase,
      portfolioLinks,
      hosting,
      industry,
      autoCode,
    })) as Project[];

    const portfolio = formPortfolio(filteredProjects);

    setAllPortfolio(portfolio);
    setTotalItems(count);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      processProjects(projects, count);
    } else {
      setLoading(false);
    }
  }, [projects, count, processProjects, isLoading]);

  const handlePaginationChange = useCallback((page, pagesize) => {
    const newOffset = pagesize * (page - 1);
    setOffset(newOffset);
    setLimit(pagesize);
  }, []);

  const handleSave = useCallback(
    async (index: number, changedLink: ChangedLink) => {
      try {
        const changedProject = allPortfolio[index];
        const formatedChangedLink = formChangedLink(changedProject, changedLink);

        if (formatedChangedLink) {
          const { linkId, ...link } = formatedChangedLink;

          if (linkId && formatedChangedLink.url === '') {
            await deleteLink(linkId);
          } else if (linkId) {
            await updateLink(linkId, link);
          } else {
            await createLink(formatedChangedLink);
          }

          await mutate();
        }
      } catch (error) {
        console.error('Error saving link:', error);
      }
    },
    [allPortfolio, updateLink, createLink, deleteLink, mutate],
  );

  const handleSelectChange = useCallback(
    async (value, index: number, field: string) => {
      try {
        const changedProject = allPortfolio[index];
        const { projectId } = changedProject;

        await updateProject(projectId, field, value);

        await mutate();
      } catch (error) {
        console.error('Error updating project:', error);
      }
    },
    [allPortfolio, updateProject, mutate],
  );

  return (
    <Spin spinning={loading}>
      <Input className="search-input" placeholder="Search by project name, client or code" value={phrase} onChange={e => setPhrase(e.target.value)} />

      <Select
        className="search-select search-select-hosting"
        showSearch
        allowClear
        placeholder="Search by hosting"
        value={hosting}
        onChange={setHosting}
        options={HOSTING_OPTIONS.slice(1)}
      />

      <Select
        className="search-select search-select-industry"
        showSearch
        placeholder="Search by industry"
        value={industry}
        onChange={setIndustry}
        options={INDUSTRY_OPTIONS}
        mode="multiple"
        allowClear
        style={{ width: '150px', marginLeft: '16px' }}
      />

      <PortfolioTable
        allPortfolio={allPortfolio}
        handleSave={handleSave}
        handleSelectChange={handleSelectChange}
        handlePaginationChange={handlePaginationChange}
        totalItems={totalItems}
      />
    </Spin>
  );
};
