import { Form, Row, Col, Divider, Input, Button, Popconfirm, Select, message, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { User } from '../../entities';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import UserPositionsSelector from './UserPositionsSelector';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

type Props = {
  user: User;
};

const UserEditableOverview = ({ user }: Props) => {
  const [form] = useForm();

  const onFinish = async (values: any) => {
    await axios.patch(`/users/patch-profile`, { ...values, id: user.id });
    message.success('Profile updated');
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        title: user.title,
        role: user.role,
        positions: user.positions,
        bio: user.bio,
        monthOfBirth: user.monthOfBirth,
        dayOfBirth: user.dayOfBirth,
        phone: user.phone,
        links: user.links,
      }}
      layout="vertical"
    >
      <Form.Item label="Title" name="title" rules={[{ required: true, message: 'This field is required.' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Roles" name="positions" rules={[{ required: true, message: 'This field is required.' }]}>
        <UserPositionsSelector style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item label="What I do" name="role" rules={[{ required: true, message: 'This field is required.' }]}>
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item label="Bio" name="bio" rules={[{ required: true, message: 'This field is required.' }]}>
        <TextArea rows={4} />
      </Form.Item>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Day of birth" name="dayOfBirth" rules={[{ required: true, message: 'This field is required.' }]}>
            <InputNumber min={1} max={31} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Month of birth" name="monthOfBirth" rules={[{ required: true, message: 'This field is required.' }]}>
            <Select>
              <Select.Option value={null} key={0}>
                {' '}
              </Select.Option>
              {months.map(i => (
                <Select.Option value={i} key={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Phone" name="phone">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.List name="links">
        {(fields: any, { add, remove }) => (
          <>
            <Row>
              <Col span="7">Label</Col>
              <Col span="1"></Col>
              <Col span="16">Link</Col>
            </Row>
            <Divider />
            {fields.map((field: any) => (
              <Row key={field.key}>
                <Col span="7">
                  <Form.Item {...field} name={[field.name, 'label']} rules={[{ required: true, message: 'Missing label' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="1"></Col>
                <Col span="15">
                  <Form.Item {...field} name={[field.name, 'link']}>
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span="1">
                  <Popconfirm title="Are you sure to delete this link?" onConfirm={() => remove(field.name)} okText="Yes" cancelText="No">
                    <Button type="text" icon={<MinusCircleOutlined />} />
                  </Popconfirm>
                </Col>
              </Row>
            ))}
            <Row>
              <Col span="23">
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add link
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserEditableOverview;
