import useSWR from 'swr';
import { FetchResult } from '../entities';

type Result = FetchResult & { 
  users: UserBrief[], 
  usersDict: Record<number, UserBrief>
};

type UseUsersParams = {
  activeOnly?: boolean;
  includeClientUsers?: boolean;
};

export type UserBrief = {
  id: number;
  picture: string;
  name: string;
  title: string;
  email: string;
  avatarColor: string;
  initials: string;
  client: { id: string } | null
};

export function useUsersBrief({ activeOnly = false, includeClientUsers = false }: UseUsersParams = {}): Result {
  const queryParams = new URLSearchParams();

  queryParams.append('activeOnly', activeOnly.toString());
  queryParams.append('includeClientUsers', includeClientUsers.toString());

  const endpoint = `/users/brief${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;

  const { data, error, mutate } = useSWR(endpoint);

  const users = data?.data || [];

  const usersDict = Object.fromEntries(users.map(u => [u.id, u]));

  return {
    users,
    usersDict,
    isLoading: !error && !data,
    isError: !!error,
    mutate,
  };
}
