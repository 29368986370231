import useSWR from 'swr';
import { Commission, FetchResult } from '../entities';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { axiosBackend } from '../api/backend';

type Result = FetchResult & {
  commission: Commission[];
  saveCommission: (commission: Commission) => Promise<AxiosResponse<any>>;
  deleteCommission: (invoiceId: string) => Promise<AxiosResponse<any>>;
};

export function useProjectCommission(projectId: string): Result {
  const { data, error, mutate } = useSWR(`/projects/${projectId}/commission`);

  const saveCommission = useCallback((commission: Commission) => axiosBackend.post(`/projects/${projectId}/commission`, commission), [projectId]);
  const deleteCommission = useCallback(
    (commissionId: string) => axiosBackend.delete(`/projects/${projectId}/commission/${commissionId}`),
    [projectId],
  );

  return {
    commission: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    saveCommission,
    deleteCommission,
  };
}
