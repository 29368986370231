import useSWR from 'swr';
import { FetchResult } from '../entities';
import { RangeValueType } from '../entities/Invoice';

export type TimesheetSummary = {
  key: string;
  userId: number;
  category: string;
  ratePerHour: number;
  rateType: string;
  totalMinutes: number | string;
  totalCost: number;
  fixedCost: number;
  phaseName: string;
  userName: string;
  billableHours: number;
  billableRate: number;
  billableTotal: number;
  fixedCostTotal: number;
  fixedBillableTotal: number;
  isGroup?: boolean;
};

type Result = FetchResult & { timesheets: TimesheetSummary[] };

export function useProjectsExtendedTimesheetSummary(id: string, dateRange: RangeValueType): Result {
  const queryString = dateRange ? `?from=${dateRange[0]?.format('YYYY-MM-DD')}&to=${dateRange[1]?.format('YYYY-MM-DD')}` : '';
  const { data, error, mutate } = useSWR(dateRange ? `/projects/${id}/timesheet-summary-extended${queryString}` : null);

  const timesheets = data?.data || [];

  return {
    timesheets: timesheets.map((t, index) => ({
      ...t,
      key: `${index}_${t.phaseName || ''}_${t.category}_${t.userId}_${t.rateType}_${t.ratePerHour}${t.fixedCost ? '_fixedCost' : ''}`,
      totalMinutes: Number(t.totalMinutes),
      totalCost: Number(t.totalCost),
      fixedCost: Number(t.fixedCost),
    })),
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
