import { Button, Divider, Input, message, PageHeader, Popconfirm, Tabs } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSettings } from '../../dal/useSettings';
import TemplatePhases from './TemplatePhases';

export interface Phase {
  name: string;
  lengthInWeeks: number;
  tasks: string[];
}

export interface Template {
  name: string;
  phases: Phase[];
}

export interface PhaseTasksSetting {
  templates: Array<Template>;
}

const SettingsPhases = () => {
  const { settings, key, mutate } = useSettings<PhaseTasksSetting>('phases_tasks');
  const [newTemplate, setNewTemplate] = useState<string>('');
  const [newPhase, setNewPhase] = useState<string>('');
  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    if (settings && settings.templates) {
      setTemplates(settings.templates);
    }
  }, [settings]);

  const addTemplate = () => {
    const checkIfExists = templates.filter(t => t.name === newTemplate).length > 0;
    if (checkIfExists) {
      message.warning('Template with that name already exists');
    } else {
      setTemplates(t => [...t, { name: newTemplate, phases: [] }]);
      setNewTemplate('');
    }
  };

  const addPhase = (templateName: string) => {
    const template = templates.filter(t => t.name === templateName)[0];
    const checkIfExists = template.phases.filter(p => p.name === newPhase).length > 0;
    if (checkIfExists) {
      message.warning('Phase with that name already exists');
    } else {
      saveTemplatePhases([...template.phases, { name: newPhase, lengthInWeeks: 1, tasks: [] }], templateName);
      setNewPhase('');
    }
  };

  const removeTemplate = (name: string) => {
    setTemplates(t => [...t.filter(t => t.name !== name)]);
  };

  const saveTemplatePhases = (phases: Phase[], name: string) => {
    setTemplates(t => {
      const objIndex = t.findIndex(i => i.name === name);
      t[objIndex].phases = phases;
      return [...t];
    });
  };

  const save = async () => {
    await axios.post(`/settings`, { key: key, value: { templates } });
    await mutate();
    message.success('Saved successfully');
  };

  if (!settings) return null;

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title="Templates settings"
        extra={
          <Input.Group compact style={{ marginBottom: 24 }}>
            <Input style={{ width: 300 }} value={newTemplate} onChange={e => setNewTemplate(e.target.value)} placeholder="New template name" />
            <Button type="primary" onClick={addTemplate}>
              Add
            </Button>
          </Input.Group>
        }
      />

      <div className="ant-space-vertical">
        <Tabs
          items={templates.map(t => ({
            key: t.name,
            label: t.name,
            children: (
              <>
                <div style={{ display: 'flex', gap: 8 }}>
                  <Popconfirm
                    title="Are you sure you want to delete this template?"
                    onConfirm={() => removeTemplate(t.name)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger>Delete template</Button>
                  </Popconfirm>
                  <Input.Group compact>
                    <Input style={{ width: 175 }} value={newPhase} onChange={e => setNewPhase(e.target.value)} placeholder="New phase" />
                    <Button type="primary" onClick={() => addPhase(t.name)}>
                      Add
                    </Button>
                  </Input.Group>
                </div>

                <Divider />

                <h3>Phases</h3>

                <TemplatePhases phases={t.phases} onChange={phases => saveTemplatePhases(phases, t.name)} />
              </>
            ),
          }))}
        />

        <Button type="primary" onClick={save} style={{ marginTop: 24 }}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default SettingsPhases;
