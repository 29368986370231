import { useCallback, useState } from 'react';
import { Typography, Button, PageHeader, Table, Menu, Space, message, Dropdown } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, CalculatorOutlined, DownloadOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { useTimesheetReport } from '../../dal/useTimesheetReport';
import UserSelector from '../../components/users/UserSelector';
import axios from 'axios';
import { formatCurrency } from '../../common/utils';
import { TimesheetReportItem } from '../../entities';
import { saveAs } from 'file-saver';

const Summary = (data: readonly any[]) => {
  let totalAmount = 0;
  let totalHours = 0;

  data.forEach(({ amount, fixedCost, hours }) => {
    totalAmount += +amount || +fixedCost;
    totalHours += +hours;
  });

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} align="right" colSpan={3}>
          {' '}
          Total
        </Table.Summary.Cell>

        <Table.Summary.Cell index={1} align="right">
          <Typography.Text>{totalHours.toFixed(2)}</Typography.Text>
        </Table.Summary.Cell>

        <Table.Summary.Cell index={2} align="right">
          <Typography.Text>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount || 0)}</Typography.Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};

const TimesheetReport = () => {
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment().startOf('month'));
  const [userId, setUserId] = useState<number | null>(null);
  const { report, mutate } = useTimesheetReport(userId, currentMonth.format('YYYY-MM-DD'));
  const [downloadIsProcess, setDownloadInProgress] = useState<boolean>(false);

  const recalulate = useCallback(async () => {
    await axios.post(`/project-finances/timesheet-report`, { userId, currentMonth: currentMonth.format('YYYY-MM-DD') });
    await mutate(null, true);
  }, [userId, currentMonth, mutate]);

  const download = useCallback(() => {
    setDownloadInProgress(true);
    axios
      .get(`/project-finances/timesheet-report/download?currentMonth=${currentMonth.format('YYYY-MM-DD')}`, {
        responseType: 'blob',
      })
      .then(response => {
        const fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
        saveAs(response.data, `${fileName}.csv`);
        setDownloadInProgress(false);
      });
  }, [currentMonth]);

  const sendEmail = () => {
    axios.post(`/project-finances/timesheet-report/send?currentMonth=${currentMonth.format('YYYY-MM-DD')}`);
    message.success('Email sent');
  };
  const sendEmailCurrentUser = () => {
    axios.post(`/project-finances/timesheet-report/send?currentMonth=${currentMonth.format('YYYY-MM-DD')}&userId=${userId}`);
    message.success('Email sent');
  };

  const menu = (
    <Menu onClick={sendEmailCurrentUser}>
      <Menu.Item key="selectedUser" disabled={!userId}>
        For selected user
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title="Timesheet Report"
        className="flex-wrap"
        subTitle={
          <Space>
            <Button icon={<ArrowLeftOutlined />} onClick={() => setCurrentMonth(currentMonth.clone().subtract(1, 'month'))} />
            {currentMonth.format('MMMM YYYY')}
            <Button icon={<ArrowRightOutlined />} onClick={() => setCurrentMonth(currentMonth.clone().add(1, 'month'))} />
            <Button icon={<DownloadOutlined />} onClick={download} loading={downloadIsProcess}>
              Monthly report
            </Button>
            <Dropdown.Button onClick={sendEmail} overlay={menu}>
              Monthly report
            </Dropdown.Button>
          </Space>
        }
        extra={[
          <UserSelector allowClear style={{ width: '240px' }} value={userId} onChange={e => setUserId(e)} />,
          <Button icon={<CalculatorOutlined />} onClick={recalulate} type="primary" disabled={!report}>
            Recalculate rates
          </Button>,
        ]}
      />

      <div className="ant-space-vertical">
        <Table bordered size="small" dataSource={report} summary={Summary} pagination={false}>
          <Table.Column
            dataIndex="description"
            title="Description"
            render={(_, row: TimesheetReportItem) => `${row.projectCode} - ${row.clientName} - ${row.projectName}`}
          />
          <Table.Column dataIndex="category" title="Category" />
          <Table.Column
            dataIndex="rate"
            title="Rate"
            align="right"
            render={(value, row: TimesheetReportItem) => (row.fixedCost !== 0 ? '' : formatCurrency(value))}
          />
          <Table.Column
            dataIndex="hours"
            title="Hours"
            align="right"
            render={(value, row: TimesheetReportItem) => (row.fixedCost !== 0 ? '' : value.toFixed(2))}
          />
          <Table.Column
            dataIndex="amount"
            title="Amount"
            align="right"
            render={(amount, row: TimesheetReportItem) => formatCurrency(row.fixedCost !== 0 ? row.fixedCost : amount)}
          />
        </Table>
      </div>
    </div>
  );
};

export default TimesheetReport;
