import { Input, InputProps } from 'antd';
import { useState } from 'react';
import { displayHoursFromNumber, displayNumberFromHours } from '../../common/utils';

export const checkHours = (_: any, value: any) => {
  if (displayNumberFromHours(value) > 0) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Time spent is required'));
};

type Props = Omit<InputProps, 'onChange'> & {
  onChange?: (hours, number) => void;
};

const HoursInput = (props: Props) => {
  const [value, setValue] = useState<string>();

  const formatValue = () => {
    if (!!value && !value.toString().includes(':')) {
      setValue(displayHoursFromNumber(Number(value)).toString());
    }
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
    props.onChange && props.onChange(e.target.value, displayNumberFromHours(e.target.value));
  };

  return (
    <Input
      {...props}
      value={value || (props.value && displayHoursFromNumber(Number(props.value)))}
      onChange={e => handleChange(e)}
      onBlur={formatValue}
      bordered={!props.readOnly}
    />
  );
};

export default HoursInput;
