const DOTS_LENGTH = 3;
const MAX_DOMAIN_LENGTH = 14;
const MAX_LINK_LENGTH = MAX_DOMAIN_LENGTH + DOTS_LENGTH;

export const formatLink = (url: string): string => {
  const strippedUrl = url.replace(/^https?:\/\//, '').replace(/^www\./, '');

  if (strippedUrl.length <= MAX_LINK_LENGTH) {
    return strippedUrl;
  }

  const [domain, ...pathParts] = strippedUrl.split('/');
  const path = pathParts.join('/');

  if (domain.length >= MAX_DOMAIN_LENGTH) {
    return domain.substring(0, MAX_DOMAIN_LENGTH) + '...';
  }

  const maxPathLength = MAX_LINK_LENGTH - domain.length - DOTS_LENGTH;

  return domain + '/' + path.substring(0, maxPathLength) + '...';
};

export const getPathFromLink = (link: string): string => {
  if (!link.includes('/')) {
    return '';
  }
  return link.substring(link.indexOf('/') + 1);
};
