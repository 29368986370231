import { Divider } from 'antd';
import { useFeatureFlag } from 'configcat-react';
import queryString from 'query-string';
import { useEffect, useState, useCallback, useMemo, forwardRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useChatContext } from '../../../../common/chatContext';
import { saveFiltersToStorage, useFiltersState } from '../../../../common/useFiltersState';
import AddChannelForm from '../../../../components/chat/AddChannelForm';
import AddProjectForm from '../../../../components/project/AddProjectForm';
import { Filters, filterProjects } from '../../../../components/project/ProjectFilters';
import { InternalPropjectTypes, filterByUser, filterProjectsType, searchByPhrase } from '../../../../components/project/ProjectsTable';
import { useSession as useSessionApp } from '../../../../dal';
import type { Project as IProject } from '../../../../entities';
import { CHANNEL_TYPES } from '../../../constants';
import { UserWithPermissions } from '../../../dal';
import { Result } from '../../../dal/useChat';
import { ChatChannel } from '../../../entities/chat';
import { DashboardPages, ProjectsDisplay, UrlParams, ISelectedPage } from '../types/types';
import AdvancedProjectView from './AdvancedProjectView';
import ProjectFilter from './ProjectFilter';
import ShortProjectView from './ShortProjectView';
import SidebarHeader from './SidebarHeader';
import { NewChannelData } from '../../../../api/useChatApi';

interface DashboardSidebarProps {
  session?: UserWithPermissions;
  chatData: Result;
  projects: IProject[];
  selectedPage: ISelectedPage<any>;
  isCreatingNewChannel: boolean;
  isError: boolean;
  isLoading: boolean;
  createNewChannel: (values: NewChannelData) => Promise<void>;
  onSelectedPageChange: (valule: ISelectedPage<any>) => void;
  onCreatingNewChannelChange: (value: boolean) => void;
  onCloseChannelList: () => void;
}

const DashboardSidebar = forwardRef<HTMLDivElement, DashboardSidebarProps>(
  (
    {
      session,
      chatData,
      projects,
      isCreatingNewChannel,
      selectedPage,
      isError,
      isLoading,
      createNewChannel,
      onCreatingNewChannelChange,
      onSelectedPageChange,
      onCloseChannelList,
    },
    ref,
  ) => {
    const { chatChannels, activeChannelId, handleChannel, users, loadUsers } = chatData;
    const projectDesiplatSavedMode: ProjectsDisplay = localStorage.getItem('project-display-mode') as ProjectsDisplay;
    const { id: projectUrlId, tab: projectUrlTab } = useParams<UrlParams>();

    const { session: appSession } = useSessionApp();
    const { value: chatEnabled } = useFeatureFlag('chat', false, { identifier: appSession?.email! });

    const filterKey = 'allprojects';
    const params = useFiltersState(filterKey);
    const searchPhraseFromParams = params && params[`${filterKey}-s`] ? (params[`${filterKey}-s`]!.toString() as string) : '';

    const [phrase, setPhrase] = useState<string>(searchPhraseFromParams);
    const [showMyProjects] = useState<boolean>(params && params[`${filterKey}-my`] ? (params[`${filterKey}-my`] as boolean) : false);
    const [showInternalProjects, setShowInternalProjects] = useState<InternalPropjectTypes>(
      params && params[`${filterKey}-int`] ? (params[`${filterKey}-int`] as InternalPropjectTypes) : 'all',
    );
    const filterFromParams = params && params[`${filterKey}-f`] ? (params[`${filterKey}-f`]!.toString() as Filters) : 'active';
    const [userFilter, setUserFilter] = useState<number | null>(
      params && params[`${filterKey}-u`] ? (Number(params[`${filterKey}-u`]) as number) : null,
    );
    const [filter, setFilter] = useState<Filters>(filterFromParams);
    const [projectAccordionState, setProjectAccordionState] = useState(
      localStorage.getItem('project-accordion') ? localStorage.getItem('project-accordion') === 'true' : true,
    );

    const [projectsDisplay, setProjectsDisplay] = useState<ProjectsDisplay>(projectDesiplatSavedMode || ProjectsDisplay.SHORT);
    const [newProjectDrawerOpen, setNewProjectDrawerOpen] = useState<boolean>(false);
    const [newChannelOpen, setNewChannelOpen] = useState<boolean>(false);
    const [newChannelModalMembers, setNewChannelModalMembers] = useState<string[]>([]);

    type ProjectsDisplay = 'short' | 'advanced';

    const navigate = useNavigate();
    const location = useLocation();
    const { chatUnread } = useChatContext();

    useEffect(() => {
      const filtersState = {
        [`${filterKey}-s`]: !!phrase ? phrase : undefined,
        [`${filterKey}-f`]: !!filter ? filter : undefined,
        [`${filterKey}-my`]: !!showMyProjects ? showMyProjects : undefined,
        [`${filterKey}-u`]: !!userFilter ? userFilter : undefined,
      };
      const filtersStringify = `?${queryString.stringify(filtersState)}`;
      saveFiltersToStorage(filterKey, filtersStringify);
    }, [phrase, filter, showMyProjects, filterKey, navigate, showInternalProjects, userFilter]);

    const handleResetProjectFilters = () => {
      setShowInternalProjects('all');
      setFilter('active');
      setPhrase('');
      setUserFilter(null);
    };

    const handleOpenPage = useCallback(() => {
      if (projectUrlId === 'create') {
        onCreatingNewChannelChange(true);
        onSelectedPageChange({ type: DashboardPages.CREATE, data: null });
        handleChannel('');
      } else if (projectUrlId) {
        onCreatingNewChannelChange(false);
        handleChannel(projectUrlId);
        if (location.pathname.includes('projects') && projects) {
          const project = projects.find(project => project.id === projectUrlId);
          if (project) {
            onSelectedPageChange({ type: DashboardPages.PROJECT, data: project });
          }
        } else if (location.pathname.includes('chat')) {
          const channel = chatChannels.find(channel => channel.id === projectUrlId);
          if (channel) {
            onSelectedPageChange({ type: DashboardPages.CHAT, data: channel });
          }
        }
      } else if (!projectUrlId && chatChannels?.length && appSession) {
        const resultProjects =
          projectsDisplay === ProjectsDisplay.SHORT
            ? filterProjectsType(filterProjects(filterByUser(projects, appSession.id), 'active', true), 'all')
            : filterProjectsType(searchByPhrase(filterProjects(filterByUser(projects, userFilter), filter, true), phrase), showInternalProjects);
        let result = {
          value: chatChannels?.findIndex(c => c.unread > 0 && (c.type === CHANNEL_TYPES.INDIVIDUAL || c.type === CHANNEL_TYPES.PRIVATE)),
          type: 'direct',
        }; // firstUnreadDirect
        if (result.value === -1) {
          result = { value: resultProjects?.findIndex(project => chatUnread[project.id]?.unreadMentions > 0), type: 'project' }; //firstUnreadMentionProject
        }
        if (result.value === -1) {
          result = { value: resultProjects?.findIndex(project => chatUnread[project.id]?.unread > 0), type: 'project' }; //firstUnreadProject
        }
        if (result.value === -1) {
          result = {
            value: chatChannels?.findIndex(c => c.manualUnread > 0 && (c.type === CHANNEL_TYPES.INDIVIDUAL || c.type === CHANNEL_TYPES.PRIVATE)),
            type: 'direct',
          }; //firstManualUnreadDirect
        }
        if (result.value === -1) {
          result = { value: resultProjects?.findIndex(project => chatUnread[project.id]?.manualUnread > 0), type: 'project' }; //firstManualUnreadProject
        }
        if (result.type === 'project' && resultProjects?.length) {
          handleChannel(resultProjects[result.value > -1 ? result.value : 0]?.id, false, true, 'projects');
          onSelectedPageChange({ type: DashboardPages.PROJECT, data: resultProjects[result.value > -1 ? result.value : 0] });
        } else {
          handleChannel(chatChannels[result.value > -1 ? result.value : 0]?.id, false, true);
          onSelectedPageChange({ type: DashboardPages.CHAT, data: chatChannels[result.value > -1 ? result.value : 0] });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeChannelId, chatChannels, projectUrlId, appSession, projectsDisplay, userFilter, showInternalProjects, filter, phrase, location]);

    useEffect(() => {
      handleOpenPage();
    }, [handleOpenPage]);

    // memoize filter function to avoid usless calles
    const ProjectList = useMemo(() => {
      if (appSession) {
        const selectedProject = (selectedPage.type === DashboardPages.PROJECT && selectedPage.data.id) || '';
        let resultProjects: IProject[] = [];
        if (projectsDisplay === ProjectsDisplay.SHORT) {
          resultProjects = filterProjectsType(
            filterProjects(filterByUser(projects, appSession.id, selectedProject), filter, true, selectedProject),
            'all',
            selectedProject,
          );
        } else if (phrase) {
          resultProjects = filterProjectsType(searchByPhrase(filterProjects(filterByUser(projects, null), filter, true), phrase), 'all');
        } else {
          resultProjects = filterProjectsType(
            searchByPhrase(filterProjects(filterByUser(projects, userFilter), filter, true), phrase),
            showInternalProjects,
          );
        }

        return resultProjects.sort((a, b) => {
          if (projectsDisplay === 'advanced' || !chatEnabled) {
            return 0;
          }

          if ((chatUnread[a.id]?.unreadMentions || chatUnread[a.id]?.manualUnread || chatUnread[a.id]?.unread) && !chatUnread[b.id]) {
            return -1;
          } else if (!chatUnread[a.id] && (chatUnread[b.id]?.unreadMentions || chatUnread[b.id]?.manualUnread || chatUnread[b.id]?.unread)) {
            return 1;
          }

          if (chatUnread[a.id]?.unreadMentions > chatUnread[b.id]?.unreadMentions) {
            return -1;
          } else if (chatUnread[a.id]?.unreadMentions < chatUnread[b.id]?.unreadMentions) {
            return 1;
          }

          if (chatUnread[a.id]?.manualUnread > chatUnread[b.id]?.manualUnread) {
            return -1;
          } else if (chatUnread[a.id]?.manualUnread < chatUnread[b.id]?.manualUnread) {
            return 1;
          }

          if (chatUnread[a.id]?.unread > chatUnread[b.id]?.unread) {
            return -1;
          } else if (chatUnread[a.id]?.unread < chatUnread[b.id]?.unread) {
            return 1;
          }

          return 0;
        });
      } else return [];
    }, [appSession, projectsDisplay, phrase, projects, selectedPage, userFilter, filter, showInternalProjects, chatEnabled, chatUnread]);

    const handleClickSelect = (type: DashboardPages, data: ChatChannel | IProject) => {
      const state: ISelectedPage<typeof data> = {
        type: type as any,
        data: data as any,
      };

      onSelectedPageChange(state);

      if (type === DashboardPages.PROJECT) {
        const url = `${data.id}${projectUrlTab ? `/${projectUrlTab}` : ''}`;
        navigate(`/projects/${url}`);
        return;
      }

      if (type === DashboardPages.CHAT) {
        navigate(`/chat/${data.id}`);
        handleChannel(data.id);
        return;
      }
    };

    const handleCreateProject = () => {
      setNewProjectDrawerOpen(true);
    };

    const handleCreateMessage = () => {
      navigate('/chat/create');
      onSelectedPageChange({ type: DashboardPages.CREATE, data: null });
    };

    const handleCreateChannel = () => {
      setNewChannelOpen(true);
    };

    return (
      <>
        <AddProjectForm modalOpen={newProjectDrawerOpen} setModalOpen={setNewProjectDrawerOpen} dontRedirect={false} />
        <AddChannelForm
          modalOpen={newChannelOpen}
          setModalOpen={setNewChannelOpen}
          users={users}
          session={session}
          onAddUserToChannel={setNewChannelModalMembers}
          newChannelMembers={newChannelModalMembers}
          createNewChannel={createNewChannel}
          chatChannels={chatChannels}
          loadUsers={loadUsers}
          handleChannel={handleChannel}
        />
        <div id="dashboard-sidebar" className="sidebar" ref={ref}>
          <div className="header-container sticky top-0 bg-white z-10">
            <SidebarHeader
              projectsDisplay={projectsDisplay}
              projectAccordionState={projectAccordionState}
              showProjectAccordionInShortMode={ProjectList.length > 9}
              onProjectDisplayChange={setProjectsDisplay}
              onProjectAccordionStateChange={() => setProjectAccordionState(!projectAccordionState)}
              onCreateProject={handleCreateProject}
              onCreateMessage={handleCreateMessage}
              onCreateChannel={handleCreateChannel}
            />

            {projectsDisplay === ProjectsDisplay.ADVANCED && (
              <ProjectFilter
                phrase={phrase}
                filter={filter}
                showInternalProjects={showInternalProjects}
                userFilter={userFilter}
                onPhraseChange={setPhrase}
                onFilterChange={setFilter}
                onShowInternalProjectsChange={setShowInternalProjects}
                onResetFilter={handleResetProjectFilters}
                onUserFilterChange={setUserFilter}
              />
            )}
          </div>

          {projectsDisplay === ProjectsDisplay.ADVANCED ? (
            <AdvancedProjectView projects={ProjectList} chatUnread={chatUnread} selectedPage={selectedPage} onSelectPage={handleClickSelect} />
          ) : (
            <>
              <ShortProjectView
                session={session}
                projects={ProjectList}
                chatChannels={chatChannels}
                chatUnread={chatUnread}
                selectedPage={selectedPage}
                projectAccordionState={projectAccordionState}
                isError={isError}
                isLoading={isLoading}
                isCreatingNewChannel={isCreatingNewChannel}
                onSelectPage={handleClickSelect}
                onCloseChannelList={onCloseChannelList}
              />
              <Divider className="bg-[#ececec] bg-opacity-0 !m-0 !mt-3" />
            </>
          )}
        </div>
      </>
    );
  },
);

export default DashboardSidebar;
