import { InputNumber, InputNumberProps } from 'antd';

const MoneyInput = (props: InputNumberProps) => (
  <InputNumber
    controls={false}
    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => (value || '').replace(/\$\s?|(,*)/g, '')}
    style={{ width: '100%', borderRadius: '6px' }}
    bordered={!props.readOnly}
    {...props}
  />
);

export default MoneyInput;
