import { useCallback } from 'react';

export const useSidebarWidth = (
  DashboardSidebarRef: React.RefObject<HTMLDivElement>,
  DashboardMainContainerRef: React.RefObject<HTMLDivElement>,
  CustomDividerRef: React.RefObject<HTMLDivElement>,
) => {
  return useCallback(() => {
    const dashboardSidebar = DashboardSidebarRef.current;
    const dashboardMainContainer = DashboardMainContainerRef.current;

    const onMouseMove = (e: MouseEvent) => {
      if (!dashboardSidebar || !dashboardMainContainer) return;

      const newWidth = e.pageX - 55;
      if (newWidth > 288 && newWidth < 600) {
        document.body.style.userSelect = 'none';
        dashboardSidebar.style.width = `${newWidth}px`;
        dashboardSidebar.style.minWidth = `${newWidth}px`;
      }
    };

    const handleMouseUp = () => {
      document.body.style.userSelect = '';
      localStorage.setItem('left-bar-width', dashboardSidebar?.style.width || '250px');
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    const onMouseDown = () => {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const storedWidth = Number(localStorage.getItem('left-bar-width')) || 250;
    if (storedWidth >= 220 && storedWidth <= 600 && dashboardSidebar) {
      dashboardSidebar.style.width = `${storedWidth}px`;
      dashboardSidebar.style.minWidth = `${storedWidth}px`;
    }

    const divider = CustomDividerRef.current;
    divider?.addEventListener('mousedown', onMouseDown);

    return () => {
      divider?.removeEventListener('mousedown', onMouseDown);
    };
  }, [DashboardSidebarRef, DashboardMainContainerRef, CustomDividerRef]);
};
