import { useCallback, useState } from 'react';
import { PageHeader, Table, Typography } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import TimesheetTabsControls from '../../components/Timesheet/TimesheetTabsControls';
import { useCommissionReport } from '../../dal/useCommissionReport';
import { CommissionReportItem } from '../../entities';
import { formatCurrency, nullableDataSorter } from '../../common/utils';

const CommissionReport = () => {
  const [activeDay, setActiveDay] = useState(moment().startOf('month'));

  const { commissionReport } = useCommissionReport(activeDay.format('YYYY-MM-DD'));

  const getPreviousMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().subtract(1, 'month'));
  }, []);

  const getNextMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().add(1, 'month'));
  }, []);

  const summary = useCallback(pageData => {
    let summaryBillAmount = 0;
    let summaryCommissionAmount = 0;

    pageData.forEach(({ key, billAmount, commissionAmount }) => {
      summaryBillAmount += billAmount;
      summaryCommissionAmount += commissionAmount;
    });

    return (
      <>
        <Table.Summary.Row className="role-group-row">
          <Table.Summary.Cell index={0} colSpan={4}>
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            {formatCurrency(summaryBillAmount)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="right">
            {formatCurrency(summaryCommissionAmount)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }, []);

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={activeDay.format('MMMM, YYYY')}
        className="payroll-header"
        subTitle={<TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />}
      />

      <div className="ant-space-vertical">
        <Table bordered size="small" dataSource={commissionReport} rowKey="id" pagination={false} summary={summary}>
          <Table.Column
            dataIndex="clientName"
            title="Client"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/clients/${row.clientId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.clientName, b.clientName)}
          />
          <Table.Column
            dataIndex="projectName"
            title="Project"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.projectName, b.projectName)}
          />
          <Table.Column
            dataIndex="projectCode"
            title="Code"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) =>
              nullableDataSorter(parseInt(a.projectCode.substring(1), 10), parseInt(b.projectCode.substring(1), 10))
            }
          />
          <Table.Column
            dataIndex="invoiceNumber"
            title="Invoice Number"
            render={value =>
              value.map(invoiceNumber => (
                <>
                  <Typography.Text>{invoiceNumber}</Typography.Text>
                  <br />
                </>
              ))
            }
          />
          <Table.Column
            dataIndex="billAmount"
            title="Bill Amount"
            align="right"
            render={formatCurrency}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.billAmount, b.billAmount)}
          />
          <Table.Column
            dataIndex="personName"
            title="Person"
            render={(value, { commissionPercent }: CommissionReportItem) => (
              <Typography.Text>
                {value} ({commissionPercent}%)
              </Typography.Text>
            )}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.personName, b.personName)}
          />
          <Table.Column
            dataIndex="commissionAmount"
            title="Commission"
            align="right"
            render={formatCurrency}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.commissionAmount, b.commissionAmount)}
          />
        </Table>
      </div>
    </div>
  );
};

export default CommissionReport;
