import { Button, Col, Row, Spin, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Layout from '../../layouts/authorised/Authorised';
import { useProjects } from '../../dal';
import { useState } from 'react';
import AddProjectForm from '../../components/project/AddProjectForm';
import ProjectsTable from '../../components/project/ProjectsTable';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ProjectPermissions } from '../../common/usePermissions/permissions';

const Projects = () => {
  const { hasPermission } = usePermissions();
  const { projects = [] } = useProjects();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  if (!projects)
    return (
      <Layout>
        <Spin />
      </Layout>
    );

  return (
    <Layout overrideBackgroundContainer>
      <Row gutter={16}>
        <Col span={24}>
          <div className="custom-container">
            <Tabs
              activeKey="1"
              tabBarExtraContent={
                hasPermission(ProjectPermissions.PROJECT_CREATE) && (
                  <Button icon={<PlusOutlined />} type="primary" onClick={() => setDrawerOpen(true)}>
                    Add project
                  </Button>
                )
              }
              items={[
                {
                  key: '1',
                  label: 'Projects',
                  children: <ProjectsTable projects={projects} filterKey="allprojects" />,
                },
              ]}
            />
          </div>
        </Col>
      </Row>

      {/* <Row gutter={16}>
        <Col span={24}>
          <div className="custom-container">
            <SingleTab title="Projects board">
              <ProjectsBoard />
            </SingleTab>
          </div>
        </Col>
      </Row> */}
      {drawerOpen && <AddProjectForm modalOpen={drawerOpen} setModalOpen={setDrawerOpen} />}
    </Layout>
  );
};

export default Projects;
