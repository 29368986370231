import useSWR from 'swr';
import { FetchResult, CommissionReportItem } from '../entities';

type Result = FetchResult & { commissionReport: CommissionReportItem[] };

export function useCommissionReport(forMonth: string | null): Result {
  const { data, error, mutate } = useSWR(`/commission-report?forMonth=${forMonth || ''}`);

  const groupedData: CommissionReportItem[] =
    data &&
    Object.values(
      data?.reduce((acc, item) => {
        const key = `${item.projectId}-${item.forMonth}-${item.personName}`;

        if (!acc[key]) {
          acc[key] = {
            ...item,
            billAmount: 0,
            commissionAmount: 0,
            invoiceNumber: [],
          };
        }

        acc[key].billAmount += item.billAmount;
        acc[key].commissionAmount += item.commissionAmount;
        acc[key].invoiceNumber.push(item.invoiceNumber);

        return acc;
      }, {}),
    );

  return {
    commissionReport: groupedData,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
