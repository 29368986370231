import { Empty } from 'antd';

import MessageList from './MessageList';
import { User } from '../../entities';
import { ChatChannel, ChatMessage, ChatMessageReplyProps, StateCB } from '../../entities/chat';

import './Messages.css';

interface MessagesProps {
  user: User;
  chatChannel: ChatChannel;
  messages: ChatMessage[];
  setReplyTo: (message?: ChatMessageReplyProps | StateCB) => void;
  loadMessages: (part: number) => void;
  isAllowToCommunicate: string | null;
  chatWhereUserMember: ChatChannel[];
  replyTo?: ChatMessageReplyProps;
  style?: any;
  users?: User[];
}

const Messages = ({ user, chatChannel, messages, setReplyTo, replyTo, style, isAllowToCommunicate, loadMessages, users }: MessagesProps) => {
  return (
    <div className="messages-window" id="scrolableDiv" style={style}>
      {messages?.length ? (
        <MessageList
          key={chatChannel.id}
          users={users}
          loadMessages={loadMessages}
          isAllowToCommunicate={isAllowToCommunicate}
          messages={messages}
          replyTo={replyTo}
          setReplyTo={setReplyTo}
          user={user}
          channelId={chatChannel.id}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

export default Messages;
