import React from 'react';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { ChatMessage } from '../../entities/chat';
import { useChatApi } from '../../../api/useChatApi';

interface Props {
  message: ChatMessage;
  closeModal: (state: string | null) => void;
}

const Reaction: React.FC<Props> = ({ message, closeModal }) => {
  const { reaction } = useChatApi();

  const emojiRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    const elem = emojiRef.current;
    if (elem) {
      const bodyRect = document.body.getBoundingClientRect(),
        elemRect = elem.getBoundingClientRect();

      const messageHeight = bodyRect.height - 231;

      if (messageHeight < elemRect.bottom) {
        emojiRef.current.style.top = messageHeight - elemRect.bottom + 'px';
      }
    }
  }, [emojiRef]);

  const updateReaction = async (emojiUrl: string, emoji: string) => {
    await reaction(message.id, emoji);
    closeModal(null);
  };

  return (
    <div className={`relative z-50`}>
      <div className={'absolute right-[-.5rem] sm:right-0 xl:left-[25rem]'} ref={emojiRef}>
        <EmojiPicker
          onEmojiClick={data => {
            updateReaction(data.getImageUrl(EmojiStyle.NATIVE), data.emoji);
          }}
        />
      </div>
    </div>
  );
};

export default Reaction;
