import { Avatar, Select, SelectProps } from 'antd';
import { useProject } from '../../dal';
import { useUsersBrief } from '../../dal/useUsersBrief';

interface UsersMultiSelectorProps extends SelectProps {
  projectId?: string | null;
}

const UsersMultiSelector = ({ projectId, ...props }: UsersMultiSelectorProps) => {
  const { project } = useProject(projectId ? projectId : '');

  const { users = [] } = useUsersBrief({ includeClientUsers: true });

  const usersWithSpecificClientAccs = users.filter(user => user.client === null || user?.client?.id === project?.client?.id);

  return (
    <Select
      {...props}
      mode="multiple"
      showSearch
      placeholder="Select users"
      filterOption={(input, option) => {
        return `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {usersWithSpecificClientAccs.map(u => (
        <Select.Option key={u.id} value={u.id}>
          <Avatar src={u.picture} style={{ backgroundColor: u?.avatarColor, marginRight: '6px' }} size="small">
            {u.picture ? null : u.initials}
          </Avatar>
          {u.name || u.email}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UsersMultiSelector;
