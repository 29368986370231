import { Input, Modal, Form, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios, { AxiosError } from 'axios';
import { useUsers } from '../../dal';
import { getInitials } from '../../utils';

interface Props {
  modalOpen: boolean;
  closeModal: () => void;
  clientId?: string;
  initialValues?: { id: number; name: string; email: string };
}

const AVATAR_COLORS = ['#bfbfbf', '#1890ff', '#fa8c16', '#52c41a', '#722ed1', '#f5222d', '#2f54eb', '#eb2f96', '#fadb14', '#096dd9'];

const AddEditAccountForm = ({ modalOpen, closeModal, clientId, initialValues }: Props) => {
  const { users, mutate } = useUsers({ clientId });
  const [form] = useForm();

  const onFinish = async (values: { name: string; email: string; password: string }) => {
    try {
      if (initialValues) {
        await axios.patch(`/users/${initialValues.id}`, { ...values, client: clientId });
        message.success('Account edited');
      } else {
        const allUserInitials = users.map(user => user.initials);
        const targetInitials = getInitials(values.name);
        const occurrences = allUserInitials.reduce((count, initials) => (initials === targetInitials ? count + 1 : count), 0);
        await axios.post(`/users`, { ...values, client: clientId, avatarColor: AVATAR_COLORS[occurrences], initials: targetInitials });
        message.success('Account added');
      }
      await mutate();
      closeModal();
    } catch (e) {
      if ((e as AxiosError).response?.status === 409) {
        message.error('Email already exists');
      }
    }
  };

  return (
    <Modal title="Add account" open={modalOpen} onOk={() => form.submit()} onCancel={() => closeModal()}>
      <Form form={form} onFinish={onFinish} validateTrigger="onSubmit" initialValues={initialValues || undefined}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Email is required' }]}>
          <Input />
        </Form.Item>
        {initialValues && (
          <Form.Item label="Password" name="password">
            <Input type="password" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AddEditAccountForm;
