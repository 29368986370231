import React from 'react';
import { Table, Tooltip } from 'antd';
import { PortfolioSelect } from './PortfolioSelect';
import LinkInput from '../linkInput/LinkInput';
import { ChangedLink, HOSTING_OPTIONS, INDUSTRY_OPTIONS, LIMIT, Portfolio } from './utils';

import './PortfolioTable.css';
import { nullableDataSorter } from '../../common/utils';

const { Column } = Table;

interface PortfolioTableProps {
  readonly allPortfolio: Portfolio[];
  readonly totalItems: number;
  readonly handleSave: (index: number, changedLink: ChangedLink) => void;
  readonly handleSelectChange: (value: string | boolean | null | (string | boolean | null)[], index: number, field: string) => void;
  readonly handlePaginationChange: (page: number, pageSize: number) => void;
}

export const PortfolioTable: React.FC<PortfolioTableProps> = props => {
  const { allPortfolio, totalItems, handleSave, handleSelectChange, handlePaginationChange } = props;

  return (
    <Table
      dataSource={allPortfolio}
      pagination={{
        total: totalItems,
        defaultPageSize: LIMIT,
        onChange: handlePaginationChange,
      }}
      scroll={totalItems ? { x: 'max-content', y: 'calc(100vh - 250px)' } : undefined}
      className="portfolio-table"
      rowKey="id"
    >
      <Column
        dataIndex="code"
        className="portfolio-item portfolio-code"
        title="ID"
        fixed="left"
        sorter={(a: Portfolio, b: Portfolio) => nullableDataSorter(a.code, b.code)}
      />

      <Column
        dataIndex={['client', 'name']}
        title="Client • Project"
        fixed="left"
        render={(value, row: Portfolio) => (
          <Tooltip
            placement="right"
            title={
              <>
                <div>{row.client}</div>
                <div>{row.name}</div>
              </>
            }
          >
            <div className="portfolio-item portfolio-project">
              <div className="portfolio-value" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {row.client}
              </div>
              <span className="portfolio-dot">•</span>
              <div className="portfolio-value" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {row.name}
              </div>
            </div>
          </Tooltip>
        )}
        sorter={(a: Portfolio, b: Portfolio) => nullableDataSorter(a.client, b.client)}
      />

      <Column
        dataIndex="phase"
        title="Phase"
        className="portfolio-item portfolio-phase"
        sorter={(a: Portfolio, b: Portfolio) => nullableDataSorter(a.phase, b.phase)}
      />

      <Column
        dataIndex="figmaProjectLink"
        title="Figma project link"
        width="150px"
        render={(value, row: Portfolio, index) => (
          <LinkInput
            value={value}
            onChange={newValue => handleSave(index, { linkId: row.figmaProjectLinkId, url: newValue, linkType: 'FIGMA_PROJECT' })}
          />
        )}
      />

      <Column
        dataIndex="figmaClientLink"
        title="Figma client link"
        width="150px"
        render={(value, row: Portfolio, index) => (
          <LinkInput
            value={value}
            onChange={newValue => handleSave(index, { linkId: row.figmaClientLinkId, url: newValue, linkType: 'FIGMA_CLIENT' })}
          />
        )}
      />

      <Column
        dataIndex="htmlLink"
        title="HTML link"
        width="150px"
        render={(value, row: Portfolio, index) => (
          <LinkInput value={value} onChange={newValue => handleSave(index, { linkId: row.htmlLinkId, url: newValue, linkType: 'HTML' })} />
        )}
      />

      <Column
        dataIndex="htmlDdcLink"
        title="HTML DDC link"
        width="150px"
        render={(value, row: Portfolio, index) => (
          <LinkInput value={value} onChange={newValue => handleSave(index, { linkId: row.htmlDdcLinkId, url: newValue, linkType: 'HTML_DDC' })} />
        )}
      />

      <Column
        dataIndex="devSiteLink"
        title="Dev site"
        width="150px"
        render={(value, row: Portfolio, index) => (
          <LinkInput value={value} onChange={newValue => handleSave(index, { linkId: row.devSiteLinkId, url: newValue, linkType: 'DEV_SITE' })} />
        )}
      />

      <Column
        dataIndex="liveSiteLink"
        title="Live site"
        width="150px"
        render={(value, row: Portfolio, index) => (
          <LinkInput value={value} onChange={newValue => handleSave(index, { linkId: row.liveSiteLinkId, url: newValue, linkType: 'LIVE_SITE' })} />
        )}
      />

      <Column
        dataIndex="waybackLink"
        title="Wayback link"
        width="150px"
        render={(value, row: Portfolio, index) => (
          <LinkInput value={value} onChange={newValue => handleSave(index, { linkId: row.waybackLinkId, url: newValue, linkType: 'WAYBACK' })} />
        )}
      />

      <Column
        dataIndex="hosting"
        title="Hosting"
        width="90px"
        render={(value, row: Portfolio, index) => (
          <PortfolioSelect value={value} index={index} onChange={value => handleSelectChange(value, index, 'hosting')} options={HOSTING_OPTIONS} />
        )}
      />

      <Column
        dataIndex="industry"
        title="Industry"
        width="150px"
        render={(value, row: Portfolio, index) => (
          <PortfolioSelect
            value={value}
            index={index}
            onChange={value => handleSelectChange(value, index, 'industry')}
            multiple
            allowClear
            options={INDUSTRY_OPTIONS}
          />
        )}
      />
    </Table>
  );
};
