import { useCallback, useEffect, useMemo, useState } from 'react';
import { Space, Button, Spin, Statistic, Table, PageHeader, Typography, Modal, Select } from 'antd';
import moment from 'moment';
import Summary from './components/SummaryTable';
import { formatCurrency, nullableDataSorter } from '../../common/utils';
import { ProjectsReportItem } from '../../entities';
import { useTimesheetReportAllProjects } from '../../dal/useTimesheetReportAllProjects';
import TimesheetTabsControls from '../../components/Timesheet/TimesheetTabsControls';
import { useTimesheetReportByProjectCode } from '../../dal/useTimesheetReportByProjectCode';
import { InternalPropjectTypes } from '../../components/project/ProjectsTable';
import { useInvoices } from '../../dal/useProjectInvoices';
import { Invoice } from '../../entities/Invoice';
import InvoiceModal from '../../components/project/ProjectFinance.invoiceModal';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ReportPermissions } from '../../common/usePermissions/permissions';
import { Link } from 'react-router-dom';

const BudgetReport = () => {
  const { hasPermission } = usePermissions();
  const [activeDay, setActiveDay] = useState(moment().startOf('month'));
  const { allProjectsReport } = useTimesheetReportAllProjects(activeDay.format('YYYY-MM-DD'));
  const { invoices, mutate: mutateInvoice, deleteInvoice, saveInvoice } = useInvoices(activeDay.format('YYYY-MM-DD'));
  const [selectedProjectCode, setselectedProjectCode] = useState<string | null>(null);
  const { projectReport } = useTimesheetReportByProjectCode(selectedProjectCode, activeDay.format('YYYY-MM-DD'));
  const [filteredProjectType, setFilteredProjectType] = useState<InternalPropjectTypes>('all');
  const [filteredProjectsReport, setFilteredProjectsReport] = useState<ProjectsReportItem[]>(allProjectsReport || []);
  const [openInvoice, setOpenInvoice] = useState<Invoice | null>(null);

  const onInvoiceDelete = useCallback(
    async (invoiceId: string) => {
      await deleteInvoice(invoiceId);
      await mutateInvoice();
      setOpenInvoice(null);
    },
    [deleteInvoice, mutateInvoice],
  );

  const onInvoiceFormFinish = useCallback(
    async (invoice: Invoice) => {
      await saveInvoice({ ...invoice, projectId: openInvoice?.projectId });
      await mutateInvoice();
      setOpenInvoice(null);
    },
    [mutateInvoice, openInvoice?.projectId, saveInvoice],
  );

  const filterMap = useMemo(
    () => ({
      all: allProjectsReport,
      client: allProjectsReport?.filter(report => !report.isInternal),
      internal: allProjectsReport?.filter(report => report.isInternal),
    }),
    [allProjectsReport],
  );

  useEffect(() => {
    setFilteredProjectsReport(filterMap[filteredProjectType]);
  }, [allProjectsReport, filterMap, filteredProjectType]);

  const getPreviousMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().subtract(1, 'month'));
  }, []);

  const getNextMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().add(1, 'month'));
  }, []);

  if (!allProjectsReport || !filteredProjectsReport || !invoices) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  const totals = filteredProjectsReport?.reduce(
    (prev, curr) => ({
      totalAmount: prev.totalAmount + curr.amount,
      totalHours: prev.totalHours + curr.hours,
      totalProjectsLength: (prev.totalProjectsLength += 1),
      clientTotalAmount: !curr.isInternal ? prev.clientTotalAmount + curr.amount : prev.clientTotalAmount,
      clientTotalHours: !curr.isInternal ? prev.clientTotalHours + curr.hours : prev.clientTotalHours,
      clientTotalProjectsLength: !curr.isInternal ? (prev.clientTotalProjectsLength += 1) : prev.clientTotalProjectsLength,
      internalTotalAmount: curr.isInternal ? prev.internalTotalAmount + curr.amount : prev.internalTotalAmount,
      internalTotalHours: curr.isInternal ? prev.internalTotalHours + curr.hours : prev.internalTotalHours,
      internalTotalProjectsLength: curr.isInternal ? (prev.internalTotalProjectsLength += 1) : prev.internalTotalProjectsLength,
    }),
    {
      totalAmount: 0,
      totalHours: 0,
      totalProjectsLength: 0,
      clientTotalAmount: 0,
      clientTotalHours: 0,
      clientTotalProjectsLength: 0,
      internalTotalAmount: 0,
      internalTotalHours: 0,
      internalTotalProjectsLength: 0,
    },
  );

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={activeDay.format('MMMM, YYYY')}
        className="payroll-header"
        subTitle={<TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />}
        extra={
          <Select
            id="switch-internal-projects"
            style={{ width: '200px', marginBottom: '16px' }}
            value={filteredProjectType}
            onChange={setFilteredProjectType}
            getPopupContainer={trigger => trigger.parentElement}
          >
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="internal">Internal</Select.Option>
            <Select.Option value="client">Client</Select.Option>
          </Select>
        }
      />

      <div className="ant-space-vertical">
        <Table
          bordered
          size="small"
          dataSource={filteredProjectsReport}
          rowKey="id"
          pagination={false}
          title={() => (
            <Space className="flex-wrap">
              {filteredProjectType === 'all' && (
                <Statistic
                  title={`Total Amount (${totals.totalProjectsLength})`}
                  value={totals.totalAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />
              )}

              {filteredProjectType === 'all' && (
                <Statistic title={`Total Hours (${totals.totalProjectsLength})`} value={totals.totalHours} precision={2} style={{ width: '150px' }} />
              )}

              {['all', 'client'].includes(filteredProjectType) && (
                <Statistic
                  title={`Client Total Amount (${totals.clientTotalProjectsLength})`}
                  value={totals.clientTotalAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />
              )}

              {['all', 'client'].includes(filteredProjectType) && (
                <Statistic
                  title={`Client Total Hours (${totals.clientTotalProjectsLength})`}
                  value={totals.clientTotalHours}
                  precision={2}
                  style={{ width: '170px' }}
                />
              )}

              {['all', 'internal'].includes(filteredProjectType) && (
                <Statistic
                  title={`Internal Total Amount (${totals.internalTotalProjectsLength})`}
                  value={totals.internalTotalAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />
              )}

              {['all', 'internal'].includes(filteredProjectType) && (
                <Statistic
                  title={`Internal Total Hours (${totals.internalTotalProjectsLength})`}
                  value={totals.internalTotalHours}
                  precision={2}
                  style={{ width: '170px' }}
                />
              )}
            </Space>
          )}
        >
          <Table.Column
            dataIndex="clientName"
            title="Client"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/clients/${row.clientId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.clientName, b.clientName)}
          />
          <Table.Column
            dataIndex="projectName"
            title="Name"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.projectName, b.projectName)}
          />
          <Table.Column
            dataIndex="projectCode"
            title="Code"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) =>
              nullableDataSorter(parseInt(a.projectCode.substring(1), 10), parseInt(b.projectCode.substring(1), 10))
            }
          />
          <Table.Column
            title="Timesheet"
            dataIndex="projectCode"
            render={projectCode =>
              projectCode ? (
                <Button
                  type="link"
                  onClick={() => setselectedProjectCode(projectCode)}
                  disabled={!hasPermission(ReportPermissions.REPORTS_TIMESHEET_READ)}
                >
                  Show
                </Button>
              ) : (
                <Typography.Text type="secondary">Manual entry</Typography.Text>
              )
            }
          />
          <Table.Column
            dataIndex="hours"
            title="Hours"
            align="right"
            render={value => value.toFixed(2)}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.hours, b.hours)}
          />
          <Table.Column
            dataIndex="amount"
            title="Amount"
            align="right"
            render={formatCurrency}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.amount, b.amount)}
          />
          <Table.Column
            dataIndex="projectCode"
            title="Invoices"
            width={450}
            align="center"
            render={projectCode => (
              <InvoicesTable
                invoices={invoices.filter(i => i.project?.autoCode === projectCode)}
                onSelect={(invoice: Invoice) => setOpenInvoice(invoice)}
              />
            )}
          />
          <Table.Column
            dataIndex="projectCode"
            title="Markup %"
            width={100}
            align="center"
            render={(projectCode, row: ProjectsReportItem) => {
              const invoicedValue = invoices
                .filter(i => i.project?.autoCode === projectCode)
                .reduce((acc: number, cur: Invoice) => acc + cur.value, 0);

              if (invoicedValue === 0) {
                return null;
              }

              const markupPercentage = (1 - row.amount / invoicedValue) * 100;

              return invoicedValue > 0 ? (
                <Typography.Text type={markupPercentage <= 0 ? 'danger' : markupPercentage <= 50 ? 'warning' : 'success'}>
                  {markupPercentage.toFixed(2)}
                </Typography.Text>
              ) : null;
            }}
          />
        </Table>
      </div>

      {openInvoice && (
        <InvoiceModal
          projectId={openInvoice.projectId!}
          billableRate={0}
          invoice={openInvoice}
          onCancel={() => setOpenInvoice(null)}
          onDelete={onInvoiceDelete}
          onSubmit={onInvoiceFormFinish}
        />
      )}

      <Modal
        title={`Timesheet report for ${
          projectReport?.length ? `${projectReport[0].clientName} - ${projectReport[0].projectName} - ${projectReport[0].projectCode}` : ''
        }`}
        open={Boolean(projectReport?.length)}
        width={1200}
        destroyOnClose
        footer={null}
        onCancel={() => setselectedProjectCode(null)}
      >
        <Table bordered size="small" dataSource={projectReport} summary={Summary} pagination={false}>
          <Table.Column dataIndex="contactName" title="Employee" />
          <Table.Column dataIndex="category" title="Category" />
          <Table.Column
            dataIndex="rate"
            title="Rate"
            align="right"
            render={(value, row: ProjectsReportItem) => (row.fixedCost !== 0 ? '' : formatCurrency(value))}
          />
          <Table.Column
            dataIndex="hours"
            title="Hours"
            align="right"
            render={(value, row: ProjectsReportItem) => (row.fixedCost !== 0 ? '' : value.toFixed(2))}
          />
          <Table.Column
            dataIndex="amount"
            title="Amount"
            align="right"
            render={(amount, row: ProjectsReportItem) => formatCurrency(row.fixedCost !== 0 ? row.fixedCost : amount)}
          />
        </Table>
      </Modal>
    </div>
  );
};

const InvoicesTable = ({ invoices, onSelect }: { invoices: Invoice[]; onSelect: (Invoice) => void }) => {
  return invoices.length > 0 ? (
    <Table bordered size="small" dataSource={invoices} pagination={false} className="nested-table">
      <Table.Column
        dataIndex="autoCode"
        title="Invoice #"
        render={(autoCode, invoice: Invoice) => (
          <Button type="link" onClick={() => onSelect(invoice)}>
            {autoCode}
          </Button>
        )}
      ></Table.Column>
      <Table.Column
        dataIndex="isActive"
        title="Status"
        width={100}
        align="right"
        render={(_, invoice: Invoice) => (!invoice.isActive ? 'Draft' : invoice.paid ? 'Paid' : 'Not paid')}
      />
      <Table.Column dataIndex="value" title="Value" render={formatCurrency} width={100} align="right" />
      <Table.Column dataIndex="actualCost" title="Actual cost" render={formatCurrency} width={100} align="right" />
      <Table.Column dataIndex="markupPercentage" title="Markup %" width={100} align="right" />
    </Table>
  ) : (
    <Typography.Text type="warning">No invoices for this project</Typography.Text>
  );
};

export default BudgetReport;
