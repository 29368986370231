import useSWR from 'swr';
import { ExternalLink, FetchResult } from '../entities';

type Result = FetchResult & { links: ExternalLink[] };

export function useProjectLinks(id: string): Result {
  const { data, error, mutate } = useSWR(`/projects/${id}/links`);

  return {
    links: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
