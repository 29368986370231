import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Spin } from 'antd';
import { useSession, useTimesheetYesterday } from '../../dal';
import Search from '../../components/search';
import { CustomHeader } from './CustomHeader';
import logo from '../../images/logo.svg';
import { Modal } from 'antd';
import { useMenu } from '../../common/useMenu';

const { Content, Sider } = Layout;

const Authorised = ({
  children,
  isFullPage = false,
  overrideBackgroundContainer = false,
  fullHeight = false
}: {
  children: ReactNode;
  isFullPage?: boolean;
  overrideBackgroundContainer?: boolean;
  fullHeight?: boolean;
}) => {
  const location = useLocation();
  const { session, mutate } = useSession();
  // const { isDesktop, isMobile } = useAdaptive();
  const menuItems_desktop = useMenu();
  // const menuItems_mobile = useMenuMobile();

  const pathKey = (function () {
    let path: string[] = location.pathname.split('/');
    if (path[2] && path[2] === 'board') {
      return path[1] + '/' + path[2];
    }
    if (path[1] && path[1] === 'reports') {
      return path[1] + '/' + path[2];
    }
    return path[1];
  })();

  const [yesterdaySubmition, setYesterdaySubmition] = useTimesheetYesterday();
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  useEffect(() => {
    if (yesterdaySubmition) {
      const visible = !(yesterdaySubmition === 'true' || yesterdaySubmition === 'disabled');
      return setIsVisibleModal(visible);
    }
  }, [yesterdaySubmition]);

  const handleYesterdaySubmition = () => {
    setYesterdaySubmition('disabled');
    navigate('/timesheet');
  };

  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      await axios.post('/logout');
    } finally {
      await mutate();
      navigate('/');
    }
  }, [navigate, mutate]);

  if (!session) {
    return <Spin/>
  }

  return (
    <Layout>
      <Modal
        open={isVisibleModal}
        title="Submit timesheet for yesterday"
        okText="Go to timesheet"
        cancelText="Skip for now"
        onOk={() => handleYesterdaySubmition()}
        onCancel={() => setYesterdaySubmition('disabled')}
      >
        <span>You did not submit any hours for yesterday, please click here to add your hours for yesterday now!</span>
      </Modal>
      <Search />
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          backgroundColor: 'black',
          zIndex: 1,
          width: '56px !important',
          flex: '0 0 56px',
        }}
        width={300}
        collapsed={true}
      >
        <div className="logo">
          <img src={logo} alt="Design in DC logo" height="20px" />
        </div>

        {/* MENU DESKTOP */}
        <Menu items={menuItems_desktop} theme="dark" mode="inline" defaultSelectedKeys={[pathKey]} style={{ background: 'black' }} />
        {/* MENU MOBILE */}
        {/* {isMobile() && <Menu items={menuItems_mobile} theme="dark" mode="inline" defaultSelectedKeys={[pathKey]} style={{ background: 'black' }} />} */}
      </Sider>
      <Layout style={{ height: '100vh', marginLeft: 56 }}>
        {/* HEADER (display only for desktop) */}
        <CustomHeader profileImg={session.picture} name={session.name} logout={logout} userId={session!.id} />
        <Content style={{ padding: isFullPage ? 0 : '24px 16px 16px', overflowY: 'auto' }}>
          {overrideBackgroundContainer ? (
            children
          ) : (
            <div className="site-layout-background custom-container" style={{ padding: isFullPage ? 0 : 24, height: fullHeight ? '100%' : 'auto' }}>
              {children}
            </div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default React.memo(Authorised);
