import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Comment, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Xarrow from 'react-xarrows';
import { FileOutlined } from '@ant-design/icons';

import Reaction from './Reaction';
import { User } from '../../entities';
import { ChatMessage, ChatMessageReplyProps, StateCB } from '../../entities/chat';

import ImageModal from './imageModal';
import Reactions from './Reactions';
import {
  AMOUNT_OF_COMMENTS_TO_LOAD,
  AMOUNT_OF_MESSAGES_TO_LOAD,
  INITIAL_AMOUNT_OF_LOADED_COMMENTS,
  INITIAL_AMOUNT_OF_LOADED_MESSAGES,
  S3_URL_MASK,
  MESSAGE_ACTIONS_DATA,
} from '../../constants';

import DonwloadIcon from '../../static/images/download_icon.svg';
import { formatBytes } from '../../common/utils';
import { useChatApi } from '../../../api/useChatApi';

import './Messages.css';

interface MessageListProps {
  messages: ChatMessage[];
  user: User;
  setReplyTo: (message?: ChatMessageReplyProps | StateCB) => void;
  isAllowToCommunicate: string | null;
  loadMessages: (part: number) => void;
  replyTo?: ChatMessageReplyProps;
  isItReply?: boolean;
  users?: User[];
  channelId: string;
}

const MessageList = ({ messages, user, setReplyTo, replyTo, isAllowToCommunicate, loadMessages, isItReply, users, channelId }: MessageListProps) => {
  const [isOver, setIsOver] = useState<string | null>();
  const [loadedTimes, setLoadedTimes] = useState(INITIAL_AMOUNT_OF_LOADED_MESSAGES);
  const [threadLoaded, setThreadLoaded] = useState(INITIAL_AMOUNT_OF_LOADED_COMMENTS);
  const [lastDateUserVisitedChannelActual, setLastDateUserVisitedChannelActual] = useState<Date>(new Date());
  const [isLoaded, setIsLoaded] = useState(false);
  const [showImage, setShowImage] = useState<string | null>();

  const { readAllMessages, messageAction, getLastDateUserVisitedChannel } = useChatApi();

  const openEmojiModal = (id: string) => {
    setTimeout(() => {
      setIsOver(id);
    }, 0);
  };

  React.useEffect(() => {
    if (isItReply && messages.length) {
      if (messages[0].shouldDisplay) {
        setThreadLoaded(pV => pV + 1);
      }
    }
  }, [messages, isItReply]);

  const closeEmojiModal = (e: any) => {
    if (e.target.className.includes && !e.target.className.includes('epr')) {
      setIsOver(null);
    }
  };

  const getMessageToDisplay = (): ChatMessage[] => {
    if (isItReply) {
      const [fIndex, lIndex] = [0, threadLoaded - 1];
      const result = messages.filter((message, index) => message.shouldDisplay || (index >= fIndex && index <= lIndex));

      if (result.length > threadLoaded) {
        setThreadLoaded(result.length);
      }
      return result;
    } else return messages;
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', closeEmojiModal, true);

    return () => {
      document.removeEventListener('mousedown', closeEmojiModal, true);
    };
  }, []);

  const loadMoreComments = () => {
    setThreadLoaded(pV => pV + AMOUNT_OF_COMMENTS_TO_LOAD);
    setIsLoaded(false);
  };

  const loadMoreData = () => {
    const newValue = loadedTimes + AMOUNT_OF_MESSAGES_TO_LOAD;
    setLoadedTimes(newValue);
    loadMessages(newValue);
  };

  React.useEffect(() => {
    if (user) {
      if (!document.body.getElementsByClassName(`style-mention-${user.id}`).length) {
        const el = document.createElement('style');
        el.className = `style-mention-${user.id}`;
        el.innerHTML = `.mention-${user.id} {background: #f2c74466}`;
        document.body.appendChild(el);
      }
    }
  }, [user]);

  const scrollToLastUnreadMessage = useCallback(
    (lastDate: Date) => {
      if (lastDate && messages) {
        const unreadMessages = messages.filter(message => message.createdAt > lastDate);

        const lastUnreadMessage = unreadMessages.at(-1);
        const targetMessage = lastUnreadMessage || messages[0];
        const elem = document.getElementById(targetMessage.id);

        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start' });

          setTimeout(async () => {
            const lastVisitedAt = (await readAllMessages(channelId)).data.data.lastVisitedAt;

            setLastDateUserVisitedChannelActual(lastVisitedAt);
          }, 2000);
        }
      }
    },
    [channelId, messages, readAllMessages],
  );

  React.useEffect(() => {
    // scrollToReplyMessage();

    getLastDateUserVisitedChannel(channelId).then(({ data }) => {
      setLastDateUserVisitedChannelActual(data?.lastDateUserVisitedChannel);
      setIsLoaded(false);
      scrollToLastUnreadMessage(data?.lastDateUserVisitedChannel);
    });
  }, [channelId, getLastDateUserVisitedChannel, scrollToLastUnreadMessage]);

  function srollToElem(el: HTMLElement | null) {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  const handleMessageAction = async (key: string, data: any, messageId: string) => {
    await messageAction({ key, data, messageId });
  };

  return messages && !isLoaded ? (
    <>
      <InfiniteScroll
        dataLength={getMessageToDisplay().length}
        className={isItReply ? '!overflow-visible' : ''}
        next={loadMoreData}
        hasMore={isItReply ? false : true}
        inverse={true}
        style={{ display: 'flex', flexDirection: 'column-reverse', height: '100%', overflow: 'visible' }}
        loader={isItReply ? '' : <div className="w-full text-center text-base">This is the beginning of a chat room 🤐</div>}
        scrollableTarget="scrolableDiv"
      >
        {getMessageToDisplay().map((item: ChatMessage) => {
          const manualUnread = item.unReadBy.find(u => u.id === user.id);
          const read = !manualUnread && !isLoaded && item.createdAt < lastDateUserVisitedChannelActual;
          return (
            <div
              key={item.id + '-message'}
              id={item.id}
              className={
                'comment mb-[5px] mx-3 ' +
                `${item.isServiceMessage && '!text-[#616061]'} ${isItReply && 'reply-comment '}` +
                (read ? '' : !item.isServiceMessage && ' unread ') +
                ` ${replyTo && replyTo.id === item.id && 'to-reply !bg-[#fff9dd] rounded-[15px]'} ${isItReply && !read && ' !mr-0'}`
              }
              onLoad={() => {
                if (item.replyTo) {
                  setIsLoaded(true);
                }
              }}
            >
              {isLoaded &&
                document.getElementById(item?.replyTo?.id)?.getElementsByClassName('ant-comment-avatar')[0] &&
                document.getElementById(item.id)?.getElementsByClassName('ant-comment-avatar')[0] && (
                  <Xarrow
                    color="#D9DCE3"
                    path="grid"
                    startAnchor="bottom"
                    endAnchor="left"
                    start={{ current: document.getElementById(item?.replyTo?.id)?.getElementsByClassName('ant-comment-avatar')[0] }}
                    end={{ current: document.getElementById(item.id)?.getElementsByClassName('ant-comment-avatar')[0] }}
                    strokeWidth={1}
                    showHead={false}
                  />
                )}
              <Comment
                actions={[
                  isAllowToCommunicate !== undefined && !isItReply && !item.isServiceMessage && (
                    <span
                      className="!m-0 !text-sm !mr-3"
                      onClick={() => {
                        setReplyTo(() => {
                          setTimeout(() => srollToElem(document.getElementById(item.id)), 0);
                          return item.replyTo ? { id: item.replyTo.id, text: item.text } : item;
                        });
                      }}
                    >
                      Reply
                    </span>
                  ),
                  isAllowToCommunicate !== undefined && !item.isServiceMessage && (
                    <div
                      onClick={() => openEmojiModal(item.id)}
                      className="add-reactions cursor-pointer transition-all hover:bg-[#4F67FF] rounded-[62px] mr-3 bg-[#E9EBEF] w-8 h-[22px] flex justify-center items-center"
                    >
                      <div className="add-reactions-logo "></div>
                    </div>
                  ),
                  <Reactions isAllowToCommunicate={isAllowToCommunicate} user={user} reactions={item.reactions} message={item} />,
                ]}
                author={item.author?.name}
                avatar={item.author?.picture}
                content={
                  <>
                    <div dangerouslySetInnerHTML={{ __html: item.text }} className={item.isServiceMessage ? '!text-[#616061]' : ''}></div>
                    <div className="flex flex-wrap">
                      {item?.files?.map(file => {
                        const [name, type, filterKey, fileSize] = file.split('&');
                        const fileURL = `${S3_URL_MASK}${item.author.id}/${filterKey}/${name}`;

                        return type.split('/')[0] === 'image' ? (
                          <div
                            key={fileURL}
                            onClick={() => {
                              setShowImage(fileURL);
                            }}
                            className="message-file-image"
                          >
                            <img src={fileURL} alt={name} />
                          </div>
                        ) : (
                          <div className="message-file" key={fileURL}>
                            <FileOutlined className=" text-[32px]" />
                            <div className="message-file-data ml-2">
                              <div className="file-name">{name}</div>
                              <div className="file-size">{fileSize ? formatBytes(Number(fileSize), 0) : '??? mb'}</div>
                            </div>
                            <div className="file-actions flex ml-auto">
                              <a href={fileURL} target="_blank" className="download-file" rel="noreferrer">
                                <img src={DonwloadIcon} alt="donwload-icon" />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="message-actions flex">
                      {item.actions?.map(action => {
                        const [key, data] = action.split('@');
                        return (
                          <div key={key} className="message-action" onClick={() => handleMessageAction(key, data, item.id)}>
                            {MESSAGE_ACTIONS_DATA[key]?.title}
                          </div>
                        );
                      })}
                    </div>
                  </>
                }
                datetime={
                  <Tooltip title={moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}>
                    <span>{moment(item.createdAt).fromNow()}</span>
                  </Tooltip>
                }
              >
                <>
                  {isOver === item.id && isAllowToCommunicate !== undefined && <Reaction closeModal={setIsOver} message={item} />}
                  {item.thread?.length > 0 && (
                    <MessageList
                      isItReply={true}
                      isAllowToCommunicate={isAllowToCommunicate}
                      messages={item.thread}
                      setReplyTo={setReplyTo}
                      user={user}
                      loadMessages={loadMessages}
                      channelId={channelId}
                    />
                  )}
                  <div className="flex">
                    {!isItReply && replyTo?.id === item.id ? (
                      <div onClick={() => setReplyTo()} className="py-2 pl-1 font-bold select-none cursor-pointer hover:underline w-fit">
                        Cancel reply
                      </div>
                    ) : (
                      item.thread?.length > 0 &&
                      !item.isServiceMessage && (
                        <div
                          onClick={() => {
                            setReplyTo(() => {
                              setTimeout(() => srollToElem(document.getElementById(item.id)), 0);
                              return item.replyTo ? { id: item.replyTo.id, text: item.text } : item;
                            });
                          }}
                          className="py-2 pl-1 font-bold select-none cursor-pointer hover:underline w-fit"
                        >
                          Reply
                        </div>
                      )
                    )}
                  </div>
                </>
              </Comment>
            </div>
          );
        })}
        {isItReply && messages.length > threadLoaded && (
          <div onClick={loadMoreComments} className="text-[#7F85A2] pl-[12px] font-bold select-none cursor-pointer hover:underline w-fit">
            Load older messages
          </div>
        )}
      </InfiniteScroll>
      {showImage && <ImageModal image={showImage} user={user} setShowImage={setShowImage} />}
    </>
  ) : null;
};

export default MessageList;
