import { useState, useCallback, useEffect, useRef } from 'react';
import { User } from '../entities';
import { ChatChannel, ChatMessage, ChatMessageHeaders, ChatMessageReplyProps, StateCB } from '../entities/chat';
import Pusher from 'pusher-js';

import { parse } from 'query-string';
import { INITIAL_AMOUNT_OF_LOADED_MESSAGES } from '../constants';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag } from 'configcat-react';
import { useChatApi } from '../../api/useChatApi';

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

export type Result = {
  chatChannels: ChatChannel[];
  chatWhereUserMember: ChatChannel[];
  handleChannel: (channelId: string, isRefresh?: boolean, isRedirect?: boolean, endpoint?: string) => void;
  activeChannelId: string;
  messages: any;
  retrieveMessages: (channelId: string, part?: number, hardLoad?: boolean) => Promise<void>;
  replyTo?: ChatMessageReplyProps;
  setReplyTo: (message?: ChatMessageReplyProps | StateCB) => void;
  users: User[];
  loadUsers: () => void;
};

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', { cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || '' });

export function useChat(user: User | undefined, searchParams: string): Result {
  const navigate = useNavigate();

  const { value: chatEnabled } = useFeatureFlag('chat', false, { identifier: user?.email! });

  const { getChannels, getMessages, getUsers } = useChatApi();

  const [messages, setMessages] = useState<any>({});
  const [replyTo, setReplyTo] = useState<ChatMessageReplyProps>();
  const [activeChannelId, setActiveChannelId] = useState('');
  const [users, setUsers] = useState<User[]>([]);

  const [chatChannels, setChannels] = useState<ChatChannel[]>([]);
  const [chatWhereUserMember, setChatWhereUserMember] = useState<ChatChannel[]>([]);

  const currentUrlParams = useRef<{ [key: string]: string | null }>(parse(searchParams) as { [key: string]: string | null });
  const chatId = (currentUrlParams.current['chat-id'] || 'non-chat') + '';
  const channelSubscriber = useRef<any>(null);
  const refreshChannels = useRef<{ [key: string]: boolean }>({});

  const mutateChatChannels = useCallback(
    async (chatEnabled: boolean) => {
      const { channels }: { channels: ChatChannel[] } = chatEnabled ? (await getChannels(searchParams)).data : { channels: [] };

      setChatWhereUserMember(channels);
      setChannels(channels);
    },
    [getChannels, searchParams],
  );

  useEffect(() => {
    if (user) {
      mutateChatChannels(chatEnabled);
    }
  }, [chatEnabled, user, mutateChatChannels]);

  const loadUsers = useCallback(async () => {
    if (chatEnabled) {
      const result = (await getUsers(chatId)).data?.data as User[];
      setUsers(result);
    }
  }, [getUsers, chatId, chatEnabled]);

  const cancelSubscription = (channelId: string) => {
    if (channelSubscriber.current) {
      channelSubscriber.current.unbind();
      // this null is important to reupdate
      channelSubscriber.current = null;
      pusher.unsubscribe(channelId);
    }
  };

  const retrieveMessages = useCallback(
    async (channelId: string, part = INITIAL_AMOUNT_OF_LOADED_MESSAGES, hardLoad?: boolean) => {
      if (hardLoad || !messages[channelId]?.length || refreshChannels.current[channelId]) {
        const newMessages = { ...messages };
        newMessages[channelId] = (await getMessages(channelId, chatId, part)).data?.data;
        setMessages(newMessages);
        delete refreshChannels.current[channelId];
      }
    },
    [chatId, getMessages, messages],
  );

  const pushMessage = (message: ChatMessage) => {
    if (!message.channel?.id) return;

    setMessages(prevMessages => {
      const channelId = message.channel.id;
      const channelMessages = prevMessages[channelId] || [];

      let updatedMessages;

      if (message.replyTo?.id) {
        updatedMessages = channelMessages.map(msg =>
          msg.id === message.replyTo!.id ? { ...msg, thread: [{ ...message, shouldDisplay: true }, ...(msg.thread || [])] } : msg,
        );
      } else {
        updatedMessages = [message, ...channelMessages];
      }

      return { ...prevMessages, [channelId]: updatedMessages };
    });
  };

  const subscribeChannel = useCallback(
    (channelId: string) => {
      const pusherChannelId = channelId + '-' + chatId;
      if (channelSubscriber.current?.name === pusherChannelId) return;

      cancelSubscription(pusherChannelId);

      channelSubscriber.current = pusher.subscribe(pusherChannelId);

      channelSubscriber.current.bind(
        'chat:new-message-' + process.env.REACT_APP_CHAT_ID,
        (message: { messageData: ChatMessage; visibleFor: string }) => {
          if (message.visibleFor === user?.id || !message.visibleFor) {
            pushMessage(message.messageData);
          }
        },
      );

      channelSubscriber.current.bind('chat:new-channel-created-' + process.env.REACT_APP_CHAT_ID, () => {
        mutateChatChannels(chatEnabled);
      });

      channelSubscriber.current.bind('chat:channel-changed-' + process.env.REACT_APP_CHAT_ID, () => {
        mutateChatChannels(chatEnabled);
      });

      channelSubscriber.current.bind(chatId + ':new-channel-member-' + process.env.REACT_APP_CHAT_ID, loadUsers);
    },

    [chatEnabled, chatId, loadUsers, mutateChatChannels, user?.id],
  );

  const handleChannel = useCallback(
    async (channelId: string, isRefresh?: boolean, isRedirect?: boolean, endpoint = 'chat') => {
      if (isRefresh) {
        refreshChannels[channelId] = true;
      }

      setActiveChannelId(channelId);
      if (channelId) {
        cancelSubscription(channelId);

        subscribeChannel(channelId);

        await retrieveMessages(channelId);

        if (isRedirect) {
          navigate(`/${endpoint}/${channelId}`);
        }
      }
    },
    [navigate, retrieveMessages, subscribeChannel],
  );

  if (chatEnabled) {
    const baseEventsSubscriber = pusher.subscribe('chat-base-events-' + process.env.REACT_APP_CHAT_ID);

    baseEventsSubscriber.bind('chat:channel-renamed-' + process.env.REACT_APP_CHAT_ID, (channel: ChatChannel) => {
      const chatChannel = chatChannels.find(c => c.id === channel.id);

      if (chatChannel) {
        chatChannel.isNameEdited = true;
        chatChannel.name = channel.name;
      }
    });

    baseEventsSubscriber.bind('chat:new-message-in-channel-' + process.env.REACT_APP_CHAT_ID, (message: ChatMessageHeaders) => {
      refreshChannels.current[message.channel.id] = true;

      if (message.author.id !== user?.id && !message.isServiceMessage) {
        const chatChannel = chatChannels.find(c => c.id === message.channel.id);

        if (chatChannel && !message.dontIncreaseUnread) {
          chatChannel.unread++;
        }
      }
    });
  }

  const chatData = {
    chatChannels,
    chatWhereUserMember,
    handleChannel,
    activeChannelId,
    messages,
    retrieveMessages,
    replyTo,
    setReplyTo,
    users,
    loadUsers,
    chatId,
  };

  return chatData;
}
